<template>
  <section class="acc">
    <div class="wrap">
      <div class="row">
        <div class="accInfo col-12 col-lg-5 offset-lg-1">
          <div class="row aic">
            <span class="icon icon-bank"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>
            <h2 v-html="$t('step_summary_need_a_bank_account')"></h2>
          </div>
          <p>{{$t('step_summary_bank_account_caption')}}</p>
          <router-link @click="InlineButtonClickHandler" :to="{name: 'banks', params: {'locale': this.$store.state.lang}}" class="btn btn-half"><span class="btn-val">{{$t('home_info_see_banks')}} <span class="icon icon-pin2"></span></span> <span class="hover"></span></router-link>
        </div>
        <div class="accLinks col-12 col-lg-4 offset-lg-1 px-0">
          <div class="row">
            <div class="col-12">
              <div class="row aic">
                <span class="icon icon-lup_quest"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                <h2>{{$t('home_links_title')}}</h2>
              </div>
            </div>
            <div class="linksList col-12 px-0">
              <div class="row jcc">
                <div class="linksListSingle col-12">
                  <a v-bind:href="$t('home_links_1')" target="_blank" class="btn btn-ghost btn-full btn-sq"><span class="btn-val">{{ $t('home_links_1_label') }}</span> <span class="hover"></span></a>
                </div>
                <div class="linksListSingle col-12">
                  <a v-bind:href="$t('home_links_2')" target="_blank" class="btn btn-ghost btn-full btn-sq"><span class="btn-val">{{ $t('home_links_2_label') }}</span> <span class="hover"></span></a>
                </div>
                <div class="linksListSingle col-12">
                  <a v-bind:href="$t('home_links_3')" target="_blank" class="btn btn-ghost btn-full btn-sq"><span class="btn-val">{{ $t('home_links_3_label') }}</span> <span class="hover"></span></a>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    methods: {
      InlineButtonClickHandler(event) {
        event.section = 'Summary Banks';
        this.$emit('sateliteClick', event);
      }
    }
  }
</script>