export default {
  "top_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GdzieZamieszkać"])},
  "langswitcher_lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język"])},
  "langswitcher_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waluta"])},
  "langswitcher_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
  "home_banner_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gdzie zamieszkać?"])},
  "home_banner_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odnajdź się u nas."])},
  "home_banner_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasze narzędzie przedstawi Ci kilka miejscowości wraz z poglądowymi kosztami utrzymania. Co ważne, zaproponuje też potencjalne oferty pracy i mieszkań."])},
  "home_banner_content_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpowiedz na kilka pytań i znajdź miejsce, które najbardziej odpowiada Twoim potrzebom."])},
  "home_banner_btn_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozpocznij"])},
  "home_who_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dlaczego powstało GdzieZamieszkać? "])},
  "home_who_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z inicjatywy Mastercard® przy wsparciu Grupy Morizon-Gratka i Ukraińskiego Domu, stworzyliśmy serwis, w którym za pomocą kilku kliknięć znajdziesz odpowiadające Twoim potrzebom miejsce do życia, sprawdzisz oferty mieszkań i zatrudnienia."])},
  "home_who_strong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wspieramy uchodźców z Ukrainy w poszukiwaniu swojego miejsca w Polsce."])},
  "home_who_btn_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dowiedz się więcej"])},
  "home_earnings_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarobki brutto <br class=\"desktop\">w danej branży"])},
  "home_earnings_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz interesujące Cię branże"])},
  "home_earnings_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznaj średnie, orientacyjne zarobki dla danego województwa w jednej z kilkunastu wybranych branż. "])},
  "home_earnings_small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Średnie zarobki brutto w sektorze przedsiębiorstw oraz w podziale na odpowiednie grupy PKD."])},
  "home_earnings_show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz wszystkie"])},
  "home_info_citizens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mieszkańców"])},
  "home_info_need_an_bank_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potrzebujesz konta <br>bankowego w Polsce?"])},
  "home_info_need_an_bank_account_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto bankowe w lokalnym banku ułatwi Ci start w nowym miejscu. Sprawdź listę placówek w wybranej okolicy."])},
  "home_info_see_banks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz placówki"])},
  "home_info_check_cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przykładowe miejscowości dostępne na stronie"])},
  "home_faq_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Często zadawane pytania"])},
  "home_faq_items_1_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dlaczego i dla kogo Mastercard stworzył narzędzie GdzieZamieszkać?"])},
  "home_faq_items_1_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Narzędzie GdzieZamieszkać od Mastercard powstało z myślą o obywatelach Ukrainy, którzy szukają w Polsce schronienia przed wojną bądź już je znaleźli. Będzie jednak przydatne dla każdego zainteresowanego relokacją, niezależnie od jej powodów i pochodzenia danej osoby.\nW prosty i szybki sposób dostarczamy informacji na temat uśrednionych kosztów życia oraz przybliżonej wysokości wynagrodzenia, jakich można się spodziewać w różnych miejscach kraju. Jest to nasze wsparcie w podejmowaniu decyzji, gdzie zamieszkać na dłużej.</p>"])},
  "home_faq_items_2_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak działa narzędzie GdzieZamieszkać?"])},
  "home_faq_items_2_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Wypełniony formularz jest podstawą do rozpoczęcia poszukiwań. Nasze narzędzie, bazując na zanonimizowanych danych Mastercard, statystycznych danych GUS oraz dostępnych ofert z serwisu Gratka.pl, sugeruje rozwiązania najbardziej adekwatne do informacji podanych w formularzu uzupełnionym na wstępie.</p>"])},
  "home_faq_items_3_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z jakiego okresu i skąd pochodzą dane przedstawione na stronie?"])},
  "home_faq_items_3_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Narzędzie Gdzie Zamieszkać bazuje na zanonimizowanych danych transakcyjnych Mastercard oraz ogólnodostępnych źródłach zewnętrznych, m.in. GUS (Główny Urząd Statystyczny). Podane informacje dotyczą okresu: 1.06.2022 – 31.12.2022.</p>"])},
  "home_faq_items_4_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co oznacza stwierdzenie „średnie koszty miesięczne”?"])},
  "home_faq_items_4_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Średni miesięczny koszt utrzymania dla danego gospodarstwa domowego jest wyliczany na\npodstawie odpowiedzi udzielonych w formularzu oraz estymowanych kosztów życia przeciętnej osoby dorosłej w danym miejscu w Polsce. Podane wyniki są orientacyjne i mogą zmieniać się w zależności od indywidualnych preferencji użytkownika oraz zmieniającej się sytuacji gospodarczej.</p>"])},
  "home_faq_items_5_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybrałam w formularzu koszty za mieszkanie dla 2 osób dorosłych i 2 dzieci. Czy dokładnie takie koszty czekają mnie na miejscu?"])},
  "home_faq_items_5_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Podane ceny są cenami orientacyjnymi. Na ich podstawie można zorientować się, jakie są szacunkowe koszty najmu mieszkania w wybranym miejscu. Narzędzie GdzieZamieszkać nie służy jednak do porównywania ofert czy negocjacji cenowych. Na finalne ceny ma wpływ szereg czynników ekonomicznych, w tym indywidualne warunki zależne od dostarczycieli dóbr i usług, które mogą mieć wpływ na różnicę względem prezentowanych stawek uśrednionych.</p>"])},
  "home_faq_items_6_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W liście rozwijalnej brakuje danych, które mnie interesują. Co teraz?"])},
  "home_faq_items_6_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Staramy się dostarczać Ci jak najwięcej informacji, korzystając z dostępnych danych. Zachęcamy do skorzystania z narzędzia w obecnej formie i uzupełnienia potrzebnych informacji, aby uzyskać spersonalizowany wynik.</p>"])},
  "home_faq_items_7_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrobiłem/am błąd w jednym z pytań. Czy mogę poprawić moje odpowiedzi?"])},
  "home_faq_items_7_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Tak. Wystarczy wrócić do formularza, przejść do odpowiedniego pytania i zmienić swoją odpowiedź, aby zaktualizować wynik.</p>"])},
  "home_faq_items_8_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy to wszystkie koszty, których mogę spodziewać się w miejscu docelowym?"])},
  "home_faq_items_8_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Narzędzie służy przedstawieniu orientacyjnych i podstawowych kosztów życia w danej lokalizacji, czyli np. ceny wynajmu mieszkania, zakupów spożywczych czy transportu. W zależności od indywidualnych preferencji oraz potrzeb użytkownika/ków, jak i dodatkowych czynników finalne koszty utrzymania mogą być inne.</p>"])},
  "home_faq_items_9_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy mogę porównywać średnie koszty życia pomiędzy polskimi miejscowościami?"])},
  "home_faq_items_9_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Tak, po przeanalizowaniu danych z formularza narzędzie GdzieZamieszkać przedstawia wynik dla trzech miejscowości: dla miejsca aktualnego pobytu i dwóch innych w jego pobliżu.</p>"])},
  "home_faq_items_10_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy mogę zapisać wygenerowane wyniki i wrócić do nich później?"])},
  "home_faq_items_10_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Możesz wygenerować wiele wyników, a każdy z nich zapisać jako osobny link, by móc do niego wrócić później lub udostępnić rodzinie lub znajomym.</p>"])},
  "home_faq_items_11_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy mogę sprawdzić koszty życia poza Polską?"])},
  "home_faq_items_11_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Narzędzie GdzieZamieszkać umożliwia zapoznanie się z uśrednionymi miesięcznymi kosztami utrzymania oraz wynagrodzenia wyłącznie na terenie Polski.</p>"])},
  "home_faq_items_12_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po jakim kursie przeliczane są kwoty w widoku EUR/UAH?"])},
  "home_faq_items_12_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Przelicznik na stronie GdzieZamieszkać jest aktualizowany raz dziennie na podstawie kursu średniego walut obcych NBP.</p>"])},
  "home_faq_items_13_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcę założyć konto w banku. Jak mam znaleźć placówkę?"])},
  "home_faq_items_13_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Narzędzie po wpisaniu lokalizacji pomaga w znalezieniu najbliższej placówki bankowej, w której można założyć konto. Wystarczy wybrać przycisk Zobacz placówki w sekcji Potrzebujesz konta bankowego w Polsce?, który znajduje się na dole strony głównej.</p>"])},
  "home_faq_items_14_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jakiego metrażu mogę oczekiwać po kryteriach mieszkań: kawalerki, 2, 3 oraz 4 i więcej pokoi?"])},
  "home_faq_items_14_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Uśredniając, kawalerka to mieszkanie 32 m2, 2 pokoje to 45 m2, 3 pokoje – 60 m2, 4 pokoje i więcej – 110 m2.</p>"])},
  "home_faq_items_15_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaką mogę mieć pewność, że koszty wynajmu podane przez GdzieZamieszkać, będą zgodne z rzeczywistością?"])},
  "home_faq_items_15_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Koszty wynajmu podane przez Gdzie Zamieszkać są kosztami orientacyjnymi i mogą różnić się od siebie w zależności od opłat dodatkowych, takich jak opłaty za media czy fundusz remontowy.</p>"])},
  "home_faq_items_16_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy mogę oczekiwać wynagrodzenia, takiego jak podane przez GdzieZamieszkać?"])},
  "home_faq_items_16_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Kwoty wynagrodzenia szacowane są na podstawie aktualnych danych pochodzących z GUS i podawane brutto.  Są one jedynie uśrednioną kwotą wynagrodzenia w danej branży. Nie mogą też być podstawą do roszczeń wobec pracodawców.</p>"])},
  "home_faq_items_17_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dlaczego narzędzie prosi mnie o dostęp do lokalizacji urządzenia?"])},
  "home_faq_items_17_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Dzięki udostępnieniu lokalizacji możemy wskazać listę najbliższych placówek bankowych w Twojej okolicy lub w miejscowości docelowej.</p>"])},
  "home_faq_items_18_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dlaczego narzędzie GdzieZamieszkać prosi mnie o podanie danych dot. wielkości mojej rodziny?"])},
  "home_faq_items_18_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Ta informacja służy do oszacowania średnich kosztów utrzymania w danej lokalizacji dla wskazanej w formularzu liczby osób.</p>"])},
  "home_faq_items_19_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na jakich urządzeniach działa narzędzie GdzieZamieszkać?"])},
  "home_faq_items_19_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Narzędzie GdzieZamieszkać działa na wszystkich urządzeniach typu komputer, tablet, telefon. Strona jest dostępna zarówno w wersji mobile (systemy: Android i iOS), jak i desktopowej.</p>"])},
  "home_faq_items_20_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co oznacza stwierdzenie „średnie zarobki brutto”?"])},
  "home_faq_items_20_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Średnie zarobki brutto oznaczają przeciętne wynagrodzenie, jakie pracownik może otrzymywać za wykonywaną pracę we wskazanej branży. „Brutto” oznacza kwotę przed odliczeniem obowiązkowych podatków i świadczeń na rzecz państwa (podatek dochodowy, składki ubezpieczenia społecznego ciążące na pracowniku). Po ich odjęciu otrzymujemy kwotę netto, czyli wynagrodzenie przekazywane pracownikowi w dniu wypłaty („na rękę”). Składki, które odprowadza się od kwoty wynagrodzenia brutto, różnią się w zależności od m.in. typu umowy i wysokości wynagrodzenia.</p>"])},
  "home_links_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przydatne linki"])},
  "home_links_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://pomagamukrainie.gov.pl/#potrzebuje-pomocy"])},
  "home_links_1_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomoc rządowa"])},
  "home_links_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://ukrainskidom.pl/"])},
  "home_links_2_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wsparcie pozarządowe"])},
  "home_links_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.nfz.gov.pl/dla-pacjenta-z-ukrainy/"])},
  "home_links_3_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opieka zdrowotna"])},
  "terms_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zasady korzystania"])},
  "policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polityka prywatności"])},
  "footer_lang_and_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język i waluta:"])},
  "page_terms_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zasady korzystania z usługi strony GdzieZamieszkac.com"])},
  "page_terms_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "page_terms_starts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zasady Strony wchodzą w życie z dniem 5 lipca 2022 roku"])},
  "page_policy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polityka prywatności"])},
  "page_policy_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.mastercard.pl/pl-pl/wizja/zachowanie-prywatnosci/polityka-prywatnosci.html"])},
  "page_about_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak powstało <br>GdzieZamieszkać."])},
  "page_about_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaawansowane narzędzie stworzone przez Mastercard zostało uzupełnione o istotne informacje udostępnione przez partnera Grupę Morizon Gratka. Patronat merytoryczny nad projektem objął Ukraiński Dom."])},
  "page_about_powered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POWERED BY"])},
  "page_about_partnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PARTNERSHIPS"])},
  "page_about_partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerzy"])},
  "page_about_mastercard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jako globalna firma technologiczna z branży płatności twórz przyjazne i intuicyjne rozwiązania cyfrowe dla pojedynczych osób, firm czy całych społeczeństw. Zapewniamy wszystkim i wszędzie bezpieczne i proste transakcje pozwalające realizować swój potencjał."])},
  "page_about_morizon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To grupa będąca wiodącym wydawcą serwisów ogłoszeniowych w Polsce, które co miesiąc odwiedza blisko 4 mln użytkowników. Gratka już od 20 lat cieszy się popularnością wśród użytkowników zainteresowanych ogłoszeniami w kategorii praca i nieruchomości."])},
  "page_about_uah_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraiński Dom"])},
  "page_about_uah_home_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizacja powstała dzięki Fundacji „Nasz Wybór”, by ułatwić integrację i wsparcie dla społeczności Ukraińskiej. W chwili obecnej otwarty jest dla wszystkich cudzoziemców i oferuje przestrzeń do działań i wymiany doświadczeń oraz poczucie przynależności do wspólnoty."])},
  "page_about_how_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak działa narzędzie"])},
  "page_about_how_works_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Narzędzie analizuje zagregowane dane Mastercard wraz z ogólnodostępnymi danymi pochodzącymi z oficjalnych źródeł, by w połączeniu z informacjami od naszych Partnerów pomóc użytkownikom w kluczowej decyzji i odnalezieniu najlepszego miejsca do życia w Polsce."])},
  "page_about_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opieramy się na danych i informacjach"])},
  "page_about_data_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pewnie zastanawia Cię na czym dokładnie opieramy działanie GdzieZamieszkać?"])},
  "page_about_data_mastercard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na podstawie zanonimizowanych wartości koszyków danych kategorii zakupowych uśredniliśmy koszty utrzymania w danym mieście oraz stawki mieszkań."])},
  "page_about_data_gus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uśrednione zarobki w danej branży oparliśmy na ogólnodostępnym raporcie, opracowywanym każdego roku przez Główny Urząd Statystyczny."])},
  "page_about_data_morizon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sugerowane oferty pracy i mieszkań pobierane są bezpośrednio z aktualnych ogłoszeń zamieszczanych na stronie partnera."])},
  "page_about_data_title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastercard"])},
  "page_about_data_title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GUS"])},
  "page_about_data_title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupa Morizon-Gratka"])},
  "page_about_toolkit_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz toolkit (1.3 MB)"])},
  "page_about_toolkit_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcesz zaprosić do swojego miasta ludzi, którzy szukają spokoju i perspektyw dla siebie. Ściągnij przygotowaną przez nas paczkę, w której znajdziesz wskazówki jak nagrać proste wideo na ten temat oraz materiały graficzne, które możesz wykorzystać do takiej formy promocji."])},
  "page_about_story_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznaj ich historie"])},
  "page_about_story_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Obejrzyj historie Ukrainek, które podzieliły się z nami swoimi doświadczeniami z pierwszych miesięcy w naszym kraju.</p>\n<p><strong>Narzędzie „GdzieZamieszkać\" powstało dzięki nim i z myślą o takich jak one.</strong></p>"])},
  "page_about_btn_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wypełnij formularz"])},
  "banks_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placówki bankowe"])},
  "banks_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znajdź adresy placówek bankowych w Twojej okolicy lub lokalizacji docelowej."])},
  "banks_type_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpisz nazwę miasta/miejscowości"])},
  "banks_see_on_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz na mapie"])},
  "banks_geolocation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wystąpił problem podczas ustalania Twojej pozycji. Skorzystaj z wyszukiwania."])},
  "steps_menu_step01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybór zawodu"])},
  "steps_menu_step02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba mieszkańców"])},
  "steps_menu_step03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wielkość mieszkania"])},
  "steps_menu_step04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodzaj transportu"])},
  "steps_menu_step05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybór miejscowości"])},
  "steps_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalej"])},
  "steps_prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wróć"])},
  "step_01_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W jakiej branży/zawodzie szukasz pracy?"])},
  "step_01_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz interesującą Cię branżę"])},
  "step_02_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ile osób z Tobą mieszka?"])},
  "step_02_adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dorośli (wliczając Ciebie):"])},
  "step_02_kids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzieci (do 15 roku życia):"])},
  "step_02_i_have_pets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mam zwierzęta"])},
  "step_02_pets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwierzęta:"])},
  "step_02_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono"])},
  "step_03_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaki rodzaj nieruchomości <br class=\"desktop\">Cię interesuje?"])},
  "step_03_estate_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kawalerka"])},
  "step_03_estate_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mieszkanie 2 pokojowe"])},
  "step_03_estate_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mieszkanie 3 pokojowe"])},
  "step_03_estate_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mieszkanie 4 lub więcej pokoi"])},
  "step_04_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z jakich środków transportu korzystasz?"])},
  "step_04_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli korzystasz z własnego pojazdu, obliczymy średni miesięczny koszt paliwa w danej miejscowości."])},
  "step_04_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz zaznaczyć obydwie formy transportu."])},
  "step_04_public_transportation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport <br>publiczny"])},
  "step_04_own_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Własny pojazd"])},
  "step_05_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W jakiej miejscowości w Polsce mieszkasz obecnie?"])},
  "step_05_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymasz wynik dla wskazanej miejscowości oraz dwóch pobliskich."])},
  "step_05_type_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpisz nazwę miasta/miejscowości"])},
  "step_05_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta miejscowość jest niedostępna, wpisz inną nazwę lub wybierz z listy poniżej"])},
  "step_summary_compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porównaj najlepsze miejsca do życia i pracy."])},
  "step_summary_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miasto"])},
  "step_summary_other_cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oraz dwie okoliczne miejscowości"])},
  "step_summary_industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branża"])},
  "step_summary_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Kwoty wynagrodzenia są podawane brutto i stanowią uśrednioną wartość danych pochodzących z GUS. Nie mogą być podstawą do roszczeń wobec pracodawców."])},
  "step_summary_filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtruj miasta po:"])},
  "step_summary_filter_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba ofert pracy"])},
  "step_summary_filter_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najwyższe zarobki brutto"])},
  "step_summary_filter_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najniższe koszty życia"])},
  "step_summary_filter_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba mieszkań na wynajem"])},
  "step_summary_you_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoja lokalizacja"])},
  "step_summary_away_from": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("how_far")), " od Twojej lokalizacji"])},
  "step_summary_salary_gros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zarobki brutto"])},
  "step_summary_spendings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wydatki"])},
  "step_summary_job_offers_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liczba ofert pracy"])},
  "step_summary_flats_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liczba mieszkań na wynajem"])},
  "step_summary_avg_salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnie zarobki (brutto)"])},
  "step_summary_avg_spendings_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Średnie miesięczne wydatki w mieście <span class=\"yellow\">", _interpolate(_named("city")), "</span>"])},
  "step_summary_avg_earning_spendings_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Średnie wydatki i zarobki dla branży: <span class=\"yellow\">", _interpolate(_named("industry")), "</span>, w mieście <span class=\"yellow\">", _interpolate(_named("city")), "</span>"])},
  "step_summary_family_label_adults_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dla osoby dorosłej"])},
  "step_summary_family_label_adults_many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["dla ", _interpolate(_named("people")), " osób dorosłych"])},
  "step_summary_family_label_family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dla rodziny"])},
  "step_summary_family_label_detailed_adults_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 dorosły"])},
  "step_summary_family_label_detailed_adults_many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("people")), " dorosłych"])},
  "step_summary_family_label_detailed_kids_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 dziecko"])},
  "step_summary_family_label_detailed_kids_many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("people")), " dzieci"])},
  "step_summary_family_label_detailed_pets_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 zwierzę"])},
  "step_summary_family_label_detailed_pets_many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("pets")), " zwierząt"])},
  "step_summary_family_label_detailed_public_transportation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podróżującej transportem publicznym"])},
  "step_summary_family_label_detailed_own_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podróżującej samochodem"])},
  "step_summary_earnings_spendings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydatki"])},
  "step_summary_earnings_earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarobki (brutto)"])},
  "step_summary_earnings_spendings_are_percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wydatki stanowią<br>\n <span class=\"val\">", _interpolate(_named("percent")), "%</span><br>\n zarobków"])},
  "step_summary_earnings_small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Podane poniżej kwoty są orientacyjne dla wybranego miasta. Więcej informacji, jak rozumieć zarobki brutto w sekcji FAQ. "])},
  "step_summary_prices_are_aprox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Podane poniżej kwoty są orientacyjne dla wybranego miasta. Więcej informacji, jak rozumieć zarobki brutto w sekcji FAQ."])},
  "step_summary_change_answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wróć do formularza"])},
  "step_summary_rent_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koszt wynajmu (kawalerka)"])},
  "step_summary_rent_1_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnia cena wynajmu mieszkania 32 m² w danym mieście. Koszty wynajmu są orientacyjne i mogą różnić się od siebie w zależności od opłat dodatkowych."])},
  "step_summary_rent_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koszt wynajmu (2 pokoje)"])},
  "step_summary_rent_2_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnia cena wynajmu mieszkania 45 m² w danym mieście. Koszty wynajmu są orientacyjne i mogą różnić się od siebie w zależności od opłat dodatkowych."])},
  "step_summary_rent_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koszt wynajmu (3 pokoje)"])},
  "step_summary_rent_3_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnia cena wynajmu mieszkania 60 m² w danym mieście. Koszty wynajmu są orientacyjne i mogą różnić się od siebie w zależności od opłat dodatkowych."])},
  "step_summary_rent_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koszt wynajmu (4 lub więcej pokoi)"])},
  "step_summary_rent_4_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średnia cena wynajmu mieszkania 110 m² w danym mieście. Koszty wynajmu są orientacyjne i mogą różnić się od siebie w zależności od opłat dodatkowych."])},
  "step_summary_supermarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supermarket"])},
  "step_summary_supermarket_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na podstawie średnich miesięcznych wydatków na osobę dorosłą w kategoriach pożywienie i chemia domowa."])},
  "step_summary_fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koszty paliwa"])},
  "step_summary_fuel_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Średni miesięczny wydatek dla 1 samochodu."])},
  "step_summary_public_transportation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport publiczny"])},
  "step_summary_public_transportation_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Na podstawie kosztu jednego biletu miesięcznego w danej miejscowości. Koszt biletu jednorazowego dla osoby dorosłej w miejscowości ", _interpolate(_named("city")), " wynosi ", _interpolate(_named("cost")), " ", _interpolate(_named("currency"))])},
  "step_summary_restaurants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restauracje"])},
  "step_summary_restaurants_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na podstawie średnich miesięcznych wydatków na osobę dorosłą."])},
  "step_summary_pets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwierzęta"])},
  "step_summary_pets_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na podstawie średnich miesięcznych wydatków na jedno zwierzę w sklepach zoologicznych."])},
  "step_summary_need_a_bank_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potrzebujesz konta <br>bankowego w Polsce?"])},
  "step_summary_bank_account_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto bankowe w lokalnym banku ułatwi Ci start w nowym miejscu. Sprawdź listę placówek w wybranej okolicy."])},
  "step_summary_total_annualy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razem (miesięcznie)"])},
  "step_summary_avg_earnings_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Średnie pensje brutto<br />w mieście ", _interpolate(_named("city"))])},
  "step_summary_choose_industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz interesujące Cię branże"])},
  "step_summary_avg_earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznaj średnią zarobków w trzech wybranych przez Ciebie branżach gospodarki dla wybranej lokalizacji*."])},
  "step_summary_avg_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Średnie zarobki brutto w sektorze przedsiębiorstw oraz w podziale na odpowiednie grupy PKD."])},
  "step_summary_select_other_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz inny region"])},
  "step_summary_citizens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mieszkańców"])},
  "step_summary_other_cities_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcesz sprawdzić inne miasta/miejscowości?"])},
  "step_summary_other_cities_title_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz województwo, a następnie miasto/miejscowość, by sprawdzić średnie miesięczne koszta dla wyszukiwanej lokalizacji."])},
  "step_summary_choose_other_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz inny region"])},
  "step_summary_type_city_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpisz nazwę miasta/miejscowości"])},
  "step_summary_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź"])},
  "step_summary_example_cities": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Przykładowe miejscowości w województwie ", _interpolate(_named("district"))])},
  "step_summary_save_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zachowaj wyniki swojego formularza"])},
  "step_summary_save_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz wyniki wyszukiwania jako link. Zachowaj je na później lub udostępnij je rodzinie lub znajomym już teraz. "])},
  "step_summary_save_btn_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zachowaj wyniki"])},
  "step_summary_gratka_show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz wszystkie oferty"])},
  "step_summary_gratka_estates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIESZKANIA"])},
  "step_summary_gratka_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRACA"])},
  "step_summary_gratka_jobs_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liczba ofert pracy"])},
  "step_summary_gratka_estates_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liczba mieszkań na wynajem"])},
  "step_summary_gratka_jobs_count0to5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oferty pracy"])},
  "step_summary_gratka_estates_count0to5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mieszkania na wynajem"])},
  "step_summary_gratka_flats_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista ofert pochodzi z serwisów Gratka.pl i Morizon.pl"])},
  "step_summary_gratka_jobs_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista ofert pochodzi z serwisu Gratka.pl"])},
  "step_summary_find_flat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znajdź swoje mieszkanie w wybranym mieście"])},
  "step_summary_find_flat_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukasz mieszkania? To pierwsze propozycje, jakie wybraliśmy dla Ciebie na podstawie wypełnionego formularza. Znajdziesz ich o wiele więcej, wybierając zakładkę wszystkie \"oferty\"."])},
  "step_summary_find_flat_desc_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W tej chwili w wybranej przez Ciebie miejscowości nie znaleźliśmy odpowiedniego mieszkania w serwisach <strong>Gratka.pl</strong> i <strong>Morizon.pl</strong>. Jeśli jednak w Twojej opinii narzędzie jest przydatne, wróć do niego za kilka dni, być może w tym czasie pojawią się nowe ogłoszenia."])},
  "step_summary_find_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znajdź pracę w wybranym mieście"])},
  "step_summary_find_job_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz wybrane dla Ciebie oferty pracy.Przejrzyj pełną listę i znajdź ofertę, która najbardziej Ci odpowiada."])},
  "step_summary_find_job_desc_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W tej chwili w wybranych przez Ciebie kategoriach nie znaleźliśmy odpowiednich ofert pracy w serwisie <strong>Gratka.pl</strong>. Rynek pracy zmienia się bardzo dynamicznie, wróć do narzędzia za kilka dni by sprawdzić, czy nie pojawiły się w nim nowe ogłoszenia."])},
  "step_summary_flat_rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mieszkanie do wynajęcia"])},
  "step_summary_flat_room_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kawalerka"])},
  "step_summary_flat_room_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 pokoje"])},
  "step_summary_flat_room_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 pokoje"])},
  "step_summary_flat_room_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 pokoje"])},
  "step_summary_flat_room_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 pokoi"])},
  "step_summary_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zostaw opinię o narzędziu"])},
  "step_summary_modal_question1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy narzędzie okazało się pomocne?"])},
  "step_summary_modal_question2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy uważasz informacje za przydatne?"])},
  "step_summary_modal_question3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy wyniki są trafne?"])},
  "step_summary_modal_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij"])},
  "step_summary_modal_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dziękujemy za Twoją opinię"])},
  "step_summary_go_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz wyniki"])},
  "browser_not_support_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoja przeglądarka nie obsługuje tej funkcji"])},
  "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopiowano adres url do schowka"])},
  "copy_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wystąpił nieoczekiwany błąd"])},
  "not_found_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie mogliśmy znaleźć takiej strony"])},
  "not_found_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilka kroków. Tylko tyle dzieli Cię od znalezienia Twojego <br class=\"desktop\">miejsca w Polsce."])},
  "not_found_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wróć na stronę główną"])},
  "industry_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administracja biurowa"])},
  "industry_B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budownictwo/Architektura, geodezja"])},
  "industry_C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edukacja, szkolenia"])},
  "industry_D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Franczyza, własny biznes"])},
  "industry_E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet, e-commerce, nowe media"])},
  "industry_F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT Administracja"])},
  "industry_G": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistyka, transport, spedycja"])},
  "industry_H": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing, promocja, PR"])},
  "industry_I": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motoryzacja"])},
  "industry_J": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsługa klienta, call center"])},
  "industry_K": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prawo"])},
  "industry_L": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolnictwo"])},
  "industry_M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzątaczki, opiekunki, gosposie"])},
  "industry_N": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubezpieczenia"])},
  "industry_O": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakupy"])},
  "industry_P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administracja państwowa, sektor publiczny"])},
  "industry_R": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doradztwo, konsulting"])},
  "industry_S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanse, ekonomia, bankowość"])},
  "industry_T": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HR, BHP, kadry"])},
  "industry_U": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inżynieria, konstrukcja, technika"])},
  "industry_W": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT programowanie"])},
  "industry_X": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola jakości"])},
  "industry_Y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media, dziennikarstwo, rozrywka, kultura"])},
  "industry_Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieruchomości"])},
  "industry_AA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praca fizyczna"])},
  "industry_AB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkcja"])},
  "industry_AC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reklama, grafika, kreacja, fotografia"])},
  "industry_AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedaż"])},
  "industry_AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uroda, zdrowie, sport"])},
  "currency_pln": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zł"])},
  "currency_eur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€"])},
  "currency_uah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UAH"])},
  "district_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dolnośląskie"])},
  "district_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kujawsko-pomorskie"])},
  "district_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lubelskie"])},
  "district_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lubuskie"])},
  "district_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["łódzkie"])},
  "district_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["małopolskie"])},
  "district_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mazowieckie"])},
  "district_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opolskie"])},
  "district_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podkarpackie"])},
  "district_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podlaskie"])},
  "district_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pomorskie"])},
  "district_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["śląskie"])},
  "district_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["świętokrzyskie"])},
  "district_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["warmińsko-mazurskie"])},
  "district_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wielkopolskie"])},
  "district_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zachodniopomorskie"])},
  "district_ms_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dolnośląskim"])},
  "district_ms_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kujawsko-pomorskim"])},
  "district_ms_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lubelskim"])},
  "district_ms_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lubuskim"])},
  "district_ms_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["łódzkim"])},
  "district_ms_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["małopolskim"])},
  "district_ms_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mazowieckim"])},
  "district_ms_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opolskim"])},
  "district_ms_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podkarpackim"])},
  "district_ms_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podlaskim"])},
  "district_ms_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pomorskim"])},
  "district_ms_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["śląskim"])},
  "district_ms_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["świętokrzyskim"])},
  "district_ms_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["warmińsko-mazurskim"])},
  "district_ms_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wielkopolskim"])},
  "district_ms_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zachodniopomorskim"])},
  "toolkit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toolkit"])},
  "meta_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gdzie zamieszkać? Mastercard"])},
  "meta_title_wheretosettle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where to settle as a Ukrainian refugee? - Where to settle? Mastercard"])},
  "meta_title_costofliving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Cost of Living in Poland - Where to settle? Mastercard"])},
  "meta_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź koszty życia, oferty pracy i mieszkań w Polsce."])},
  "help_ua_get_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skorzystaj z pomocy w sprawach<br />administracyjnych, prawnych i wielu innych."])},
  "help_ua_goto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź na stronę"])},
  "patronage_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patronat"])},
  "patronage_cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Związek Miast Polskich"])},
  "patronage_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najstarsza organizacja samorządowa w kraju, której zadaniem jest wspierać polskie miasta w działaniach na rzecz rozwoju społecznego i gospodarczego oraz upowszechniać dobre praktyki nowoczesnego i innowacyjnego zarządzania samorządowymi wspólnotami mieszkańców."])},
  "home_school_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placówki edukacyjne w okolicy"])},
  "home_school_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź, gdzie znajdują się szkoły oferujące możliwość nauki w jęz. ukraińskim."])},
  "home_school_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż szkoły"])},
  "schools_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placówki edukacyjne"])},
  "schools_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Wybierając szkołę oferującą możliwość nauki w jęz. ukraińskim, należy osobiście zapoznać się z programem nauczania w danej placówce. Dostępność miejsc w szkołach jest ograniczona*. </p>\n<p>Często, gdy w danej okolicy nie istnieje szkoła z możliwością nauki w jęz. ukraińskim lokalne społeczności szukają alternatywy, m.in. organizując zajęcia pozaszkolne dla dzieci. Warto dodatkowo zweryfikować tę możliwość w wybranej miejscowości.</p>\n<p style=\"font-size: 14px;\">*Szczegóły dot. zapisów oraz ewentualnych opłat, znajdują się na stronach poszczególnych placówek. Aby poznać zasady przyjmowania dzieci z Ukrainy do polskich szkół sprawdź informacje na stronie: <a href=\"https://www.gov.pl/web/psse-lask/zasady-przyjmowania-dzieci-z-ukrainy-do-polskich-szkol\" target=\"_blank\" rel=\"nofollow\">https://www.gov.pl/web/psse-lask/zasady-przyjmowania-dzieci-z-ukrainy-do-polskich-szkol</a> </p>"])},
  "school_list_1_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkoła „Materinka”"])},
  "school_list_1_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zgoda 5/5, Warsaw, Poland"])},
  "school_list_1_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.ukranska-shkola-materinka.mozello.com"])},
  "school_list_1_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/vV4N381ThyWG1m6W9"])},
  "school_list_2_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warszawska Szkoła Ukraińska"])},
  "school_list_2_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grójecka 128A, 02-383 Warszawa"])},
  "school_list_2_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.vshkolu.edu.pl"])},
  "school_list_2_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/gVL7UvF793nhvNqx6"])},
  "school_list_3_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraińska szkoła w Polsce z fundacji „Ukraina Niezniszczalna”"])},
  "school_list_3_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al. Jerozolimskie 179, 02-222, Warszawa"])},
  "school_list_3_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.nezlamna.org"])},
  "school_list_3_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "school_list_4_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkoła Sobotnia (Wrocławski Związek Ukraińców w Polsce)"])},
  "school_list_4_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruska 46/202, 50-079 Wrocław"])},
  "school_list_4_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.facebook.com/CUKRuska/"])},
  "school_list_4_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/KcfeKDv2xMAAX7VG7"])},
  "school_list_5_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraińska szkoła w Polsce z fundacji „Ukraina Niezniszczalna”"])},
  "school_list_5_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aleja Wiśniowa, 1, 53-313, Wrocław"])},
  "school_list_5_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.nezlamna.org"])},
  "school_list_5_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/K4cqHAi6h96CEtGR8"])},
  "school_list_6_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraińska szkoła w Polsce z fundacji „Ukraina Niezniszczalna”"])},
  "school_list_6_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Świętego Filipa 25, 31-150, Kraków"])},
  "school_list_6_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.nezlamna.org"])},
  "school_list_6_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "school_list_7_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zespół szkół średnich imienia Tarasa Szewczenki"])},
  "school_list_7_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dworcowa 25, 78-425 Biały Bór"])},
  "school_list_7_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.liceum-bialybor.pl"])},
  "school_list_7_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/mzGdBQ5V2FobKjQS9"])},
  "school_list_8_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkoła Podstawowa Nr 2 w Białym Borze"])},
  "school_list_8_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Słupska 27, 78-425 BIAŁY BÓR"])},
  "school_list_8_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.sp2bbor.eu"])},
  "school_list_8_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/xp1ZXGCETVjHWEHu5"])},
  "school_list_9_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkoła Podstawowa nr 8  im. Łesi Ukrainki www.lesiabart.pl"])},
  "school_list_9_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leśna 1, 11-200 Bartoszyce"])},
  "school_list_9_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.lesiabart.pl"])},
  "school_list_9_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/dDhqnnw4VRzwWZ7L7"])},
  "school_list_10_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zespół  Szkół z Ukraińskim Językiem Nauczania w Górowie Iławeckim"])},
  "school_list_10_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkolna 6, 11-220 Górowo Iławeckie"])},
  "school_list_10_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.szkolazdumka.pl"])},
  "school_list_10_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/9x2ZE7K73xosxWwi9"])},
  "school_list_11_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zespół Szkół Ogólnokształcących Nr 2 im. Markiana Szaszkewicza w Przemyślu"])},
  "school_list_11_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smolki 10, 37-700 Przemyśl"])},
  "school_list_11_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" www.szaszk.edu.pl"])},
  "school_list_11_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/bDrmq4aD42uN5L1d7"])},
  "school_list_12_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkoła Podstawowa z Oddziałami Mistrzostwa Sportowego nr 9 im. A.Mickiewicza mieszcząca się w budynku byłego Gimnazjum nr 1 w Rybniku Oddziały przygotowawcze"])},
  "school_list_12_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cmentarna 1, 44-200 Rybnik"])},
  "school_list_12_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.sp9.miastorybnik.pl/for-foreigners/"])},
  "school_list_12_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/81wc3ZfLp8Yo8Q7Y9"])},
  "school_list_13_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkoła Podstawowa im. A. Mickiewicza w Bielsku Podlaskim (oddzielne klasy ukraińskie z nauczaniem języka ukraińskiego)"])},
  "school_list_13_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adama Mickiewicza 126"])},
  "school_list_13_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zs-mickiewicz.szkolnastrona.pl"])},
  "school_list_13_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/vqk8j2ztkUqggW8GA"])},
  "school_list_14_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zespół Szkolno-Przedszkolny w Czeremsze"])},
  "school_list_14_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkolna 2, 17-240 Czeremcha"])},
  "school_list_14_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zspczeremcha.pl/index.php/pl/"])},
  "school_list_14_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/fvonmV4zwwHyMcWh6"])},
  "school_list_15_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niepubliczna Szkoła Podstawowa św. św. Cyryla i Metodego"])},
  "school_list_15_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ludwika Waryńskiego 30, 15-461 Białystok"])},
  "school_list_15_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szkolacim.szkolnastrona.pl"])},
  "school_list_15_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/htAj9RbNkfub4Zdi6"])},
  "school_list_16_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IV Liceum Ogólnokształcące w Legnicy"])},
  "school_list_16_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarasa Szewczenki 10, 59-220;"])},
  "school_list_16_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zso4legnica.pl"])},
  "school_list_16_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/Tu7uDEJ5teBQWoN97"])},
  "summary_schools_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placówki edukacyjne w okolicy"])},
  "summary_schools_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź gdzie znajdują się szkoły oferujące możliwość nauki w jęz. ukraińskim."])},
  "summary_schools_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż szkoły"])},
  "see_on_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz na mapie"])},
  "page_wheretosettle_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where to settle as a Ukrainian refugee?"])}
}