<template>
  <div v-bind:class="{'open': this.isOpen}" class="okiModal">
    <div class="wrap">
      <div class="row vmh-100 aic jcc">
        <div class="okiModalCont col-12 col-lg-10 col-xlg-8">
          <a @click="this.close()" class="close"><span class="icon icon-x2"></span></a>
          <div class="row">
            <div class="col-12 col-lg-6">
              <h2>{{$t('step_summary_modal_title')}}</h2>
            </div>
            <div class="col-12 col-lg-6 rat px-0">
              <form>
                <div class="row">
                  <div class="col col-12 rating">
                    <p v-bind:class="{'error': this.errors.answer_1}">{{$t('step_summary_modal_question1')}}</p>
                    <div class="ratingWrapper">
                      <input type="radio" v-model="this.form.answer_1" :value="5" name="rat" id="rat01">
                      <label for="rat01"><span class="icon icon-star_full"><span class="path1"></span><span class="path2"></span></span></label>

                      <input type="radio" v-model="this.form.answer_1" :value="4" name="rat" id="rat02">
                      <label for="rat02"><span class="icon icon-star_full"><span class="path1"></span><span class="path2"></span></span></label>

                      <input type="radio" v-model="this.form.answer_1" :value="3" name="rat" id="rat03">
                      <label for="rat03"><span class="icon icon-star_full"><span class="path1"></span><span class="path2"></span></span></label>

                      <input type="radio" v-model="this.form.answer_1" :value="2" name="rat" id="rat04">
                      <label for="rat04"><span class="icon icon-star_full"><span class="path1"></span><span class="path2"></span></span></label>

                      <input type="radio" v-model="this.form.answer_1" :value="1" name="rat" id="rat05">
                      <label for="rat05"><span class="icon icon-star_full"><span class="path1"></span><span class="path2"></span></span></label>
                    </div>
                  </div>
                  <div class="col col-12 rating">
                    <p v-bind:class="{'error': this.errors.answer_2}">{{$t('step_summary_modal_question2')}}</p>
                    <div class="ratingWrapper">
                      <input type="radio" v-model="this.form.answer_2" :value="5"  name="rat2" id="rat06">
                      <label for="rat06"><span class="icon icon-star_full"><span class="path1"></span><span class="path2"></span></span></label>

                      <input type="radio" v-model="this.form.answer_2" :value="4"  name="rat2" id="rat07">
                      <label for="rat07"><span class="icon icon-star_full"><span class="path1"></span><span class="path2"></span></span></label>

                      <input type="radio" v-model="this.form.answer_2" :value="3" name="rat2" id="rat08">
                      <label for="rat08"><span class="icon icon-star_full"><span class="path1"></span><span class="path2"></span></span></label>

                      <input type="radio" v-model="this.form.answer_2" :value="2" name="rat2" id="rat09">
                      <label for="rat09"><span class="icon icon-star_full"><span class="path1"></span><span class="path2"></span></span></label>

                      <input type="radio" v-model="this.form.answer_2" :value="1" name="rat2" id="rat10">
                      <label for="rat10"><span class="icon icon-star_full"><span class="path1"></span><span class="path2"></span></span></label>
                    </div>
                  </div>
                  <div class="col col-12 rating">
                    <p v-bind:class="{'error': this.errors.answer_3}">{{$t('step_summary_modal_question3')}}</p>
                    <div class="ratingWrapper">
                      <input type="radio" v-model="this.form.answer_3" :value="5" name="rat3" id="rat11">
                      <label for="rat11"><span class="icon icon-star_full"><span class="path1"></span><span class="path2"></span></span></label>

                      <input type="radio" v-model="this.form.answer_3" :value="4" name="rat3" id="rat12">
                      <label for="rat12"><span class="icon icon-star_full"><span class="path1"></span><span class="path2"></span></span></label>

                      <input type="radio" v-model="this.form.answer_3" :value="3" name="rat3" id="rat13">
                      <label for="rat13"><span class="icon icon-star_full"><span class="path1"></span><span class="path2"></span></span></label>

                      <input type="radio" v-model="this.form.answer_3" :value="2" name="rat3" id="rat14">
                      <label for="rat14"><span class="icon icon-star_full"><span class="path1"></span><span class="path2"></span></span></label>

                      <input type="radio" v-model="this.form.answer_3" :value="1" name="rat3" id="rat15">
                      <label for="rat15"><span class="icon icon-star_full"><span class="path1"></span><span class="path2"></span></span></label>
                    </div>
                  </div>
                  <div class="col col-12">
                    <button v-if="this.sent==false" type="button" @click="validateForm" class="btn btn-full"><span class="btn-vel">{{$t('step_summary_modal_send')}}</span> <span class="hover"></span></button>
                    <p v-if="this.sent==true"><strong class="success">{{$t('step_summary_modal_message')}}</strong></p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
      sent: false,
      form: {
        answer_1: null,
        answer_2: null,
        answer_3: null,
      },
      errors: {
        answer_1: false,
        answer_2: false,
        answer_3: false,
      },
      lastPosition: -1,
      closedManually: false
    }
  },
  unmounted() {
    window.removeEventListener("scroll", this.updateScroll);
    window.removeEventListener("load", this.updateScrollWithTimeout);
    window.removeEventListener("resize", this.updateScrollWithTimeout);
  },
  created() {


    window.addEventListener("scroll", this.updateScroll);
    window.addEventListener('load', this.updateScrollWithTimeout);
    window.addEventListener('resize', this.updateScrollWithTimeout);


  },
  methods: {
    open() {
      if(this.$cookies.get('hideModal') == null) {
        this.isOpen = true;
        window.document.body.classList.add('okiModalOpen');
      }
      //else already hidden, so we cannot open it again

    },
    close() {
      this.isOpen = false;
      this.closedManually = true;
      window.document.body.classList.remove('okiModalOpen');

      this.$cookies.set('hideModal',true);
    },
    validateForm(event) {
      this.InlineButtonClickHandler(event);
      this.errors.answer_1 = false;
      this.errors.answer_2 = false;
      this.errors.answer_3 = false;

      if(this.form.answer_1 == null)
        this.errors.answer_1 = true;
      if(this.form.answer_2 == null)
        this.errors.answer_2 = true;
      if(this.form.answer_3 == null)
        this.errors.answer_3 = true;

      if(this.errors.answer_1 || this.errors.answer_2 || this.errors.answer_3)
        return;

      this.sendForm();
    },
    sendForm() {

      this.$store
          .dispatch('saveReview', this.form)
          .then(result => {
            if(result.data.result == true) {
              this.form.answer_1 = null;
              this.form.answer_2 = null;
              this.form.answer_3 = null;
              this.sent = true;
            }
          }).catch(error => {
            console.log(error);

      });

    },
    updateScrollWithTimeout() {
      var $this = this;
      setTimeout(function(){
        $this.updateScroll()
      },300);
    },
    updateScroll() {
      if(this.closedManually == true)
        return;

      if(window.pageYOffset > this.lastPosition)
        this.lastPosition = window.pageYOffset;

      var fP = document.querySelector('.earningsSummary');

      if(this.lastPosition > fP.offsetTop)
        this.open();
    },
    InlineButtonClickHandler(event) {
      event.section = 'Summary Review';
      this.$emit('sateliteClick', event);
    }
  }

}
</script>