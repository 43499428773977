<template>
  <section class="steps stepsInd">
    <div class="wrap visible">


      <div class="row jcc">
        <div class="stepsNav col-12 col-lg-10 px-0 px-lg-3">
          <StepsMenu :active="1"/>
        </div>
      </div>


      <div class="row aic jcc">
        <div class="stepsDesc col-12 ta-c px-0">
          <div class="row jcc">
            <div class="col-12 col-lg-8 mb-5">
              <div class="step"><p class="yellow"><strong><span class="val">1</span>/5</strong></p></div>
              <h1 v-bind:class="{'error': this.showError}" class="mb-5 h2">{{$t('step_01_title')}}</h1>
              <form>
                <div class="row jcc">
                  <div class="col-12">
                    <select name="industry" id="industry" @change="this.selectIndustry" v-model="this.industry">
                      <option value="">{{$t('step_01_caption')}}</option>
                      <option v-for="(item, key) in this.getSortedList" :key="'industry_' + key"  :value="item.index">{{item.label}}</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>

            <div class="stepDescNav col-12 px-0 ">
              <div class="row jcc">
                <div class="col-12 col-lg-auto">
                  <router-link :to="this.industry == null ? '' : {'name': 'step02', params: {'locale': this.$store.state.lang}}" @click="InlineButtonClickHandler" class="btn btn-g btn-full" v-bind:class="{'hover': this.industry != null && this.industry != ''}"><span class="btn-val">{{$t('steps_next')}}</span> <span class="hover"></span></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import StepsMenu from "@/views/partials/StepsMenu.vue";
export default {
  components: {StepsMenu},
  // watch: {
  //   '$store.state.lang': function() {
  //     this.generateItems();
  //   }
  // },
  watch: {
    industry() {
      if(this.industry != '')
        this.showError = false;
    }
  },
  data() {
    return {
      'industry': '',
      'list': [],
      'showError': false
    }
  },
  created() {

    this.$store.dispatch('fetchIndustries').
        then(industries => {
          this.list = industries;
        });


    if(this.$store.state.config.industry != null) {
      this.industry = this.$store.state.config.industry;
    }

  },
  computed: {
    getSortedList() {
      var translatedList = [];
      for(var index in this.list) {
        translatedList.push({'label': this.$t('industry_'+this.list[index]), 'index': this.list[index]});
      }

      translatedList.sort(function(a,b) {
        return ('' + a.label).localeCompare(b.label);
      });
      return translatedList;
    },
  },
  methods: {

    getIndustries() {
        return this.list;
    },
    selectIndustry(event)
    {
      this.industry = event.target.value;
      this.$store
          .dispatch('setIndustry', {
            'industry': this.industry
          })
      ;
    },
    InlineButtonClickHandler(event) {

      this.validateStep();
      this.$emit('analyticsEvent', 'step02');

      event.section = 'Step01';
      this.$emit('sateliteClick', event);
    },
    validateStep() {
      this.showError = false;
      if(this.industry == '')
        this.showError = true;
    }
  }

}
</script>