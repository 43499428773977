<template>
  <section class="who">
    <div class="wrap visible">
      <div class="row aic">
        <div class="img col-12 col-lg-2 ta-c">
          <p><strong>{{ $t('patronage_title')}}</strong></p>
          <img src="@/assets/dist/img/patronate_01.svg" alt="ZMP"></div>
        <div class="img col-12 col-lg-5 ta-c">
          <img src="@/assets/dist/img/whoImg01Big.svg" v-bind:alt="$t('home_who_title')">
        </div>
        <div class="col-12 col-lg-5">
          <h2>{{ $t('home_who_title')}}</h2>
          <p>{{ $t('home_who_caption')}}</p>
          <p><strong>{{ $t('home_who_strong')}}</strong></p>
          <router-link @click="InlineButtonClickHandler" :to="{ name: 'about', params: {'locale': this.$store.state.lang}}" class="btn btn-full btn-auto-lg"><span class="btn-val">{{ $t('home_who_btn_label')}}</span> <span class="hover"></span></router-link>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  methods: {
    InlineButtonClickHandler(event) {
      event.section = 'Home About';
      this.$emit('sateliteClick', event);
    }
  }
}
</script>
