<template>
  <section class="earnings earningsSummary" id="earningsSummary">
    <div class="wrap visible">
      <div class="row">
        <div class="earningsStats col-12 col-lg-6 col-xlg-5 ta-c">
          <h2 v-html="$t('step_summary_avg_earning_spendings_in', {city: this.getCurrentCity(), industry: this.$t('industry_' + this.$store.state.config.industry)})"></h2>
          <div class="graph">
            <div class="graphImg">
              <svg width="281" height="281" viewBox="0 0 281 281" fill="none" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <radialGradient id="grad01" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(201.5 39) rotate(125.707) scale(295.561)">
                    <stop stop-color="#EB001B"/>
                    <stop offset="0.452074" stop-color="#FF5F00"/>
                    <stop offset="1" stop-color="#74CA08"/>
                  </radialGradient>
                  <radialGradient id="grad02" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(38.0006 74.0004) rotate(35.0553) scale(296.849)">
                    <stop stop-color="#EB001B"/>
                    <stop offset="0.452074" stop-color="#FF5F00"/>
                    <stop offset="1" stop-color="#74CA08"/>
                  </radialGradient>
                </defs>
                <circle cx="140" cy="140" r="107" transform="rotate(90 140 140)" stroke="url(#grad02)" stroke-width="18" fill="none" stroke-linecap="round" :style="{'stroke-dasharray': '676 676', 'stroke-dashoffset': this.getEarningsRatioForChart()}" />
                <circle cx="140.5" cy="140.5" r="130.5" stroke="#F79E1B" stroke-width="20" fill="none" stroke-linecap="round"/>
              </svg>
              <div v-bind:class="{'danger': this.getEarningsRatio() > 100}" class="graphImgDesc" v-bind:data-ratio-over="this.ratio >= 76 ? 'true' : 'false'">

                <p v-html="this.getRatioLabel()"></p>
              </div>
            </div>
            <div class="graphLegend">
              <ul>
                <li><span class="dot orange"></span><div class="name">{{$t('step_summary_earnings_spendings')}}</div></li>
                <li><span class="dot yellow"></span><div class="name">{{$t('step_summary_earnings_earnings')}}</div></li>
              </ul>
            </div>
          </div>
          <p class="small">{{$t('step_summary_earnings_small')}}</p>

          <div class="row">
            <div class="earningsPower desktop col-12">
                <p>{{$t('page_about_powered')}}</p>
                <div class="row jcc">
                  <div class="earningsPowerSingle col-auto">
                    <img src="@/assets/dist/img/powerLogo01.svg" alt="powerLogo01">
                  </div>
                </div>
              </div>
              <div class="earningsPower desktop col-12">
                <p>{{$t('page_about_partnership')}}</p>
                <div class="row jcc">
                  <div class="earningsPowerSingle col-auto">
                    <img src="@/assets/dist/img/powerLogo02.svg" alt="powerLogo02">
                  </div>
                  <div class="earningsPowerSingle col-auto">
                    <img src="@/assets/dist/img/powerLogo03.svg" alt="powerLogo03">
                  </div>
                </div>
              </div>
          </div>
        </div>

        <CostsList ref="costsList" @recalculate="this.handleRecalculate"/>

      </div>
    </div>
  </section>
</template>
<script>
//import Multiselect from '@vueform/multiselect'
import CostsList from "@/views/widgets/CostsList.vue";

export default {
  components: {
    CostsList
  },
  data() {
    return {
      selectedIndustries: [],
      options: [],
      scrollPosition: null,
      earningsBarsPos: null,
      wh: window.innerHeight,
      ww: window.innerWidth,
      ratio: 0
    }
  },
  created() {
    window.addEventListener("scroll", this.updateScroll);
    //console.log(`The scroll pos ${this.scrollPosition}.`);

    this.$store
        .dispatch('fetchIndustries')
        .then(industries => {
          this.industries = industries
        }).catch(error => {
      console.log(error);
    });

    this.$store
        .dispatch('fetchAvgEarningsByDistrict')
        .then(earnings => {
          this.earnings = earnings;
        }).catch(error => {
      console.log(error);
    });

  },
  unmounted () {
    window.removeEventListener('scroll', this.updateScroll);
  },
  watch: {
    selectedIndustries() {

    }
  },
  methods: {
    handleRecalculate() {

      this.ratio = parseInt((this.$refs.costsList.getTotalCosts() / this.$store.state.costs[this.$store.state.currency]['qb_sal' + this.$store.state.config.industry]) * 100);
    },
    getEarningsRatioForChart() {
      var max = 676;
      var percent = this.ratio > 100 ? 100 : this.ratio;

      var result = max * percent / 100;

      return max - result;
    },
    getEarningsRatio() {
      return this.ratio;
    },
    getRatioLabel() {
      return this.$t('step_summary_earnings_spendings_are_percent', {'percent': this.getEarningsRatio()})
    },
    getCurrentCity() {
      if(this.$store.state.lang == 'pl')
        return this.$store.state.config.city.city_pol;
      if(this.$store.state.lang == 'en')
        return this.$store.state.config.city.city_eng;
      if(this.$store.state.lang == 'ua')
        return this.$store.state.config.city.city_uah;
    },
    getOptions() {
      var items = [];
      for(var i in this.$store.state.industries) {
        items.push({
          value: this.$store.state.industries[i],
          label: this.$t('industry_' + this.$store.state.industries[i])
        });
      }


      return items;
    },
    getEarnings(industry) {
      return this.$store.state.costs[this.$store.state.currency]['qb_sal'+ industry];
    },
    getEarningsMax() {
      var max = 0;
      for(var i in this.$store.state.costs[this.$store.state.currency]) {
        if(i.indexOf('_sal') != -1 && i.indexOf('AvgPow') == -1) {
          if(this.$store.state.costs[this.$store.state.currency][i] > max)
            max = this.$store.state.costs[this.$store.state.currency][i];
        }
      }
      return max;
    },
    getExampleIndustries() {

      var earningsArray = [];
      for(var i in this.$store.state.industries) {
        if(this.$store.state.costs != null ) {
          earningsArray.push({
            'industry': this.$store.state.industries[i],
            'earnings': this.$store.state.costs[this.$store.state.currency]['qb_sal' + this.$store.state.industries[i]]
          });
        }
      }

      earningsArray.sort(function(a,b) {
        return !(a.earnings - b.earnings)
      });

      var result = [];
      var counter = 0;
      for(var index in earningsArray) {
        result[index] = earningsArray[index];
        counter++;
        if(counter >= 3)
          break;
      }
      //console.log(result);
      return result;

    },
    getExampleIndustirsMax()
    {
      var max = 0;
      var list = this.getExampleIndustries();
      for(var i in list) {
        if(list[i].earnings > max)
          max = list[i].earnings;
      }

      return max;
    },
    updateScroll() {
        if (this.$refs.earningsBars) {
            this.earningsBarsPos = parseInt(this.$refs.earningsBars.getBoundingClientRect().top - (this.wh * .8));
        } else {
            this.earningsBarsPos
        }
      this.scrollPosition = window.pageYOffset;
    },
    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    unmounted() {
      window.removeEventListener("scroll", this.updateScroll);
    }
  }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
