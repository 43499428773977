<template>
  <section class="main">
      <div class="bg">
          <img class="desktop" src="@/assets/dist/img/mainBg02.svg" alt="mainBg02">
      </div>
      <div class="wrap visible">
          <div class="mainSlider">
              <div class="mainSliderSingle" :key="slide01">
                <div class="row ">
                  <div class="col-12 col-lg-6 mainCont">
                    <h1><strong>{{$t('home_banner_title')}}</strong></h1>
                    <h3>{{$t('home_banner_subtitle')}}</h3>
                    <p>{{$t('home_banner_content')}}</p>
                    <p><strong>{{$t('home_banner_content_bold')}}</strong></p>
                    <router-link :to="{ name: 'step01', params: {'locale': this.$store.state.lang}}" @click="InlineButtonClickHandler" class="btn not-shad btn-full btn-auto-lg"><span class="btn-val">{{ $t('home_banner_btn_label') }}</span> <span class="hover"></span></router-link>
                  </div>
                  <Splide :options="{drag: true}" class="col-12 col-lg-6 mainVideo">
                    <SplideSlide :key="slide01">

                      <video width="100%" height="480" preload="none" poster="@/assets/dist/img/ALL.jpg" controls ref="homeVideo1" @progress="this.handleProgress(1)" @ended="this.handleSatelite('100%', 1)" @play="this.handleSatelite('Play', 1)" @pause="this.handleSatelite('Pause', 1)">
                        <source src="/video/Video_where_to_settle_City_All.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                      </video>

                    </SplideSlide>
                    <SplideSlide :key="slide02">

                      <video width="100%" height="480" preload="none" poster="@/assets/dist/img/Legnica.jpg" controls ref="homeVideo2" @progress="this.handleProgress(2)" @ended="this.handleSatelite('100%', 2)" @play="this.handleSatelite('Play', 2)" @pause="this.handleSatelite('Pause', 2)">
                        <source src="/video/Video_where_to_settle_City_Legnica.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                      </video>
                    </SplideSlide>
                    <SplideSlide :key="slide03">

                      <video width="100%" height="480" preload="none" poster="@/assets/dist/img/Leszno.jpg" controls ref="homeVideo3" @progress="this.handleProgress(3)" @ended="this.handleSatelite('100%', 3)" @play="this.handleSatelite('Play', 3)" @pause="this.handleSatelite('Pause', 3)">
                        <source src="/video/Video_where_to_settle_City_Leszno.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                      </video>

                    </SplideSlide>
                    <SplideSlide :key="slide04">

                      <video width="100%" height="480" preload="none" poster="@/assets/dist/img/Torun.jpg" controls ref="homeVideo4" @progress="this.handleProgress(4)" @ended="this.handleSatelite('100%', 4)" @play="this.handleSatelite('Play', 4)" @pause="this.handleSatelite('Pause', 4)">
                        <source src="/video/Video_where_to_settle_City_Torun.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                      </video>
                    </SplideSlide>
                  </Splide>
                </div>
              </div>
            </div>
      </div>
  </section>

  <HomeWho @sateliteClick="this.handleSateliteClick" />
  <HomeEarnings @sateliteClick="this.handleSateliteClick" />
  <HomeSchools @sateliteClick="this.handleSateliteClick" />

  <HomeFaq @sateliteClick="this.handleSateliteClick" />
  <HomeLinks @sateliteClick="this.handleSateliteClick" />
  <FixedPanel @sateliteClick="this.handleSateliteClick" :label="$t('home_banner_btn_label')" />
</template>

<script>
import HomeEarnings from "@/views/partials/HomeEarnings";
import HomeFaq from "@/views/partials/HomeFaq";
import HomeLinks from "@/views/partials/HomeLinks";
import HomeWho from "@/views/partials/HomeWho";
import FixedPanel from "@/views/partials/FixedPanel.vue";
import HomeSchools from "@/views/partials/HomeSchools.vue";
//https://github.com/Splidejs/vue-splide
import { Splide, SplideSlide } from '@splidejs/vue-splide';





export default {
  name: 'HomeView',
  components: {
    HomeSchools,
    HomeWho,
    HomeLinks,
    HomeFaq,
    HomeEarnings,
    FixedPanel,
    Splide, SplideSlide
  },
  data() {
    return {
      videoId: 'KhQzMraM6W8',
      eventsDone: {
        'video_1': [],
        'video_2': [],
        'video_3': [],
        'video_4': [],
      }
    }
  },
  mounted() {


    this.$store
        .dispatch('changeFooterVisibility', {
          'visibility': true
        })
    ;
    this.$store.dispatch('clearConfig');
    this.$emit('analyticsEvent', 'home');
  },
  created() {
    document.body.classList.add('thereIsFixedPanel');
  },
  methods: {

    InlineButtonClickHandler(event) {

      event.section = 'Home';
      this.$emit('sateliteClick', event);
      this.$emit('analyticsEvent', 'started');
    },
    handleSateliteClick(event) {

      this.$emit('sateliteClick', event);
    },
    handleSatelite(event, index) {

      var videos = ['Video_where_to_settle_City_All.mp4', 'Video_where_to_settle_City_Legnica.mp4', 'Video_where_to_settle_City_Leszno.mp4', 'Video_where_to_settle_City_Torun.mp4'];

      window._satellite.track('video',
          {
            site_section:'Home',
            button_name: event,
            video_name: videos[index-1],
            video_action: event,
            pageURL: window.top.location.href
          }
      );

    },
    handleProgress(index) {

      if(this.$refs['homeVideo'+index] == undefined)
        return;

      var percent = (this.$refs['homeVideo'+index].currentTime / this.$refs['homeVideo'+index].duration) * 100;

      var event = null;
      if(percent >= 100)
        event = '100%';
      else if(percent >= 75)
        event = '75%';
      else if(percent >= 50)
        event = '50%';
      else if(percent >= 25)
        event = '25%'
      else
        return;

      if(this.eventsDone['video_'+index].indexOf(event) != -1)
        return;

      this.eventsDone['video_'+index].push(event);

      var videos = ['Video_where_to_settle_City_All.mp4', 'Video_where_to_settle_City_Legnica.mp4', 'Video_where_to_settle_City_Leszno.mp4', 'Video_where_to_settle_City_Torun.mp4'];

      window._satellite.track('video',
          {
            site_section:'Home',
            button_name: videos[index-1],
            video_name: videos[index-1],
            video_action: event,
            pageURL: window.top.location.href
          }
      );
    }
  }
}
</script>
