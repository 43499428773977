<template>
  <section class="earnings">
    <div class="wrap visible">
      <div class="row">
        <div class="earningsFind col-12 col-lg-4 offset-lg-1 pr-lg-6">
          <h2 v-html="$t('home_earnings_title')"></h2>
          <form>
            <div class="row">
              <div class="col-12 px-0">
                <select name="earnings" id="earnings" placecholder="{{ $t('home_earnings_select')}}" @change="reloadEarnings($event)">
                  <option value="" disabled selected>{{$t('home_earnings_select')}}</option>
                  <option v-for="(industry, key) in this.getSortedList" v-bind:key="'industry_' + key" v-bind:value="industry.index">{{industry.label}}</option>
                </select>
              </div>
            </div>
          </form>
          <p>{{ $t('home_earnings_caption')}}</p>
          <p class="small">{{ $t('home_earnings_small')}}</p>
        </div>
        <div ref="earningsBars" class="earningsBars col-12 col-lg-6 offset-lg-1" v-bind:class="{anim: earningsBarsPos <= 0}">
          <div class="row">
            <div class="earningsBarsList barList col-12 px-0" v-bind:class="{'open': this.isOpen}">
              <div class="row">
                <div v-for="(item, k) in earnings" v-bind:key="'earnings_' + k" class="barListSingle col-12" :data-percent-bar="parseInt( this.getEaringsInCurrency(item) / this.getEarningsMax() * 100 ) - 10" >
                  <p class="row jcb">
                    <span class="region">{{ $t('district_'+item.woj_no) }}</span>
                    <span class="salary">{{ this.getEaringsInCurrency(item) }} {{ $t('currency_' + this.$store.state.currency)}}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 ta-c">
              <a @click="handleOpen" class="btn earningsBarsReveal"><span class="btn-val">{{ $t('home_earnings_show_all')}} <span class="icon icon-down"></span></span> <span class="hover"></span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
      industries: [],
      earnings: [],
      selectedIndustry: null,
      scrollPosition: null,
      earningsBarsPos: null,
      wh: window.innerHeight,
      ww: window.innerWidth
    }
  },
  created() {
    this.$store
        .dispatch('fetchIndustries')
        .then(industries => {
          this.industries = industries
        }).catch(error => {
      console.log(error);
    });

    this.$store
        .dispatch('fetchAvgEarningsByDistrict')
        .then(earnings => {
          this.earnings = earnings
        }).catch(error => {
      console.log(error);
    });

    window.addEventListener("scroll", this.updateScroll);
    console.log(`The scroll pos ${this.scrollPosition}.`);

  },
  computed: {
    getSortedList() {
      var translatedList = [];
      for(var index in this.industries) {
        translatedList.push({'label': this.$t('industry_'+this.industries[index]), 'index': this.industries[index]});
      }

      translatedList.sort(function(a,b) {
        return ('' + a.label).localeCompare(b.label);
      });
      return translatedList;
    },
  },
  methods: {

    handleOpen(event) {
      this.isOpen = !this.isOpen;

      event.section = 'FixedPanel';
      this.$emit('sateliteClick', event);
    },
    getEaringsInCurrency(item)
    {
      if(this.$store.state.currency in item)
        return item[this.$store.state.currency];

      return '';
    },
    reloadEarnings(event)
    {
      this.selectedIndustry = event.target.value;
      this.$store
          .dispatch('fetchAvgEarningsByDistrict', {
            'industry': this.selectedIndustry
          })
          .then(earnings => {
            this.earnings = earnings
          }).catch(error => {
        console.log(error);
      });
    },
    updateScroll() {
      this.earningsBarsPos = parseInt(this.$refs.earningsBars.getBoundingClientRect().top - (this.wh * .8));
      this.scrollPosition = window.pageYOffset;
      // console.log('scroll', this.scrollPosition, 'earningsBarsPos', this.earningsBarsPos);
    },
    getEarningsMax() {
      var max = 0;

      for(var i in this.earnings) {
        if(this.earnings[i][this.$store.state.currency] > max)
          max = this.earnings[i][this.$store.state.currency];
      }

      return max;
    }
  },
  unmounted() {
    window.removeEventListener("scroll", this.updateScroll);
  }
}
</script>
