<template>
  <ul v-bind:data-active="this.active">
    <li v-bind:class="{'active': this.active == 1}"><div class="val">{{$t('steps_menu_step01')}}</div></li>
    <li v-bind:class="{'active': this.active == 2}"><div class="val">{{$t('steps_menu_step02')}}</div></li>
    <li v-bind:class="{'active': this.active == 3}"><div class="val">{{$t('steps_menu_step03')}}</div></li>
    <li v-bind:class="{'active': this.active == 4}"><div class="val">{{$t('steps_menu_step04')}}</div></li>
    <li v-bind:class="{'active': this.active == 5}"><div class="val">{{$t('steps_menu_step05')}}</div></li>
  </ul>
</template>

<script>
export default {
  props: ['active'],
  mounted() {
    this.hideFooter();
  },
  methods: {
    hideFooter() {

      this.$store
          .dispatch('changeFooterVisibility', {
            'visibility': false
          })
      ;
    }
  }
}
</script>