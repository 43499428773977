<template>
  <section class="gratka">
    <div class="wrap visible">
      <div class="row jcb">
        <div class="gratkaFlat col-12 col-lg-6">
          <div class="row h-100">
            <div class="gratkaTitle col-12 col-lg-10">
              <p><span class="ico"><img src="@/assets/dist/img/house.svg" alt="house"></span> {{$t('step_summary_gratka_estates')}} <span class="gratkaLogo"><img src="@/assets/dist/img/powerLogo02.svg" alt="powerLogo02"></span></p>
            </div>

            <template v-if="'count' in this.flats && this.flats.count > 0">
              <div class="col-12 col-lg-10">
                <h2>{{$t('step_summary_find_flat')}}</h2>
                <p>{{$t('step_summary_find_flat_desc')}}</p>
              </div>
            </template>
            <template v-else>
              <div class="gratkaDesc col-12 col-lg-10">
                  <span class="icon icon-x3"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                  <p v-html="$t('step_summary_find_flat_desc_empty')"></p>
              </div>
            </template>


            <div class="gratkaFlatList col-12 mt-auto" v-show="'count' in this.flats && this.flats.count > 0">

              <div class="row nowrap">
                <template v-for="(flat, key) in this.getFlats().data" v-bind:key="'flat_'+key" >
                  <div v-if="key < 2" class="gratkaFlatListSingle col-12 col-lg-4">

                      <div class="row aic h-100">
                        <div class="title col-12"><a v-bind:href="this.getFlatUrl(flat)" target="_blank" rel="nofollow">{{ $t('step_summary_flat_rent') }}</a></div>
                        <div class="img">
                          <a v-bind:href="this.getFlatUrl(flat)" target="_blank" rel="nofollow"><img v-bind:src="this.getFlatImage(flat)" alt="gratkaImg01"></a>
                        </div>
                        <div class="desc col">
                          <p>{{flat.params['powierzchnia-w-m2']}} m<sup>2</sup> {{this.getRoomsLabel(flat.params['liczba-pokoi'])}}</p>
                          <p class="price">{{flat.params['cena-za-miesiac'] / 100}} zł</p>
                          <a class="loc"><span class="icon icon-pin2"></span> {{this.getCityName()}}</a>
                        </div>
                      </div>

                  </div>
                </template>

                <div class="gratkaFlatListSingle col-12 col-lg-4 ta-c">
                  <a v-bind:href="this.getAllFlatsUrl()" target="_blank" rel="nofollow" class="showAllGratkaBtn row">
                  <div class="row aic h-100">
                    <div class="all col-12">
                      <span class="icon icon-right"></span> {{$t('step_summary_gratka_show_all')}}
                    </div>
                  </div>
                  </a>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="gratkaWork col-12 col-lg-6">
          <div class="row h-100">
            <div class="gratkaTitle col-12 col-lg-10">
              <p><span class="ico"><img src="@/assets/dist/img/suitcase.svg" alt="house"></span> {{$t('step_summary_gratka_jobs')}} <span class="gratkaLogo"><img src="@/assets/dist/img/powerLogo02.svg" alt="powerLogo02"></span></p>
            </div>


            <template v-if="'count' in this.jobs && this.jobs.count > 0">

              <div class="col-12 col-lg-10">
                <h2>{{$t('step_summary_find_job')}}</h2>
                <p>{{$t('step_summary_find_job_desc')}}</p>
              </div>
            </template>
            <template v-else>
              <div class="gratkaDesc col-12 col-lg-10">
                  <span class="icon icon-x3"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                  <p v-html="$t('step_summary_find_job_desc_empty')"></p>
              </div>
            </template>


            <div class="gratkaWorkList col-12 mt-auto" v-show="'count' in this.jobs && this.jobs.count > 0">

              <div class="row nowrap">
                <template v-for="(job, key) in this.getJobs().data" v-bind:key="'job_'+key">
                  <div v-if="key < 2" class="gratkaWorkListSingle col-12 col-lg-4">
                    <div class="row aic h-100">
                      <div class="title col-12"><a v-bind:href="this.getJobUrl(job)" target="_blank" rel="nofollow">{{job.params['nazwa-stanowiska']}}</a></div>
                      <div v-if="this.hasJobImage(job)" class="img">
                        <a v-bind:href="this.getJobUrl(job)" target="_blank" rel="nofollow"><img v-bind:src="this.getJobImage(job)" alt="gratkaImg02"></a>
                      </div>
                      <div class="desc col">
                        <p>{{job.params['nazwa-firmy-imie-nazwisko']}}</p>
                        <a class="loc"><span class="icon icon-pin2"></span> {{this.getCityName()}}</a>
                      </div>
                    </div>
                  </div>
                </template>

                <div class="gratkaWorkListSingle col-12 col-lg-4 ta-c">
                  <a v-bind:href="this.getAllJobsUrl()" target="_blank" rel="nofollow" class="showAllGratkaBtn row">
                  <div class="row aic h-100">
                    <div class="all col-12">
                      <span class="icon icon-right"></span> {{$t('step_summary_gratka_show_all')}}
                    </div>
                  </div>
                  </a>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      flats: [],
      jobs: [],
      industries_slugs: [],
      wh: window.innerHeight,
      ww: window.innerWidth
    }
  },
  mounted() {
    this.retrieveFlats();
    this.retrieveJobs();
  },
  created() {
    this.$store
        .dispatch('fetchIndustriesSlugs')
        .then(industries => {
          this.industries_slugs = industries
        }).catch(error => {
      console.log(error);
    });
  },
  methods: {
    handleClickAllFlats() {
      window.top.location=this.getAllFlatsUrl();
    },
    handleClickAllJobs() {
      window.top.location=this.getAllJobsUrl();
    },
    getAllFlatsUrl() {
      var base = 'https://gratka.pl/nieruchomosci/mieszkania/';

      base += this.getCitySlug();
      base += '/wynajem'
      base += '?utm_source=gdziezamieszkac&utm_medium=brand&utm_campaign=nieruchomosci-wynajem';
      return base;
    },
    getAllJobsUrl() {

      var base = 'https://gratka.pl/praca/';

      var industry_slug = this.industries_slugs[this.$store.state.config.industry];

      base += industry_slug+'/';
      base += this.getCitySlug();
      base += '?utm_source=gdziezamieszkac&utm_medium=brand&utm_campaign=praca';
      return base;
    },
    getFlatUrl(flat) {
      return this.flats.refs.urls[flat.id] + '?utm_source=gdziezamieszkac&utm_medium=brand&utm_campaign=nieruchomosci-wynajem';
    },
    getJobUrl(job) {
      return this.jobs.refs.urls[job.id] + '?utm_source=gdziezamieszkac&utm_medium=brand&utm_campaign=praca';
    },
    getFlatImage(flat) {
      if(this.flats.refs.main_images[flat.id] != undefined)
        return this.flats.refs.main_images[flat.id].frame_urls.small;
      return require('@/assets/dist/img/empty-image.png');
    },
    hasJobImage(job) {
      return (this.jobs.refs.main_images[job.id] != undefined);
    },
    getJobImage(job) {
      if(this.jobs.refs.main_images[job.id] != undefined)
        return this.jobs.refs.main_images[job.id].frame_urls.small;
      return require('@/assets/dist/img/empty-image.png');
    },
    getRoomsLabel(count) {
      return this.$t('step_summary_flat_room_' + count);
    },
    getFlats() {
        return this.flats;
    },
    getJobs() {
      return this.jobs;
    },
    getCitySlug() {
      var slug = this.$store.state.config.city.city_pol
          .toLowerCase()
          .replaceAll(' ', '-')
          .replaceAll('_', '-')
      ;

      return slug.replaceAll('ą', 'a')
            .replaceAll('ć', 'c')
            .replaceAll('ę', 'e')
            .replaceAll('ł', 'l')
            .replaceAll('ń', 'n')
            .replaceAll('ó', 'o')
            .replaceAll('ś', 's')
            .replaceAll('ż', 'z')
            .replaceAll('ź', 'z')
            ;

    },
    getCityName() {
      if(this.$store.state.lang == 'pl')
        return this.$store.state.config.city.city_pol;
      if(this.$store.state.lang == 'en')
        return this.$store.state.config.city.city_eng;
      if(this.$store.state.lang == 'ua')
        return this.$store.state.config.city.city_uah + ' ('+this.$store.state.config.city.city_pol+')';
    },
    retrieveFlats() {

      this.$store
          .dispatch('fetchEstates', {})
          .then(offers => {

            var rooms = this.$store.state.config.estate;

            this.flats = offers;
            if('data' in this.flats)
              this.flats.data.sort(function(a, b) {

                a['index'] = 0;
                b['index'] = 0;

                if(parseInt(a.params['liczba-pokoi']) == parseInt(rooms)) {
                  a['index'] = -1000;
                }

                if(parseInt(b.params['liczba-pokoi']) == parseInt(rooms)) {
                  b['index'] = -1000;
                }

                return (a.index < b.index) ? -1 : 1;
              });


          }).catch(error => {
        console.log(error);
      });
    },
    retrieveJobs() {

      this.$store
          .dispatch('fetchJobs', {})
          .then(offers => {

            this.jobs = offers;

          }).catch(error => {
        console.log(error);
      });
    }
  }
}
//
// function propComparator(prop) {
//
//   return function(a, b) {
//     var x = a;
//     var y = b;
//
//     if(parseInt(a.params['liczba-pokoi']) == parseInt(prop))
//       x += 100;
//
//     if(parseInt(b.params['liczba-pokoi']) == parseInt(prop))
//       y += 100;
//
//     return x-y;
//   }
// }
</script>