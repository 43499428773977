<template>
  <section class="partners">
    <div class="wrap">
      <div class="row jcc">
        <div class="col-12 col-xl-10 partnersTitle">
          <h2>{{$t('page_about_partners')}}</h2>
        </div>
        <div class="col-12 col-xl-10 px-0">
          <div class="row">
            <div class="partnersSingle col-12 col-lg-4">
              <div class="img">
                <img src="@/assets/dist/img/partner01.svg" alt="partner01">
              </div>
              <p><strong>Mastercard</strong></p>
              <p>{{$t('page_about_mastercard')}}</p>
            </div>
            <div class="partnersSingle col-12 col-lg-4">
              <div class="img">
                <img src="@/assets/dist/img/partner02.svg" alt="partner02">
                <img src="@/assets/dist/img/partner03.svg" alt="partner03">
              </div>
              <p><strong>Morizon-Gratka</strong></p>
              <p>{{$t('page_about_morizon')}}</p>
            </div>
            <div class="partnersSingle col-12 col-lg-4">
              <div class="img">
                <img src="@/assets/dist/img/partner04.png" alt="partner04">
              </div>
              <p><strong>{{$t('page_about_uah_home')}}</strong></p>
              <p>{{$t('page_about_uah_home_desc')}}</p>
            </div>
          </div>
        </div>

        <div class="col-12 col-xl-10">
            <div class="row">
                <div class="col-12 separator bd"></div>
            </div>
        </div>

        <div class="col-12 col-xl-10 partnersTitle ">
            <h2>{{$t('patronage_title')}}</h2>
        </div>
        <div class="col-12 col-xl-10 px-0">
            <div class="row">
                <div class="partnersSingle col-12 px-0">
                    <div class="row">
                        <div class="img col-12 col-lg-3 px-3">
                          <img src="@/assets/dist/img/patronate_01.svg" alt="patronate_01">
                        </div>
                        <div class="col-12 col-lg-9">
                            <p><strong>{{$t('patronage_cities')}}</strong></p>
                            <p>{{$t('patronage_desc')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {

}
</script>