<template>
  <div class="banksList col-12 col-lg-5 offset-lg-1">
    <div class="row">


      <div v-for="(bank, key) in this.banks" v-bind:key="'bank_' + key" class="banksListSingle col-12">
        <div class="row aic">
          <div class="col pr-5">
            <p><strong>{{bank.name}}</strong></p>
            <p class="fw-l">{{ bank.address }}<br><a v-bind:href="'tel:'+ bank.phone">{{ bank.phone}}</a></p>
          </div>
          <div class="onMap col-auto">
            <a v-bind:href="'http://maps.google.com/maps?q='+bank.lat+','+bank.lng+'&navigate=yes'" target="_blank">
              <span class="icon icon-pin"></span>
              <span class="val"><br />{{$t('banks_see_on_map')}}</span>
            </a>
          </div>
        </div>
      </div>
      <div id="map-elem"></div>


    </div>
  </div>
</template>
<script>

const ALLOWED_BANKS = [
  'nbp',
  'narodowy bank polski',
  'pko',
  'powszechna kasa oszczędności',
  'bank handlowy',
  'ing',
  'bph',
  'santander',
  'bgs',
  'bank gospodarstwa krajowego',
  'mbank',
  'millenium',
  'polska kasa opieki',
  'boś',
  'bank pocztowy',
  'bank ochrony środowiska',
  'sgb',
  'plus bank',
  'société générale',
  'pekao',
  'deutsche bank',
  'bank polskiej spółdzielczości',
  'credit agricole',
  'idea bank',
  'bnp',
  'toyota bank',
  'dnb',
  'svenska handelsbanken',
  'bnp paribas',
  'danske bank',
  'skandinaviska enskilda',
  'caixabank',
  'haitong bank',
  'getin noble',
  'alior',
  'aareal bank',
  'citibank',
  'ikano bank',
  'nordea',
  'bank of china',
  'rci banque',
  'euroclear',
  'western union',
  'tf bank ab',
  'fce bank',
  'as inbank',
  'china construction bank',
  'mufg bank',
  'john deere bank',
  'volkswagen bank',
  'raiffeisen',
  'hsbc',
  'goldman sachs bank',
  'j.p. morgan',
  'bff bank',
  'fca bank',
  'bank nowy',
  'allfunds',
  'hoist',
  'aion',
  'bank spółdzielczy',
  'eservice',
  'spółdzielcza kasa'
]
const DISALLOWD_PLACES = ['ChIJ7SkKVt7MHkcRI8qRQZK1hAQ'];

export default {
  name: 'BanksList',
  props: ['coords'],
  data() {
    return {
      location: null,
      banks: [],
      placesServiceIterations: 0,
      map: null
    }
  },
  mounted() {
    this.location = new window.google.maps.LatLng(this.coords.latitude, this.coords.longitude);
    this.map = new window.google.maps.Map(document.getElementById('map-elem'), {
      center: this.location,
      zoom: 15
    });

    if(this.setLocationOfCity() === false)
      this.getBanks();

  },
  watch: {
    'coords': function() {
      this.location = new window.google.maps.LatLng(this.coords.latitude, this.coords.longitude);
      this.clearBankList();
      this.getBanks();
    }
  },
  methods: {
    setLocationOfCity() {
      if(this.$store.state.config.city == null)
        return false;

      var thisComponent = this;

      var request = {
        query: this.$store.state.config.city.city_pol+ ', Polska',
        fields: ['name', 'geometry'],
      };

      var service = new window.google.maps.places.PlacesService(this.map);

      service.findPlaceFromQuery(request, function(results, status) {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          for (var i = 0; i < results.length; i++) {
            thisComponent.location = results[0].geometry.location;
            thisComponent.getBanks();
            break;
          }

        }
      });
    },
    clearBankList() {
        this.banks = [];
    },
    getBanks() {

        var thisComponent = this;
        var request = {
          location: this.location,
          //radius: 1500,
          type: ['bank'],
          rankBy: window.google.maps.places.RankBy.DISTANCE
        };

        var service = new window.google.maps.places.PlacesService(this.map);
        service.nearbySearch(request, function (results, status, pagination) {

          thisComponent.placesServiceIterations++;
          //console.log('!!! - ZAPYTANIE DO GOOGLE NR: ', thisComponent.placesServiceIterations);

          if (status == window.google.maps.places.PlacesServiceStatus.OK) {

            thisComponent.placesServiceNextPageId = results;

            for(var index in results) {
              if(thisComponent.banks.length >= 10)
                break;
              if(thisComponent.isBankAllowed(results[index])) {

                thisComponent.banks.push({
                  'placeId': results[index].place_id,
                  'name': results[index].name,
                  'address': results[index].vicinity,
                  'lat': results[index].geometry.location.lat(),
                  'lng': results[index].geometry.location.lng(),
                  'phone': ''
                });

                var request = {
                  placeId: results[index].place_id,
                  fields: ['international_phone_number', 'place_id']
                };

                service.getDetails(request, function (result, status) {
                  if (status == window.google.maps.places.PlacesServiceStatus.OK) {
                    for (var i in thisComponent.banks) {
                      if (thisComponent.banks[i].placeId == result['place_id']) {
                        thisComponent.banks[i].phone = result['international_phone_number'];
                      }
                    }
                  }
                });

              } else {
                //console.log('NIE dodałem', ALLOWED_BANKS.indexOf(results[index].name.toLowerCase()), results[index].name.toLowerCase());
              }
            }

          }

          if(pagination && pagination.hasNextPage && thisComponent.placesServiceIterations < 5 && thisComponent.banks.length <= 10) {
            pagination.nextPage();
          }

        });


    },
    isBankAllowed(bank) {

      if(DISALLOWD_PLACES.indexOf(bank.place_id) != -1)
        return false;

      if(bank.name.toLowerCase().indexOf('bankomat') !== -1)
        return false;

      if(bank.name.toLowerCase().indexOf('wpłatomat') !== -1)
        return false;

      if(('vicinity' in bank) == false)
        return false;

      if(bank.vicinity == '')
        return false;

      for(var i in ALLOWED_BANKS) {
        if(bank.name.toLowerCase().indexOf(ALLOWED_BANKS[i]) !== -1)
          return true;
      }
      return false;
    }
  }
}
</script>