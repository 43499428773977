<template>
  <div id="flood-light-tag">
  <!--
Start of Floodlight Tag: Please do not remove
      Activity name of this tag: PL_Click_HomePageRozpocznij_Counter
      URL of the webpage where the tag is expected to be placed: https://www.tbc.co.uk
          This tag must be placed between the <body> and </body> tags, as close as possible to the opening tag.
      Creation Date: 06/21/2022
      -->
    <noscript v-if="this.homeTag">
      <img src="https://ad.doubleclick.net/ddm/activity/src=12235924;type=mikro;cat=lpwoa0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>
    </noscript>
    <noscript v-if="this.summaryTag">
      <img src="https://ad.doubleclick.net/ddm/activity/src=12235924;type=conv;cat=typ_p0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>
    </noscript>

    <noscript v-if="this.stepTag==1">
      <img src="https://ad.doubleclick.net/ddm/activity/src=12235924;type=mikro;cat=lp_kr0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>
    </noscript>

    <noscript v-if="this.stepTag==2">
      <img src="https://ad.doubleclick.net/ddm/activity/src=12235924;type=mikro;cat=lp_kr00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>
    </noscript>

    <noscript v-if="this.stepTag==3">
      <img src="https://ad.doubleclick.net/ddm/activity/src=12235924;type=mikro;cat=lp_kr000;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>
    </noscript>

    <noscript v-if="this.stepTag==4">
      <img src="https://ad.doubleclick.net/ddm/activity/src=12235924;type=mikro;cat=lp_kr001;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>
    </noscript>
  <!-- End of Floodlight Tag: Please do not remove -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      homeTag: false,
      summaryTag: false,
      stepTag: 0
    }
  },
  methods: {
    home() {
      // window.gtag('event', 'conversion', {
      //   'allow_custom_scripts': true,
      //   'send_to': 'DC-12235924/mikro/lpwoa0+standard'
      // });
      // this.homeTag = true;
      // this.summaryTag = false;
      // this.stepTag = 0;
    },
    step01() {
      // window.gtag('event', 'conversion', {
      //   'allow_custom_scripts': true,
      //   'send_to': 'DC-12235924/mikro/lp_kr0+standard'
      // });
      // this.homeTag = false;
      // this.summaryTag = false;
      // this.stepTag = 1;
    },
    step02() {
      // window.gtag('event', 'conversion', {
      //   'allow_custom_scripts': true,
      //   'send_to': 'DC-12235924/mikro/lp_kr00+standard'
      // });
      // this.homeTag = false;
      // this.summaryTag = false;
      // this.stepTag = 2;
    },
    step03 () {
      // window.gtag('event', 'conversion', {
      //   'allow_custom_scripts': true,
      //   'send_to': 'DC-12235924/mikro/lp_kr000+standard'
      // });
      // this.homeTag = false;
      // this.summaryTag = false;
      // this.stepTag = 3;

    },
    step04() {
      // window.gtag('event', 'conversion', {
      //   'allow_custom_scripts': true,
      //   'send_to': 'DC-12235924/mikro/lp_kr001+standard'
      // });
      // this.homeTag = false;
      // this.summaryTag = false;
      // this.stepTag = 4;

    },
    step05() {

    },
    summary() {
      // window.gtag('event', 'conversion', {
      //   'allow_custom_scripts': true,
      //   'send_to': 'DC-12235924/conv/typ_p0+standard'
      // });
      // this.homeTag = false;
      // this.summaryTag = true;
      // this.stepTag = 0;
    },
    save() {


    },
    check() {


    },
    banks() {
      // window.gtag('event', 'conversion', {
      //   'allow_custom_scripts': true,
      //   'send_to': 'DC-12235924/mikro/lpwoa0+standard'
      // });
      // this.homeTag = true;
      // this.summaryTag = false;

    }
  }
}
</script>