<template>
    <p ref="langExpander"><span v-if="this.location=='location'">{{ $t('footer_lang_and_currency')}}</span>
      <a class="btn btn-ghost btn-rad" v-bind:aria-expanded="this.isOpen" @click="handleExpanderClick()">
        <span class="lang">{{ this.getCurrentLang() }}</span>
        <span class="cur">{{ this.getCurrentCurrency() }}</span>
        <span class="icon icon-down"></span>
        <span class="hover"></span>
      </a>
    </p>
    <div ref="langSwitcher" class="okiCollapse lang-switcher" v-bind:class="{open: this.isOpen}" id="lang">
      <div class="row">
        <div class="col-12 row jcb p-3 bdb">
          <p class="col-12 px-0"><span class="icon icon-globe"></span> {{ $t('langswitcher_lang') }}</p>
          <a class="col btn btn-ghost btn-rad" v-bind:class="{active: this.getCurrentLang() == 'PL'}" @click="handleLangChange('pl')"><span class="btn-val">PL</span> <span class="hover"></span></a>
          <a class="col btn btn-ghost btn-rad" v-bind:class="{active: this.getCurrentLang() == 'EN'}" @click="handleLangChange('en')"><span class="btn-val">EN</span> <span class="hover"></span></a>
          <a class="col btn btn-ghost btn-rad" v-bind:class="{active: this.getCurrentLang() == 'UA'}" @click="handleLangChange('ua')"><span class="btn-val">UA</span> <span class="hover"></span></a>
        </div>
        <div class="col-12 row jcb p-3 bdb">
          <p class="col-12 px-0"><span class="icon icon-coins"></span> {{ $t('langswitcher_currency') }}</p>
          <a class="col btn btn-ghost btn-rad" v-bind:class="{active: this.getCurrentCurrency() == 'PLN'}" @click="handleCurrencyChange('pln')"><span class="btn-val">PLN</span> <span class="hover"></span></a>
          <a class="col btn btn-ghost btn-rad" v-bind:class="{active: this.getCurrentCurrency() == 'EUR'}" @click="handleCurrencyChange('eur')"><span class="btn-val">EUR</span> <span class="hover"></span></a>
          <a class="col btn btn-ghost btn-rad" v-bind:class="{active: this.getCurrentCurrency() == 'UAH'}" @click="handleCurrencyChange('uah')"><span class="btn-val">UAH</span> <span class="hover"></span></a>
        </div>
<!--        <div class="col-12 p-3">-->
<!--          <a class="btn btn-full" @click="handleSave()"><span class="btn-val">{{ $t('langswitcher_save') }}</span> <span class="hover"></span></a>-->
<!--        </div>-->
      </div>
    </div>

</template>
<script>
export default {
  name: 'LangSwitcher',
  props: ['location'],

  data() {
    return {
      isOpen: false,
      langLocally: null,//this.$store.state.lang,
      currencyLocally: null,//this.$store.state.currency,
    }
  },
  created() {
    window.addEventListener('click', (e) => {

      var thisComponent = this;
      setTimeout(function () {
        if(thisComponent.$refs.langSwitcher != null) {
          if (!thisComponent.$refs.langSwitcher.contains(e.target) && !thisComponent.$refs.langExpander.contains(e.target)) {

            if (thisComponent.isOpen == true) {
              thisComponent.isOpen = false;
              document.body.classList.remove('okiCollapseOpen');
            }
          }
        }
      }, 100);


    })
  },
  methods: {
    handleExpanderClick() {
      if(this.isOpen) {
        this.isOpen = false;
        document.body.classList.remove('okiCollapseOpen');
      } else {
        this.isOpen = true;
        document.body.classList.add('okiCollapseOpen');
      }
    },
    getCurrentLang() {
      if(this.langLocally != null)
        return this.langLocally.toUpperCase();
      else
        return this.$store.state.lang.toUpperCase();
    },
    getCurrentCurrency() {
      if(this.currencyLocally !== null)
        return this.currencyLocally.toUpperCase();
      else
        return this.$store.state.currency.toUpperCase();
    },
    handleLangChange(lang) {
      this.langLocally = lang;
      this.$root.$i18n.locale = this.langLocally;
      this.$store.dispatch('changeLang', this.langLocally);

      if(this.$root.$i18n.locale == 'ua') {
        document.body.classList.add('ua');
      } else {
        document.body.classList.remove('ua');
      }

      this.$router.push({name: this.$router.name, params: {'locale': this.$store.state.lang}});
      var langToPass = lang;
      if(lang == 'ua')
        langToPass = 'uk';
      window.OneTrust.changeLanguage(langToPass);

    },
    handleCurrencyChange(currency) {
      this.currencyLocally = currency;
      if(this.currencyLocally != null) {
        this.$store.dispatch('changeCurrency', this.currencyLocally);
      }
    },
    handleSave()
    {
      if(this.langLocally != null) {
        this.$root.$i18n.locale = this.langLocally;
        this.$store.dispatch('changeLang', this.langLocally);
      }
      if(this.currencyLocally != null) {
        this.$store.dispatch('changeCurrency', this.currencyLocally);
      }
      this.isOpen = false;
      document.body.classList.remove('okiCollapseOpen');
      this.langLocally = null;
      this.currencyLocally = null;

      document.documentElement.setAttribute("lang", this.$root.$i18n.locale);
      window.di.language = this.$root.$i18n.locale;

      if(this.$root.$i18n.locale == 'ua') {
        document.body.classList.add('ua');
      } else {
        document.body.classList.remove('ua');
      }

      this.$router.push({name: this.$router.name, params: {'locale': this.$store.state.lang}});
    }
  }

}
</script>