<template>
  <section class="how">
    <div class="wrap">
      <div class="row jcc aic">
        <div class="howCont col-12 col-lg-6 col-xl-5 order-lg-2">
          <h2>{{$t('page_about_how_works')}}</h2>
          <p>{{$t('page_about_how_works_desc')}}</p>
        </div>
        <div class="howImg col-12 col-lg-6 col-xl-5">
          <div class="mobile w-100"><img v-bind:src="this.getImageSrc()" alt="howImgMob"></div>
          <div class="desktop w-100"><img v-bind:src="this.getImageSrc()" alt="howImg"></div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  methods: {
    getImageSrc() {
      return require('@/assets/dist/img/howIMG_'+this.$store.state.lang+'.png');

    }
  }
}
</script>