<template>
  <section class="toolkit">
    <div class="wrap">
      <div class="row jcc">
        <div class="col-12 col-lg-6 col-xl-5">
          <h2><span class="ico"><img src="@/assets/dist/img/toolkitIco.svg" alt="toolkitIco"></span> Toolkit</h2>
          <a @click="InlineButtonClickHandler" class="btn btn-full btn-auto-lg" href="/download/toolkit.zip" download><span class="btn-val"><span class="icon icon-download"></span> {{$t('page_about_toolkit_download')}}</span> <span class="hover"></span></a>
        </div>
        <div class="col-12 col-lg-6 col-xl-5">
          <p>{{$t('page_about_toolkit_desc')}}</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  methods: {
    InlineButtonClickHandler(event) {
      event.section = 'About Toolkit';
      this.$emit('sateliteClick', event);
    }

  }
}
</script>