export default {
  "top_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДеОселитися"])},
  "langswitcher_lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мова"])},
  "langswitcher_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
  "langswitcher_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
  "home_banner_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДеОселитися?"])},
  "home_banner_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Віднайдіть себе у нас."])},
  "home_banner_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наш інструмент представить вам кілька населених пунктів разом з оглядом вартості проживання. Важливо, що він також запропонує потенційні можливості для працевлаштування та житла."])},
  "home_banner_content_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подивіться відео, і ми покажемо вам обрані міста."])},
  "home_banner_btn_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розпочати"])},
  "home_who_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для чого було створено ДеОселитися?"])},
  "home_who_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За ініціативи Mastercard®, за підтримки Групи Morizon - Gratka та Українського Дому ми створили сервіс, де в кілька кліків можна знайти житло, що відповідає Вашим потребам, перевірити пропозиції житла та працевлаштування."])},
  "home_who_strong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми підтримуємо біженців з України в пошуках свого місця в Польщі."])},
  "home_who_btn_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дізнатися більше"])},
  "home_earnings_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заробіток у даній галузі"])},
  "home_earnings_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати галузь, яка вас цікавить"])},
  "home_earnings_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дізнайтеся про середню, орієнтовну величину заробітку для даного воєводства в одній із кількох вибраних галузей*."])},
  "home_earnings_small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Середня величина заробітку без відрахувань у секторі підприємств і з розподілом за відповідними групами Польської класифікації видів діяльності (PKD)."])},
  "home_earnings_show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переглянути усі"])},
  "home_info_citizens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мешканців"])},
  "home_info_need_an_bank_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вам потрібен  <br>банківський рахунок у Польщі?"])},
  "home_info_need_an_bank_account_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банківський рахунок у місцевому банку допоможе вам почати роботу на новому місці. Перевірте список відділень у вибраному районі."])},
  "home_info_see_banks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переглянути адреси відділень"])},
  "home_info_check_cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зверніть увагу на міста"])},
  "home_faq_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поширені запитання"])},
  "home_faq_items_1_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чому і для кого Mastercard створив інструмент ДеОселитися (GdzieZamieszkać)?"])},
  "home_faq_items_1_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Інструмент ДеОселитися (GdzieZamieszkać) від Mastercard створено з думкою про громадян України, які шукають або знайшли вже в Польщі прихисток від війни. Однак він стане в пригоді кожному, хто зацікавлений у релокації, незалежно від причин і походження. Ми просто і швидко надаємо інформацію на тему середніх витрат на життя (вартості життя) і приблизного розміру заробітної плати, яких можна очікувати в різних місцях країни. Це наша підтримка у прийнятті рішень щодо того, де затриматися надовше.</p>"])},
  "home_faq_items_2_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Як працює інструмент ДеОселитися (GdzieZamieszkać)?"])},
  "home_faq_items_2_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Заповнена анкета є підставою для початку пошуку. Наш інструмент, що базується на анонімних даних Mastercard, статистичних даних Центрального статистичного управління (GUS) та доступних пропозиціях з веб-сайту Gratka.pl, пропонує рішення, які найбільше відповідають інформації, наданій в заповненій на початку анкеті.</p>"])},
  "home_faq_items_3_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Як працює інструмент? З якого періоду і звідки походять дані, представлені на сайті?"])},
  "home_faq_items_3_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Інструмент ДеОселитися (GdzieZamieszkać) базується на анонімізованих транзакційних даних Mastercard і загальнодоступних зовнішніх джерелах, зокрема, даних GUS (Головного статистичного управління). Надана інформація стосується періоду 01.06.2022 – 31.12.2022.</p>"])},
  "home_faq_items_4_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Що означає вираз «середні витрати на місяць»?"])},
  "home_faq_items_4_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Середні витрати на місяць на утримання даного домашнього господарства розраховуються на підставі відповідей, наданих у формі, та оціночної вартості життя середньостатистичної дорослої людини в даному місці Польщі. Вказані результати орієнтовні і можуть змінюватися в залежності від індивідуальних уподобань користувача і змінної економічної ситуації.</p>"])},
  "home_faq_items_5_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я вибрала у формі витрати на квартиру для 2 дорослих i 2 дітей. Чи саме такі витрати я матиму, приїхавши на місце?"])},
  "home_faq_items_5_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Вказані ціни є орієнтовними. На їх підставі можна зорієнтуватися, якими будуть приблизні витрати на оренду квартири у вибраному вами місці. Проте інструмент ДеОселитися (GdzieZamieszkać) не служить для порівняння цінових пропозицій чи цінових переговорів. На остаточні ціни впливає низка економічних факторів, у тому числі індивідуальні умови, залежні від постачальників товарів та послуг, які можуть мати вплив на те, що конкретна ціна відрізнятиметься від представлених інструментом середніх, орієнтовних ставок.</p>"])},
  "home_faq_items_6_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У випадному списку немає даних, які мене цікавлять. Що робити?"])},
  "home_faq_items_6_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Ми стараємося надати Вам якомога більше інформації, використовуючи доступні дані. Ми рекомендуємо Вам використовувати інструмент як є і заповнити інформацію, необхідну для отримання персоналізованого результату.</p>"])},
  "home_faq_items_7_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У випадному списку немає даних, які мене цікавлять. Що робити?"])},
  "home_faq_items_7_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Ми намагаємося надати вам якнайбільше інформації, користуючись доступними даними. Рекомендуємо скористатися інструментом у нинішній його формі і вписати потрібну інформацію, щоб отримати індивідуалізований результат.</p>"])},
  "home_faq_items_8_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чи це всі витрати, яких можна очікувати у запланованому місці?"])},
  "home_faq_items_8_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Інструмент створено для представлення орієнтовної і базової вартості життя в даному місці, тобто, наприклад, ціни оренди квартири, купівлі продуктів і транспортних витрат. У залежності від індивідуальних уподобань і потреб користувачів, а також додаткових чинників, остаточні витрати на утримання можуть бути іншими.</p>"])},
  "home_faq_items_9_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чи можна порівнювати середню вартість життя у різних населених пунктах Польщі?"])},
  "home_faq_items_9_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Так, проаналізувавши дані з анкети, інструмент ДеОселитися видає результат для трьох населених пунктів: поточного та двох інших, близьких до нього.</p>"])},
  "home_faq_items_10_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чи можна записати отримані результати і повернутися до них пізніше?"])},
  "home_faq_items_10_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Ви можете отримати багато результатів і кожен з них записати як окреме посилання, щоб можна було до них повернутися пізніше або надіслати рідним чи знайомим.</p>"])},
  "home_faq_items_11_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чи можна перевірити вартість життя в інших країнах, крім Польщі?"])},
  "home_faq_items_11_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Інструмент ДеОселитися (GdzieZamieszkać) дає можливість ознайомитися із середніми щомісячними витратами на утримання та заробітними платами виключно на території Польщі.</p>"])},
  "home_faq_items_12_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За яким курсом перераховуються суми EUR/UAH?"])},
  "home_faq_items_12_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Конвертер валют на сайті ДеОселитися (GdzieZamieszkać) актуалізується один раз на день на підставі середнього курсу іноземних валют Національного банку Польщі.</p>"])},
  "home_faq_items_13_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я хочу відкрити рахунок у банку, як мені знайти банківське відділення?"])},
  "home_faq_items_13_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Інструмент ДеОселитися (GdzieZamieszkać) після вписання відповідної локалізації допомагає знайти найближче банківське відділення, в якому можна відкрити рахунок. Виберіть кнопку Знайти відділення (Zobacz placówki) у секторі Вам потрібен банківський рахунок у Польщі? (Potrzebujesz konta bankowego w Polsce?), яка розташована в нижній частині головної сторінки.</p>"])},
  "home_faq_items_14_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За житловими критеріями, які є площі однокімнатної, 2-кімнатної, 3-кімнатної, 4-кімнатної чи багатокімнатної квартир?"])},
  "home_faq_items_14_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>В середньому, однокімнатна квартира має площу 32 м2, 2-кімнатна - 45 м2, 3-кімнатна - 60 м2, 4-кімнатна і більше - 110 м2.</p>"])},
  "home_faq_items_15_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чи вартість оренди, вказана інструментом ДеОселитися, відповідає дійсності?"])},
  "home_faq_items_15_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Вартість оренди, вказана інструментом ДеОселитися, є орієнтовною і може змінюватися в залежності від додаткових витрат, таких як комунальні послуги та ремонтний фонд.</p>"])},
  "home_faq_items_16_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чи я можу розраховувати на заробітну плату, подібну до тієї, яку пропонує інструмент ДеОселитися?"])},
  "home_faq_items_16_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Суми заробітної плати розраховані на основі поточних даних Центрального статистичного управління і приведені в брутто. Це лише середні суми заробітних плат в даній галузі. Вони не можуть бути підставою для претензій до роботодавців.</p>"])},
  "home_faq_items_17_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чому інструмент просить мене надати доступ до локалізації пристрою?"])},
  "home_faq_items_17_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Завдяки доступу до локалізації ми можемо надати список найближчих банківських відділень недалеко від вас або в цільовому населеному пункті.</p>"])},
  "home_faq_items_18_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чому інструмент ДеОселитися (GdzieZamieszkać) просить мене надати відомості щодо кількості членів моєї сім’ї?"])},
  "home_faq_items_18_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Ця інформація служить для оцінки середніх витрат на утримання в даному населеному пункті для вказаної у формі кількості осіб.</p>"])},
  "home_faq_items_19_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На яких пристроях працює інструмент ДеОселитися (GdzieZamieszkać)?"])},
  "home_faq_items_19_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Інструмент ДеОселитися (GdzieZamieszkać) працює на всіх пристроях типу комп’ютер, планшет, телефон. Сайт доступний також у мобільній і десктоп-версії.</p>"])},
  "home_faq_items_20_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Що означає термін «середня заробітна плата з урахуванням податків»?"])},
  "home_faq_items_20_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Під середньою заробітною платою з урахуванням податків розуміється середня винагорода, яку може отримати працівник за виконану роботу у вказаній галузі. «Cума з урахуванням податків», або інакше сума «брутто» означає суму перед відрахуванням (утриманням) з неї обов’язкових податків і державних внесків (прибуткового податку, внесків на соціальне страхування працівників). Після їх відрахування ми отримуємо чисту суму, або інакше суму «нетто», тобто винагороду за працю, яку працівник отримує «на руки» в день виплати заробітної плати. Внески, які відраховуються від суми «брутто» заробітної плати, залежать, зокрема, від: форми працевлаштування (виду трудового договору ) та розміру винагороди.</p>"])},
  "home_links_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корисні посилання"])},
  "home_links_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://pomagamukrainie.gov.pl/#potrzebuje-pomocy"])},
  "home_links_1_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допомога від уряду"])},
  "home_links_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://ukrainskidom.pl/"])},
  "home_links_2_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Громадська підтримка"])},
  "home_links_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.nfz.gov.pl/dla-pacjenta-z-ukrainy/"])},
  "home_links_3_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Охорона здоров’я"])},
  "terms_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Умови"])},
  "policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Політика конфіденційності"])},
  "footer_lang_and_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мова і валюта"])},
  "page_terms_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила користування послугою сайту GdzieZamieszkac.com"])},
  "page_terms_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "page_terms_starts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила сайту набувають чинності 5 липня 2022 року."])},
  "page_policy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Політика конфіденційності"])},
  "page_policy_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.mastercard.ua/uk-ua/vision/what-we-do/privacy.html"])},
  "page_about_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Як було створено <br>ДеОселитися."])},
  "page_about_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удосконалений інструмент, створений Mastercard, був доповнений актуальною інформацією, наданою партнерською Групою Morizon Gratka. Проєкт відбувся під патронатом Українського Дому"])},
  "page_about_powered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЗА ДОПОМОГОЮ"])},
  "page_about_partnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Партнери"])},
  "page_about_partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Партнери"])},
  "page_about_mastercard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastercard - глобальна компанія, що займається платіжними технологіями. Завдяки безпечному використанню даних, послуги та рішення Mastercard допомагають споживачам, установам та підприємствам реалізувати свій потенціал."])},
  "page_about_morizon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група Morizon-Gratka є провідним розробником веб-сайтів оголошень у Польщі. Вже понад 20 років Gratka.pl користується незмінною популярністю серед користувачів, які цікавляться оголошеннями в категоріях \"Нерухомість\", \"Автомобілі\" та \"Робота\". Morizon.pl - один з найбільших веб-сайтів з продажу нерухомості."])},
  "page_about_uah_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Український Дім"])},
  "page_about_uah_home_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Організація була створена завдяки Фонду \"Наш Вибір\" з метою сприяння інтеграції та підтримки української громади. Зараз він відкритий для всіх іноземців і пропонує простір для діяльності та обміну досвідом, а також відчуття приналежності до спільноти."])},
  "page_about_how_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Як працює інструмент?"])},
  "page_about_how_works_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інструмент аналізує агреговані дані Mastercard разом із загальнодоступними даними з офіційних джерел, щоб допомогти користувачам прийняти ключове рішення і знайти найкраще місце для проживання в Польщі, а також інформацію від наших Партнерів."])},
  "page_about_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми покладаємося на дані та інформацію"])},
  "page_about_data_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вам, напевно, цікаво, на чому саме базується робота ДеОселитися?"])},
  "page_about_data_mastercard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На основі анонімної вартості кошиків відповідних категорій покупок ми усереднили вартість проживання в конкретному місті та тарифи на житло."])},
  "page_about_data_gus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми взяли середню заробітну плату в певній галузі з публічно доступного звіту, який щороку публікується Центральним статистичним управлінням. "])},
  "page_about_data_morizon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропозиції працевлаштування та пропозиції щодо оренди житла взяті безпосередньо з актуальних оголошень, розміщених на сайті партнера."])},
  "page_about_data_title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastercard"])},
  "page_about_data_title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Центральне статистичне управління (GUS) "])},
  "page_about_data_title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група Morizon-Gratka"])},
  "page_about_toolkit_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інструментарій (1.3 MB)"])},
  "page_about_toolkit_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви хочете запросити до свого міста людей, які шукають миру і перспективи для себе? Завантажте підготовлений нами пакет, який містить поради, як зробити просте відео на цю тему, а також графічні матеріали, які ви можете використовувати для цієї форми просування."])},
  "page_about_story_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дізнайтеся їхні історії"])},
  "page_about_story_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Дивіться історії українок, які поділилися з нами своїм досвідом перших місяців перебування в нашій країні.</p>\n<p><strong>Наш інструмент „ДеОселитися\" був створений завдяки їм і з думкою про таких людей, як вони.</strong></p>"])},
  "page_about_btn_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заповнити форму"])},
  "banks_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відділення банків"])},
  "banks_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знайдіть адреси відділень банків у вашому районі або цільовому місці."])},
  "banks_type_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть назву міста/населеного пункту"])},
  "banks_see_on_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переглянути на карті"])},
  "banks_geolocation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виникла проблема з визначенням місцезнаходження. Скористайтеся пошуком."])},
  "steps_menu_step01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибір галузі"])},
  "steps_menu_step02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Члени домогосподарства"])},
  "steps_menu_step03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибір квартири"])},
  "steps_menu_step04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибір транспорту"])},
  "steps_menu_step05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибір міста"])},
  "steps_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далі"])},
  "steps_prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернутися"])},
  "step_01_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В якій галузі ви шукаєте роботу?"])},
  "step_01_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть галузь"])},
  "step_02_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скільки людей живе разом з Вами?"])},
  "step_02_adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дорослі (включаючи вас):"])},
  "step_02_kids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Діти (віком до 15 років):"])},
  "step_02_i_have_pets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У мене є домашні тварини"])},
  "step_02_pets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["тварина:"])},
  "step_02_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found"])},
  "step_03_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Яка нерухомість Вас цікавить?"])},
  "step_03_estate_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Квартира з 1 кімнатою"])},
  "step_03_estate_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Квартира з 2 кімнатами"])},
  "step_03_estate_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Квартира з 3 кімнатами"])},
  "step_03_estate_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Квартира з 4 і більше кімнат"])},
  "step_04_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якими транспортними засобами ви користуєтесь?"])},
  "step_04_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо ви користуєтеся власним транспортним засобом, ми розрахуємо середньомісячну вартість пального у даному населеному пункті."])},
  "step_04_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви можете зазначити обидва види транспорту."])},
  "step_04_public_transportation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Громадський <br>транспорт"])},
  "step_04_own_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Власний транспортний засіб"])},
  "step_05_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В якому населеному пункті Польщі Ви зараз проживаєте?"])},
  "step_05_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви отримаєте результат для вказаного населеного пункту та двох сусідніх."])},
  "step_05_type_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть назву міста/населеного пункту"])},
  "step_05_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Це місто недоступне, введіть іншу назву або виберіть зі списку нижче"])},
  "step_summary_compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Порівняйте найкращі місця проживання та роботи."])},
  "step_summary_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місто"])},
  "step_summary_other_cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["та два прилеглих населених пункти"])},
  "step_summary_industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Галузь"])},
  "step_summary_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Суми заробітної плати приведені в брутто і являють собою середні дані, отримані від Центрального статистичного управління (GUS). Вони не можуть бути підставою для претензій до роботодавців."])},
  "step_summary_filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відфільтрувати населені пункти за:"])},
  "step_summary_filter_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість пропозицій роботи"])},
  "step_summary_filter_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найвищий валовий заробіток"])},
  "step_summary_filter_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найнижча вартість життя"])},
  "step_summary_filter_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість квартир, що здаються в оренду"])},
  "step_summary_you_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше місцезнаходження"])},
  "step_summary_away_from": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("how_far")), " Від Вашого місцезнаходження"])},
  "step_summary_salary_gros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заробітна плата брутто"])},
  "step_summary_spendings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Витрати"])},
  "step_summary_job_offers_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кількість пропозицій працевлаштування"])},
  "step_summary_flats_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кількість квартир, що здаються в оренду"])},
  "step_summary_avg_salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["заробіток брутто*"])},
  "step_summary_filter_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["пропозиції роботи"])},
  "step_summary_filter_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["найвищий валовий заробіток"])},
  "step_summary_filter_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["найнижча вартість проживання, кількість квартир"])},
  "step_summary_filter_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["що здаються в оренду"])},
  "step_summary_earnings_spendings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["витрати"])},
  "step_summary_earnings_earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["заробіток брутто*"])},
  "step_summary_earnings_spendings_are_percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Витрати являють <br>\n <span class=\"val\">", _interpolate(_named("percent")), "%</span><br>\n від заробітної плати"])},
  "step_summary_earnings_small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Наведені нижче суми є орієнтовними для обраного міста. Щоб дізнатися більше про те, як розуміти заробітну плату брутто, дивіться розділ FAQ. "])},
  "step_summary_avg_spendings_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Середньомісячна величина витрат на утримання у місті <span class=\"yellow\">", _interpolate(_named("city")), "</span>"])},
  "step_summary_avg_earning_spendings_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Середні витрати та прибутки для галузі: <span class=\"yellow\">", _interpolate(_named("industry")), "</span>, у місті <span class=\"yellow\">", _interpolate(_named("city")), "</span>"])},
  "step_summary_family_label_adults_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["для дорослої людини"])},
  "step_summary_family_label_adults_many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["для сім'ї з ", _interpolate(_named("people")), " дорослих"])},
  "step_summary_family_label_family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["для сім'ї"])},
  "step_summary_family_label_detailed_adults_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 дорослий"])},
  "step_summary_family_label_detailed_adults_many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("people")), " дорослих"])},
  "step_summary_family_label_detailed_kids_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 дитина"])},
  "step_summary_family_label_detailed_kids_many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("people")), " дітей"])},
  "step_summary_family_label_detailed_pets_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 тварина"])},
  "step_summary_family_label_detailed_pets_many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("pets")), " тварин"])},
  "step_summary_family_label_detailed_public_transportation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["яка пересувається громадським транспортом"])},
  "step_summary_family_label_detailed_own_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["яка пересувається автомобілем"])},
  "step_summary_prices_are_aprox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Вказані нижче суми є орієнтовними для вибраного міста. Детальніше про те, що означає поняття «заробітна плата брутто» - у розділі поширених запитань (FAQ)."])},
  "step_summary_change_answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернутися до анкети"])},
  "step_summary_rent_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вартість оренди (однокімнатна квартира)"])},
  "step_summary_rent_1_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Середня ціна оренди квартири 32 м² в даному місті."])},
  "step_summary_rent_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вартість оренди (2 кімнати)"])},
  "step_summary_rent_2_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Середня ціна оренди квартири 45 м² в даному місті."])},
  "step_summary_rent_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вартість оренди (3 кімнати)"])},
  "step_summary_rent_3_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Середня ціна оренди квартири 60 м² в даному місті."])},
  "step_summary_rent_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вартість оренди (4 кімнати)"])},
  "step_summary_rent_4_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Середня ціна оренди квартири 110 м² в даному місті."])},
  "step_summary_rent_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вартість оренди (5 кімнати)"])},
  "step_summary_rent_5_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Середня ціна оренди квартири 120 м² в даному місті."])},
  "step_summary_supermarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Супермаркет"])},
  "step_summary_supermarket_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На основі суми середньомісячних витрат на одну дорослу людину на продукти харчування і побутову хімію."])},
  "step_summary_fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Витрати на паливо"])},
  "step_summary_fuel_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Середньомісячні витрати на 1 автомобіль."])},
  "step_summary_public_transportation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Громадський транспорт"])},
  "step_summary_public_transportation_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["На основі вартості одного місячного квитка в даному місті. Вартість одноразового квитка для дорослого в населеному пункті ", _interpolate(_named("city")), " становить ", _interpolate(_named("cost")), " ", _interpolate(_named("currency")), "."])},
  "step_summary_restaurants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ресторани"])},
  "step_summary_restaurants_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На основі суми середньомісячних витрат на одну дорослу людину."])},
  "step_summary_pets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тварини"])},
  "step_summary_pets_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На основі суми середньомісячних витрат на одну тварину в зоомагазинах."])},
  "step_summary_need_a_bank_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вам потрібен <br />банківський рахунок у Польщі?"])},
  "step_summary_bank_account_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банківський рахунок у місцевому банку допоможе вам почати роботу на новому місці. Перевірте список відділень у вибраному районі."])},
  "step_summary_total_annualy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Усього (щомісячно)"])},
  "step_summary_avg_earnings_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Середня заробітна плата<br />брутто ( з урахуванням податків) в місті", _interpolate(_named("city"))])},
  "step_summary_choose_industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати галузі, які вас цікавлять"])},
  "step_summary_avg_earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дізнайтеся про величину середнього заробітку у трьох галузях економіки, вибраних вами для вибраного місця*."])},
  "step_summary_avg_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Середнія зарплатня з урахуванням податків в секторі бізнесу і з розподілом за відповідними групами Польської класифікації видів діяльності (PKD)."])},
  "step_summary_select_other_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть інший регіон"])},
  "step_summary_citizens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мешканців"])},
  "step_summary_other_cities_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хочете перевірити інші міста/населені пункти?"])},
  "step_summary_other_cities_title_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрати воєводство, а потім місто/населений пункт, щоб перевірити середньомісячну величину витрат на утримання для потрібного місця."])},
  "step_summary_choose_other_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть інший регіон"])},
  "step_summary_type_city_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть назву міста/населеного пункту"])},
  "step_summary_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевірити"])},
  "step_summary_example_cities": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Приклад населених пунктів у воєводстві ", _interpolate(_named("district"))])},
  "step_summary_save_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти результати своєї форми"])},
  "step_summary_save_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти результати пошуку як посилання. Зберегти їх на потім або поділіться ними з родиною чи знайомими уже зараз."])},
  "step_summary_save_btn_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти результати"])},
  "step_summary_gratka_show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["переглянути всі пропозиції"])},
  "step_summary_gratka_estates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["квартири"])},
  "step_summary_gratka_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["робочих місць"])},
  "step_summary_gratka_jobs_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кількість пропозицій працевлаштування"])},
  "step_summary_gratka_estates_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кількість квартир, що здаються в оренду"])},
  "step_summary_gratka_jobs_count0to5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кількість пропозицій працевлаштування"])},
  "step_summary_gratka_estates_count0to5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кількість квартир, що здаються в оренду"])},
  "step_summary_gratka_flats_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Списки надані  Gratka.pl i Morizon.pl"])},
  "step_summary_gratka_jobs_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Списки надані Gratka.pl"])},
  "step_summary_find_flat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знайдіть роботу в обраному Вами населеному пункті"])},
  "step_summary_find_flat_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шукаєте житло? Це перші пропозиції, які ми відібрали для Вас на основі заповненої анкети. Ви можете знайти більше, вибравши вкладку \"всі пропозиції\"."])},
  "step_summary_find_flat_desc_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На даний момент ми не знайшли відповідного житла у вибраному Вами населеному пункті на <strong>Gratka.pl</strong> та <strong>Morizon.pl</strong>. Однак, якщо Ви вважаєте, що інструмент корисний, поверніться до нього через кілька днів, можливо, на той час з'являться нові оголошення."])},
  "step_summary_find_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знайдіть житло в обраному Вами населеному пункті"])},
  "step_summary_find_job_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дізнайтеся про вакансії, які ми відібрали для Вас. Перевірте повний список і знайдіть вакансію, яка Вам найбільше підходить."])},
  "step_summary_find_job_desc_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На даний момент ми не знайшли підходящих пропозицій роботи на <strong>Gratka.pl</strong> у вибраних Вами категоріях. Ринок праці змінюється дуже динамічно, будь ласка, поверніться до інструменту через кілька днів, щоб перевірити наявність нових оголошень."])},
  "step_summary_flat_rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оренда квартири"])},
  "step_summary_flat_room_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 кімната"])},
  "step_summary_flat_room_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 кімнати"])},
  "step_summary_flat_room_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 кімнати"])},
  "step_summary_flat_room_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 кімнати"])},
  "step_summary_flat_room_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 кімнати"])},
  "step_summary_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Залиште відгук про інструмент"])},
  "step_summary_modal_question1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чи виявився інструмент корисним?"])},
  "step_summary_modal_question2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чи була Вам корисна ця інформація?"])},
  "step_summary_modal_question3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чи є результати точними?"])},
  "step_summary_modal_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надішліть"])},
  "step_summary_modal_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дякуємо за вашу оцінку"])},
  "step_summary_go_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переглянути результати"])},
  "browser_not_support_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш браузер не підтримує цю функцію"])},
  "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-адресу скопійовано в буфер обміну"])},
  "copy_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сталася неочікувана помилка"])},
  "not_found_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми не змогли знайти таку сторінку"])},
  "not_found_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кілька кроків. Це все, що вам потрібно зробити, щоб знайти своє <br class=\"desktop\">місце в Польщі."])},
  "not_found_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернутися до головної сторінки"])},
  "industry_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Офісне адміністрування"])},
  "industry_B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будівництво/архітектура, геодезія"])},
  "industry_C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Освіта, навчання"])},
  "industry_D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Франчайзинг, власний бізнес"])},
  "industry_E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інтернет, електронна комерція, нові медіа"])},
  "industry_F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ІТ-адміністрування"])},
  "industry_G": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логістика, транспорт, експедирування"])},
  "industry_H": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркетинг, просування, PR"])},
  "industry_I": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автомобільна промисловість"])},
  "industry_J": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обслуговування клієнтів, кол-центр"])},
  "industry_K": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Право"])},
  "industry_L": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сільське господарство"])},
  "industry_M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прибиральниці, доглядальниці та домробітниці"])},
  "industry_N": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страхування"])},
  "industry_O": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закупівля"])},
  "industry_P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Державне управління, державний сектор"])},
  "industry_R": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Консалтинг, консультаційні послуги"])},
  "industry_S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фінанси, економіка, банківська справа"])},
  "industry_T": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["безпека та охорона праці, людські ресурси"])},
  "industry_U": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інжиніринг, будівництво, технології"])},
  "industry_W": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ІТ-програмування"])},
  "industry_X": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контроль якості"])},
  "industry_Y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Медіа, журналістика, розваги, культура"])},
  "industry_Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нерухомість"])},
  "industry_AA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фізична робота"])},
  "industry_AB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виробництво"])},
  "industry_AC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реклама, графічний дизайн, креатив, фотографія"])},
  "industry_AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продажі"])},
  "industry_AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Краса, здоров'я, спорт"])},
  "currency_pln": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zł"])},
  "currency_eur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€"])},
  "currency_uah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UAH"])},
  "district_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нижньосілезьке"])},
  "district_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Куявсько-Поморське"])},
  "district_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Люблінське"])},
  "district_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Любуське"])},
  "district_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лодзинське"])},
  "district_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малопольське"])},
  "district_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мазовецьке"])},
  "district_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опольське"])},
  "district_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підкарпатське"])},
  "district_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підляське"])},
  "district_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поморське"])},
  "district_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сілезьке"])},
  "district_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свентокшиське"])},
  "district_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вармінсько-Мазурське"])},
  "district_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Великопольське"])},
  "district_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Західнопоморське"])},
  "district_ms_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нижньосілезьке"])},
  "district_ms_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Куявсько-Поморське"])},
  "district_ms_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Люблінське"])},
  "district_ms_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Любуське"])},
  "district_ms_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лодзинське"])},
  "district_ms_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Малопольське"])},
  "district_ms_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мазовецьке"])},
  "district_ms_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опольське"])},
  "district_ms_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підкарпатське"])},
  "district_ms_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підляське"])},
  "district_ms_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поморське"])},
  "district_ms_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сілезьке"])},
  "district_ms_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свентокшиське"])},
  "district_ms_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вармінсько-Мазурське"])},
  "district_ms_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Великопольське"])},
  "district_ms_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Західнопоморське"])},
  "toolkit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інструментарій"])},
  "meta_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДеОселитися? Mastercard"])},
  "meta_title_wheretosettle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where to settle as a Ukrainian refugee? - Where to settle? Mastercard"])},
  "meta_title_costofliving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Cost of Living in Poland - Where to settle? Mastercard"])},
  "meta_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дізнайтеся про вартість проживання, актуальні вакансії та пропозиції житла в Польщі."])},
  "help_ua_get_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримайте допомогу з адміністративних, юридичних та багатьох інших питань."])},
  "help_ua_goto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти за посиланням"])},
  "patronage_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Меценатство"])},
  "patronage_cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Асоціація міст Польщі"])},
  "patronage_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найстаріша самоврядна організація в країні, діяльність якої спрямована на підтримку польських міст у їхньому соціальному та економічному розвитку, а також на поширення передового досвіду сучасного та інноваційного управління самоврядними громадами мешканців."])},
  "home_school_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Навчальні заклади в цьому районі "])},
  "home_school_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дізнайтеся, де є школи, що пропонують навчання українською мовою."])},
  "home_school_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показати школи"])},
  "schools_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Навчальні заклади"])},
  "schools_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Обираючи школу, що пропонує можливість навчання українською мовою, важливо особисто дізнатися про навчальну програму в закладі. Кількість місць у школах обмежена*.</p>\n<p>Часто, коли в районі немає школи з навчанням українською мовою, місцеві громади шукають альтернативи, наприклад, організовують позашкільні заняття для дітей. Варто додатково дізнатися про таку можливість в обраному Вами населеному пункті.</p>\n<p style=\"font-size: 14px;\">*Детальну інформацію про зарахування та можливі оплати можна знайти на веб-сайтах окремих закладів. Щоб дізнатися про правила прийому українських дітей до польських шкіл, ознайомтеся з інформацією на сайті: \n<a href=\"https://www.gov.pl/web/psse-lask/zasady-przyjmowania-dzieci-z-ukrainy-do-polskich-szkol\" target=\"_blank\" rel=\"nofollow\">https://www.gov.pl/web/psse-lask/zasady-przyjmowania-dzieci-z-ukrainy-do-polskich-szkol</a></p>"])},
  "school_list_1_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Школа \"Материнка\" ім. Д.Павличка Український навчальний заклад у Варшаві"])},
  "school_list_1_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zgoda 5/5, Warsaw, Poland"])},
  "school_list_1_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.ukranska-shkola-materinka.mozello.com"])},
  "school_list_1_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/vV4N381ThyWG1m6W9"])},
  "school_list_2_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Варшавська українська школа"])},
  "school_list_2_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grójecka 128A, 02-383 Warszawa"])},
  "school_list_2_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.vshkolu.edu.pl"])},
  "school_list_2_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/gVL7UvF793nhvNqx6"])},
  "school_list_3_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перша українська школа в Польщі від фундації «Незламна Україна"])},
  "school_list_3_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al. Jerozolimskie 179, 02-222, Warszawa"])},
  "school_list_3_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.nezlamna.org"])},
  "school_list_3_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "school_list_4_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Українська суботня школа для дітей - ZUwP Wrocław"])},
  "school_list_4_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruska 46/202, 50-079 Wrocław"])},
  "school_list_4_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.facebook.com/CUKRuska/"])},
  "school_list_4_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/KcfeKDv2xMAAX7VG7"])},
  "school_list_5_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перша українська школа в Польщі від фундації «Незламна Україна"])},
  "school_list_5_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aleja Wiśniowa, 1, 53-313, Wrocław"])},
  "school_list_5_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.nezlamna.org"])},
  "school_list_5_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/K4cqHAi6h96CEtGR8"])},
  "school_list_6_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перша українська школа в Польщі від фундації «Незламна Україна  www.nezlamna.org"])},
  "school_list_6_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Świętego Filipa 25, 31-150, Kraków"])},
  "school_list_6_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.nezlamna.org"])},
  "school_list_6_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "school_list_7_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["І Загальноосвітний ліцей ім. Т.Шевченка"])},
  "school_list_7_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dworcowa 25, 78-425 Biały Bór"])},
  "school_list_7_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.liceum-bialybor.pl"])},
  "school_list_7_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/mzGdBQ5V2FobKjQS9"])},
  "school_list_8_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Початкова школа №2 ім. Тараса Шевченка в Білому Борі (Польща)"])},
  "school_list_8_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Słupska 27, 78-425 BIAŁY BÓR"])},
  "school_list_8_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.sp2bbor.eu"])},
  "school_list_8_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/xp1ZXGCETVjHWEHu5"])},
  "school_list_9_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Початкова школа №8"])},
  "school_list_9_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leśna 1, 11-200 Bartoszyce"])},
  "school_list_9_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.lesiabart.pl"])},
  "school_list_9_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/dDhqnnw4VRzwWZ7L7"])},
  "school_list_10_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комплекс шкіл з українською мовою навчання в Гурові Ілавецькому"])},
  "school_list_10_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkolna 6, 11-220 Górowo Iławeckie"])},
  "school_list_10_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.szkolazdumka.pl"])},
  "school_list_10_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/9x2ZE7K73xosxWwi9"])},
  "school_list_11_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЗОШ-комплекс No2 ім Маркіяна Шашкевича в Перемишлі"])},
  "school_list_11_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smolki 10, 37-700 Przemyśl"])},
  "school_list_11_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" www.szaszk.edu.pl"])},
  "school_list_11_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/bDrmq4aD42uN5L1d7"])},
  "school_list_12_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Початкова школа з Філіями Спортивної Першості № 9 імені А. Міцкевича, вул. Cmentarna 1 Підготовчі класи"])},
  "school_list_12_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cmentarna 1, 44-200 Rybnik"])},
  "school_list_12_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.sp9.miastorybnik.pl/for-foreigners/"])},
  "school_list_12_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/81wc3ZfLp8Yo8Q7Y9"])},
  "school_list_13_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Початкова школа ім. А. Міцкевича в Більську-Підляському (окремі українські класи з навчанням української мови)"])},
  "school_list_13_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adama Mickiewicza 126"])},
  "school_list_13_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zs-mickiewicz.szkolnastrona.pl"])},
  "school_list_13_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/vqk8j2ztkUqggW8GA"])},
  "school_list_14_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Початкова школа та дитячий садок в Черемсі"])},
  "school_list_14_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkolna 2, 17-240 Czeremcha"])},
  "school_list_14_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zspczeremcha.pl/index.php/pl/"])},
  "school_list_14_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/fvonmV4zwwHyMcWh6"])},
  "school_list_15_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приватна початкова школа св. Кирила і Мефодія"])},
  "school_list_15_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ludwika Waryńskiego 30, 15-461 Białystok"])},
  "school_list_15_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szkolacim.szkolnastrona.pl"])},
  "school_list_15_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/htAj9RbNkfub4Zdi6"])},
  "school_list_16_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Легницька середня школа №4"])},
  "school_list_16_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarasa Szewczenki 10, 59-220;"])},
  "school_list_16_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zso4legnica.pl"])},
  "school_list_16_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/Tu7uDEJ5teBQWoN97"])},
  "summary_schools_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Навчальні заклади в цьому районі"])},
  "summary_schools_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дізнайтеся, де є школи, що пропонують навчання українською мовою. "])},
  "summary_schools_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показати школи"])},
  "see_on_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дивіться на карті"])},
  "page_wheretosettle_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where to settle as a Ukrainian refugee?"])}
}