<template>

  <section class="steps stepsProperty">
    <div class="wrap visible">


      <div class="row jcc">
        <div class="stepsNav col-12 col-lg-10 px-0 px-lg-3">
          <StepsMenu :active="3"/>
        </div>
      </div>


      <div class="row aic jcc">
        <div class="stepsDesc col-12 ta-c px-0">
          <div class="row jcc">
            <div class="col-12 col-lg-10 mb-lg-5">
              <div class="step"><p class="yellow"><strong><span class="val">3</span>/5</strong></p></div>

              <h1 v-bind:class="{'error': this.showError}" class="mb-5 h2" v-html="$t('step_03_title')"></h1>
              <form>
                <div class="row jcc">
                  <div class="radioBox col col-12 col-lg-auto">
                    <input v-model="this.estate" type="radio" name="property" id="property01" :value="1">
                    <label for="property01">{{$t('step_03_estate_1')}}</label>
                  </div>
                  <div class="radioBox col col-12 col-lg-auto">
                    <input v-model="this.estate" type="radio" name="property" id="property02" :value="2">
                    <label for="property02">{{$t('step_03_estate_2')}}</label>
                  </div>
                  <div class="radioBox col col-12 col-lg-auto">
                    <input v-model="this.estate" type="radio" name="property" id="property03" :value="3">
                    <label for="property03">{{$t('step_03_estate_3')}}</label>
                  </div>
                  <div class="radioBox col col-12 col-lg-auto">
                    <input v-model="this.estate" type="radio" name="property" id="property04" :value="4">
                    <label for="property04">{{$t('step_03_estate_4')}}</label>
                  </div>
                </div>
              </form>
            </div>

            <div class="stepDescNav col-12 co-lg-10 px-0 mt-auto">
              <div class="row jcc">
                <div class="col-6 mobile">
                  <router-link :to="{'name': 'step02', params: {'locale': this.$store.state.lang}}" @click="InlineButtonClickHandler" class="btn btn-w btn-full"><span class="btn-val">{{$t('steps_prev')}}</span> <span class="hover"></span></router-link>
                </div>
                <div class="col-6 col-lg-auto desktop">
                  <router-link :to="{'name': 'step02', params: {'locale': this.$store.state.lang}}" @click="InlineButtonClickHandler" class="btn btn-ghost btn-full"><span class="btn-val">{{$t('steps_prev')}}</span> <span class="hover"></span></router-link>
                </div>
                <div class="col-6 col-lg-auto offset-lg-1">
                  <router-link :to="this.nextStepAvailable() == false ? '' : {'name': 'step04', params: {'locale': this.$store.state.lang}}" @click="InlineButtonClickHandler"  class="btn btn-full" v-bind:class="{'btn-g': !this.nextStepAvailable()}"><span class="btn-val">{{$t('steps_next')}}</span> <span class="hover"></span></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import StepsMenu from "@/views/partials/StepsMenu";
export default {
  components: {StepsMenu},
  watch: {
    'estate': function() {
      this.$store
          .dispatch('setEstate', {
            'estate': this.estate
          })
      ;
      this.showError = false;
    }

  },
  data() {
    return {
      estate: null,
      showError: false
    }
  },
  created() {
    if(this.$store.state.config.estate != null) {
      this.estate = this.$store.state.config.estate;
    }
  },
  mounted() {
  },
  methods: {
    nextStepAvailable() {
      var result = true;
      if(this.estate == null || this.estate == '')
        result = false;

      return result;
    },
    InlineButtonClickHandler(event) {
      this.validateStep();
      this.$emit('analyticsEvent', 'step04');

      event.section = 'Step03';
      this.$emit('sateliteClick', event);
    },
    validateStep() {
      if(this.estate == null)
        this.showError = true;
    }
  }
}
</script>