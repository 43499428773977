<template>
  <section class="notFound">
    <div class="wrap">
      <div class="row jcc jcs-lg">
        <div class="col-11 col-lg-6 offset-lg-1 ta-c ta-l-lg">
          <h1>{{ $t('not_found_title') }}</h1>
          <p v-html="$t('not_found_caption')"></p>
          <router-link :to="{ name: 'home', params: {'locale': 'pl'}}" class="btn btn-half"><span class="btn-val" v-html="$t('not_found_back')"></span> <span class="hover"></span></router-link>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  mounted() {
    this.$store
        .dispatch('changeFooterVisibility', {
          'visibility': true
        })
    ;
  }
}
</script>