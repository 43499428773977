import { createI18n } from 'vue-i18n'


function loadLocaleMessages() {
    const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([a-z0-9_-]+)\./i) // [a-z0-9] -> [a-z0-9_-]
        if (matched && matched.length > 1) {
            var locale = matched[1]
            messages[locale] = locales(key).default
        }
    })

    return messages
}
var defaultLanguage = 'pl';
var langs = ['pl', 'en', 'ua'];
var navigatorLanguage = navigator.language.toLowerCase();
if(navigatorLanguage.indexOf('-') !== -1)
    navigatorLanguage = navigatorLanguage.substr(0, navigatorLanguage.indexOf('-'));

for(var i in langs) {
    if(navigatorLanguage == langs[i])
        defaultLanguage = langs[i];
}

export const defaultLocale = defaultLanguage

export default createI18n({
    locale: defaultLanguage,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'pl',
    messages: loadLocaleMessages()
})