<template>
  <section class="story">
    <div class="wrap visible">
      <div class="row jce-lg">

        <div class="storyList storyListSlider col-12 col-xl-11 px-0">

            <div class="storyListSingle col-12 px-0" :key="slide01">
              <div class="row">
                <Splide :options="{drag: true}"  class="storyListSingleVideo col-12 col-lg-7 order-lg-2">
                  <SplideSlide :key="slide01">
                    <video width="100%" height="480" preload="none" poster="@/assets/dist/img/603x383_UA_01.jpg" controls ref="aboutVideo1" @progress="this.handleProgress(1)" @ended="this.handleSatelite('100%', 1)" @play="this.handleSatelite('Play', 1)" @pause="this.handleSatelite('Pause', 1)">
                      <source src="/video/Video_where_to_settle_UA_1.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                  </SplideSlide>
                  <SplideSlide :key="slide02">
                    <video width="100%" height="480" preload="none" poster="@/assets/dist/img/603x383_UA_2.jpg" controls ref="aboutVideo2" @progress="this.handleProgress(2)" @ended="this.handleSatelite('100%', 2)" @play="this.handleSatelite('Play', 2)" @pause="this.handleSatelite('Pause', 2)">
                      <source src="/video/Video_where_to_settle_UA_2.mp4" type="video/mp4" >
                      Your browser does not support the video tag.
                    </video>
                  </SplideSlide>
                  <SplideSlide :key="slide03">
                    <video width="100%" height="480" preload="none" poster="@/assets/dist/img/603x383_UA3.jpg" controls ref="aboutVideo3" @progress="this.handleProgress(3)" @ended="this.handleSatelite('100%', 3)" @play="this.handleSatelite('Play', 3)" @pause="this.handleSatelite('Pause', 3)">
                      <source src="/video/Video_where_to_settle_UA_3.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                  </SplideSlide>
                </Splide>

                <div class="storyListSingleDesc col-12 col-lg-5">
                  <h3>{{$t('page_about_story_title')}}</h3>
                  <div v-html="$t('page_about_story_desc')"></div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </section>
</template>
<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default {
  name: 'HomeView',
  components: {
    Splide, SplideSlide
  },
  data() {
    return {
      eventsDone: {
        'video_1': [],
        'video_2': [],
        'video_3': []
      }
    }
  },
  methods: {
    handleSatelite(event, index) {

      var videos = ['Video_where_to_settle_UA_1.mp4', 'Video_where_to_settle_UA_2.mp4', 'Video_where_to_settle_UA_3.mp4'];

      window._satellite.track('video',
          {
            site_section:'About',
            button_name: event,
            video_name: videos[index-1],
            video_action: event,
            pageURL: window.top.location.href
          }
      );

    },
    handleProgress(index) {
      if(this.$refs['aboutVideo'+index] == undefined)
        return;

      var percent = (this.$refs['aboutVideo'+index].currentTime / this.$refs['aboutVideo'+index].duration) * 100;
      var event = null;
      if(percent >= 100)
        event = '100%';
      else if(percent >= 75)
        event = '75%';
      else if(percent >= 50)
        event = '50%';
      else if(percent >= 25)
        event = '25%'
      else
        return;

      if(this.eventsDone['video_'+index].indexOf(event) != -1)
        return;

      this.eventsDone['video_'+index].push(event);

      var videos = ['Video_where_to_settle_UA_1.mp4', 'Video_where_to_settle_UA_2.mp4', 'Video_where_to_settle_UA_3.mp4'];

      window._satellite.track('video',
          {
            site_section:'About',
            button_name: videos[index-1],
            video_name: videos[index-1],
            video_action: event,
            pageURL: window.top.location.href
          }
      );
    }
  }
}
</script>