<template>
  <section class="edu">
    <div class="wrap visible">
      <div class="row">
        <div class="eduDesc col-12 col-lg-5 offset-lg-1">
          <div class="icon"><img src="@/assets/dist/img/edu.svg" alt="edu"></div>
          <h2>{{$t('home_school_title')}}</h2>
        </div>
        <div class="col-12 col-lg-5">
          <p>{{$t('home_school_desc')}}</p>
          <router-link @click="InlineButtonClickHandler" :to="{ name: 'schools', params: {'locale': this.$store.state.lang}}"  class="btn"><span class="btn-val">{{$t('home_school_btn')}}</span> <span class="hover"></span></router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    InlineButtonClickHandler(event) {
      event.section = 'Home Schools';
      this.$emit('sateliteClick', event);
    }
  }
}
</script>