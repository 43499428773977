<template>
  <footer class="">

    <div class="wrap">
      <div class="row jcc jcb-lg aic">
        <div class="footLang col-12 col-lg-auto order-lg-12">
          <LangSwitcher v-bind:location="footer"/>
        </div>
        <div class="footMenu col-12 col-lg-auto order-lg-10">
          <ul>
            <li><a @click="this.handleSateliteClick($event)" href="/download/toolkit.zip" download>{{$t('toolkit')}}</a></li>
            <li><router-link :to="{ name: 'about', params: {'locale': this.$store.state.lang}}">{{ $t('home_who_btn_label')}}</router-link></li>
            <li><router-link @click="this.handleSateliteClick($event)" :to="{name: 'terms', params: {'locale': this.$store.state.lang}}">{{ $t('terms_label') }}</router-link></li>
            <li><a @click="this.handleSateliteClick($event)" v-bind:href="$t('page_policy_content')" target="_blank">{{ $t('policy_label') }}</a></li>

          </ul>
        </div>
        <div v-if="this.$store.state.lang == 'en'" class="footMenu col-12 col-lg-auto order-lg-10">
          <h5><strong>Press</strong></h5>
          <ul>
            <li><router-link :to="{ name: 'wheretosettle', params: {'locale': this.$store.state.lang}}">{{ $t('wheretosettle_label')}}</router-link></li>
            <li><router-link :to="{ name: 'costofliving', params: {'locale': this.$store.state.lang}}">{{ $t('costofliving_label')}}</router-link></li>
          </ul>
        </div>
        <div class="footLogo col-auto col-lg-auto">
          <router-link @click="this.handleSateliteClick($event)" :to="{'name': 'home', params: {'locale': this.$store.state.lang}}">
            <img src="@/assets/dist/img/logo.svg" v-bind:alt="$t('home_banner_title')">
            <span class="title">{{ $t('top_title') }}</span>
          </router-link>
        </div>
      </div>
    </div>

  </footer>
</template>
<script>
import LangSwitcher from '../widgets/LangSwitcher';
export default {
  components: {
    LangSwitcher
  },
  methods: {
    handleSateliteClick(event) {

      event.section = 'Footer';
      this.$emit('sateliteClick', event);

    }
  }
}
</script>