import { createStore } from 'vuex'
import axios from "axios";

export default createStore({
  state: {
    lang: 'pl',
    currency: 'pln',
    footerVisibility: true,
    industries: [],
    industries_slugs: [],
    earnings_by_district: [],
    biggest_cities: [],
    config: {
      uuid: null,
      industry: null,
      estate: null,
      region: null,
      city: null,
      adults: null,
      kids: null,
      pets: null,
      publicTransportation: false,
      ownVehicle: false
    },
    costs: null,
    total: null
  },
  getters: {
  },
  mutations: {
    clearConfig(state) {
      state.config.industry = null;
      state.config.region = null;
      state.config.city = null;
      state.config.adults = null;
      state.config.kids = null;
      state.config.pets = null;
      state.config.estate = null;
      state.config.publicTransportation = false;
      state.config.ownVehicle = false;
    },
    setIndustries(state, industries) {
      state.industries = industries;
    },
    setIndustriesSlugs(state, industries) {
      state.industries_slugs = industries;
    },
    setEarningsByDistrict(state, earnings) {
      state.earnings_by_district = earnings;
    },
    setBiggestCities(state, cities) {
      state.biggest_cities = cities;
    },
    setConfigProperty(state, item) {
      state.config[item.field] = item.value;
    },
    setCosts(state, costs) {
      state.costs = costs
    },
    setUuid(state, uuid) {
      state.config.uuid = uuid;
    },
    setTotal(state, total) {
      state.total = total;
    }
  },
  actions: {
    changeLang(store, lang) {

      store.state.lang = lang;

    },
    changeCurrency(store, currency) {
      store.state.currency = currency;
    },
    changeFooterVisibility(store, options) {
      store.state.footerVisibility = options.visibility;
    },
    clearConfig(store) {
      store.commit('clearConfig');
    },
    setIndustry(store, options) {
      store.commit('setConfigProperty', {
        'field': 'industry',
        'value': options.industry == "" ? null : options.industry
      });
    },
    setEstate(store, options) {
      store.commit('setConfigProperty', {
        'field': 'estate',
        'value': options.estate == "" ? null : options.estate
      });
    },
    setRegion(store, options) {
      store.state.config.region = options.region;
    },
    setCity(store, options) {
      store.state.config.city = options.city;
      store.state.config.region = options.city.woj_no
    },
    setAdults(store, options) {
      store.commit('setConfigProperty', {
        'field': 'adults',
        'value': options.adults
      });
    },
    setKids(store, options) {
      store.commit('setConfigProperty', {
        'field': 'kids',
        'value': options.kids
      });
    },
    setPets(store, options) {
      store.commit('setConfigProperty', {
        'field': 'pets',
        'value': options.pets
      });

    },
    setPublicTransportation(store, options) {
      store.commit('setConfigProperty', {
        'field': 'publicTransportation',
        'value': options.publicTransportation
      });

    },
    setOwnVehicle(store, options) {
      store.commit('setConfigProperty', {
        'field': 'ownVehicle',
        'value': options.ownVehicle
      });

    },
    setTotal(store, total) {
      store.commit('setTotal', total);
    },
    fetchIndustries(store) {
      if(store.state.industries.length > 0)
        return store.state.industries;

      return fetch(process.env.VUE_APP_MC_INDUSTRIES_ENDPOINT)
          .then(response => response.json())
          .then(data=> {

            store.commit('setIndustries', data)
            return store.state.industries
          })
    },
    fetchIndustriesSlugs(store) {
      if(store.state.industries_slugs.length > 0)
        return store.state.industries_slugs;

      return fetch(process.env.VUE_APP_MC_INDUSTRIES_ENDPOINT + '/slugs')
          .then(response => response.json())
          .then(data=> {

            store.commit('setIndustriesSlugs', data)
            return store.state.industries_slugs
          })
    },
    fetchAvgEarningsByDistrict(store, options = null) {
      if(store.state.earnings_by_district.length > 0 && options == null)
        return store.state.earnings_by_district;

      return fetch(process.env.VUE_APP_MC_AVG_EARNINGS_ENDPOINT + (options != null ? '/' + options.industry : ''))
          .then(response => response.json())
          .then(data=> {

            store.commit('setEarningsByDistrict', data)
            return store.state.earnings_by_district
          })
    },
    fetchBiggestCities(store, options = null) {

      if(store.state.biggest_cities.length > 0 && options == null)
        return store.state.biggest_cities;

      var params = '';
      if(options != null && 'district' in options)
        params = '/' + options.district;

      if(options != null && 'industry' in options)
        params += '?industry=' + options.industry;

      console.log(params);
      return fetch(process.env.VUE_APP_MC_POPULATION_ENDPOINT + '/biggest-cities' + params)
          .then(response => response.json())
          .then(data=> {

            store.commit('setBiggestCities', data)
            return store.state.biggest_cities
          })
    },
    fetchCityAutocomplete(store, search) {

        var searchString = '';
        if( typeof search === 'object' && 'region' in search)
            searchString = search.region +'/'+search.phrase;
        else
            searchString = 'null/'+search;
      return fetch(process.env.VUE_APP_MC_CITY_ENDPOINT + '/autocomplete/'+searchString)
          .then(response => response.json())
          .then(data=> {
            return data;
          })
    },
    fetchBanks(store, options) {
      var url = 'https://maps.googleapis.com/maps/api/place/nearbysearch/json?location='+options.coords.latitude+','+options.coords.longitude+'&radius=15000&type=bank&key='+process.env.VUE_APP_MC_GOOGLE_KEY;

      return fetch(url, {
        'mode': 'no-cors'
      })
          .then(response => {
            console.log(response);
          })
    },
    fetchCosts(store) {

      return fetch(process.env.VUE_APP_MC_CITY_ENDPOINT + '/costs/city/' + store.state.config.city.id)
          .then(response => response.json())
          .then(data=> {

            store.commit('setCosts', data)
            return store.state.costs
          })
    },
    fetchEstates(store) {

      return fetch(process.env.VUE_APP_MC_CITY_ENDPOINT + '/costs/city/' + store.state.config.city.id + '/flats?estate=' + store.state.config.estate)
          .then(response => response.json())
          .then(data=> {

            //store.commit('setCosts', data)
            return data
          })
    },
    fetchJobs(store) {

      return fetch(process.env.VUE_APP_MC_CITY_ENDPOINT + '/costs/city/' + store.state.config.city.id + '/jobs?industry=' + store.state.config.industry)
          .then(response => response.json())
          .then(data=> {

            //store.commit('setCosts', data)
            return data
          })
    },
    fetchRegionsMap() {

      return fetch(process.env.VUE_APP_MC_CITY_ENDPOINT + '/costs/city/regions-map')
          .then(response => response.json())
          .then(data=> {

            //store.commit('setCosts', data)
            return data
          })
    },
    fetchNearBy(store, options) {

      return fetch(process.env.VUE_APP_MC_CITY_ENDPOINT + '/costs/city/' + store.state.config.city.id + '/nearby/' + options.filter + '/' + this.state.config.industry)
          .then(response => response.json())
          .then(data=> {

            //store.commit('setCosts', data)
            return data
          })
    },
    saveReview(store, data) {
      return axios.post(process.env.VUE_APP_MC_POLL_ENDPOINT + '/save/review', data)
          .then((res) => {

            return res;
          });
    },
    saveConfig(store, uuid = null) {
      return axios.post(process.env.VUE_APP_MC_POLL_ENDPOINT + '/save' + (uuid != null ? '/' + uuid : ''), store.state.config)
      .then((res) => {

        store.commit("setUuid", res.data.uuid);

        return res;
      });
    },
    getConfig(store, uuid) {
      return fetch(process.env.VUE_APP_MC_POLL_ENDPOINT + '/get/' + uuid)
          .then(response => response.json())
          .then(data=> {

            store.commit('setUuid', uuid);
            for(var i in data) {
              store.commit('setConfigProperty', {
                'field': i,
                'value': data[i]
              });
            }

            return data;
          })
    }
  },
  modules: {
  }
})
