<template>
<div class="d-none"></div>
</template>
<script>
export default {

  methods: {
    handleClick(event) {


      var label = '';
      if(event.target != undefined)
        label = event.target.innerText;


      window._satellite.track("link_click", {
            site_section: event.section,
            button_name: label,
            pageURL: window.top.location.href
          }
      );
    },
  }
}
</script>