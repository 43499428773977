<template>
  <AboutBanner :page-title="this.pageTitle" :page-content="this.pageContent"/>
  <AboutPartners />
  <AboutHow />
  <AboutData />
  <AboutToolkit @sateliteClick="this.handleSateliteClick" />
  <AboutStory />
  <FixedPanel @sateliteClick="this.handleSateliteClick" :label="$t('page_about_btn_label')" />
</template>


<script>

import FixedPanel from "@/views/partials/FixedPanel.vue";
import AboutBanner from "@/views/partials/AboutBanner.vue";
import AboutPartners from "@/views/partials/AboutPartners.vue";
import AboutHow from "@/views/partials/AboutHow.vue";
import AboutData from "@/views/partials/AboutData.vue";
import AboutToolkit from "@/views/partials/AboutToolkit.vue";
import AboutStory from "@/views/partials/AboutStory.vue";


export default {
  components: {FixedPanel, AboutBanner, AboutPartners, AboutHow, AboutData, AboutToolkit, AboutStory},
  props: ['pageTitle', 'pageContent', 'pageStartsFrom'],
  mounted() {
    this.$store
        .dispatch('changeFooterVisibility', {
          'visibility': true
        })
    ;
  },
  methods: {
    getCurrentLang() {
      return this.$store.state.lang;
    },
    handleSateliteClick(event) {

      this.$emit('sateliteClick', event);
    },
  }
}
</script>