<template>
  <header class="">

    <div class="wrap">
      <div class="row jcb aic nowrap">
        <div class="logo col-auto">
          <router-link @click="this.handleSateliteClick($event)" :to="{ name: 'home', params: {'locale': this.$store.state.lang}}">
            <img src="@/assets/dist/img/logo.svg" v-bind:alt="$t('home_banner_title')">
            <span class="title">{{ $t('top_title') }}</span>
          </router-link>
        </div>
        <div class="headLang col-auto">
          <LangSwitcher v-bind:location="header"/>
        </div>
      </div>
    </div>

  </header>
</template>

<script>
import LangSwitcher from '../widgets/LangSwitcher';

export default {
  components: {
    LangSwitcher
  },
  methods: {
    handleSateliteClick(event) {

      var label = '';
      if(event.target != undefined)
        label = event.target.innerText;


      window._satellite.track("link_click", {
            site_section: 'Header',
            button_name: label,
            pageURL: window.top.location.href
          }
      );
    }
  }
}
</script>