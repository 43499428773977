<template>
  <section class="save">
    <div class="wrap visible">
      <div class="row">
        <div class="saveDesc col-12 col-lg-5 offset-lg-1">
          <span class="icon icon-wallet"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
          <h2>{{$t('step_summary_save_title')}}</h2>
        </div>
        <div class="col-12 col-lg-5">
          <p>{{$t('step_summary_save_caption')}}</p>
          <div v-if="this.link != null && 0">
            <input class="copy-input" type="text" ref="linkInput" v-bind:value="this.link" />
          </div>
          <a @click="this.copyTextToClipboard" class="btn"><span class="btn-val">{{$t('step_summary_save_btn_label')}} <span class="icon icon-link"></span></span> <span class="hover"></span></a>

          <div class="copy-message" v-bind:class="{error: this.copyMessageError, success: this.copyMessageSuccess}">
            {{this.copyMessage}}

          </div>

        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  watch: {
    '$store.state.lang': function () {
      this.handleSave();
    }
  },
  data() {
    return {
      copyMessage: '',
      copyMessageError: false,
      copyMessageSuccess: false,
      link: null
    }
  },
  created() {
    this.handleSave();
  },
  methods: {
    handleSave() {
      this.$store
          .dispatch('saveConfig', this.$store.state.config.uuid)
          .then(result => {
            this.link = process.env.VUE_APP_MC_ADDRESS + '/' + this.$store.state.lang + '/wynik/' +result.data.uuid;
          }).catch(error => {
        console.log(error);
      });

      this.$emit('analyticsEvent', 'save');
    },
    copyTextToClipboard(event) {
      this.InlineButtonClickHandler(event);
      var thisComponent = this;
      if (!navigator.clipboard) {
        this.copyMessage = this.$t('browser_not_support_copy');
        this.copyMessageError = true;
        this.copyMessageSuccess = false;
        return;
      }
      navigator.clipboard.writeText(thisComponent.link).then(function() {
        thisComponent.copyMessage = thisComponent.$t('copy_success');// + ': ' + text;
        thisComponent.copyMessageError = false;
        thisComponent.copyMessageSuccess = true;
      }, function(e) {
        thisComponent.copyMessage = thisComponent.$t('copy_error') + ': ' + e.toString();
        thisComponent.copyMessageError = true;
        thisComponent.copyMessageSuccess = false;
      });
    },
    InlineButtonClickHandler(event) {
      event.section = 'Summary Save';
      this.$emit('sateliteClick', event);
    }
  }
}
</script>