<template>
  <section class="links">
    <div class="wrap">
      <div class="row jcc">
        <div class="col-12 col-lg-10">
          <div class="row aic">
            <span class="icon icon-lup_quest"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
            <h2>{{ $t('home_links_title')}}</h2>
          </div>
        </div>
        <div class="linksList col-12 col-lg-10 px-0">
          <div class="row jcc">
            <div class="linksListSingle col-12 col-sm-6 col-md-4 col-lg-3">
              <a @click="InlineButtonClickHandler" v-bind:href="$t('home_links_1')" target="_blank" class="btn btn-ghost btn-full btn-sq"><span class="btn-val">{{ $t('home_links_1_label') }}</span> <span class="hover"></span></a>
            </div>
            <div class="linksListSingle col-12 col-sm-6 col-md-4 col-lg-3">
              <a @click="InlineButtonClickHandler" v-bind:href="$t('home_links_2')" target="_blank" class="btn btn-ghost btn-full btn-sq"><span class="btn-val">{{ $t('home_links_2_label')}}</span> <span class="hover"></span></a>
            </div>
            <div class="linksListSingle col-12 col-sm-6 col-md-4 col-lg-3">
              <a @click="InlineButtonClickHandler" v-bind:href="$t('home_links_3')" target="_blank" class="btn btn-ghost btn-full btn-sq"><span class="btn-val">{{$t('home_links_3_label')}}</span> <span class="hover"></span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  methods: {
    InlineButtonClickHandler(event) {
      event.section = 'Home Links';
      this.$emit('sateliteClick', event);
    }
  }
}
</script>