<template>
<!--  <nav>-->
<!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link>-->
<!--  </nav>-->
<!--  <router-view/>-->
  <GlobalHeader />

  <main class="">
    <router-view @analyticsEvent="this.insertTag" @sateliteClick="this.handleClickEvent"/>
  </main>

  <GlobalFooter v-if="this.isFooterVisible()" @sateliteClick="this.handleClickEvent" />
  <FloodLightTag ref="floodLightTag" />
  <SateliteAgent ref="satelite" />
</template>

<style>
@import '@splidejs/vue-splide/css';
@import "assets/dist/css/vendors.min.css";
@import "assets/dist/css/main.min.css";

@font-face {
    font-family: 'Mark Offc For MC';
    src: url('assets/dist/css/fonts/MarkForMC-Bold.eot');
    src: url('assets/dist/css/fonts/MarkForMC-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Mark Offc For MC';
    src: url('assets/dist/css/fonts/MarkForMC-ExtraLt.eot');
    src: url('assets/dist/css/fonts/MarkForMC-ExtraLt.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Mark Offc For MC';
    src: url('assets/dist/css/fonts/MarkForMC-Med.eot');
    src: url('assets/dist/css/fonts/MarkForMC-Med.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Mark Offc For MC';
    src: url('assets/dist/css/fonts/MarkForMC.eot');
    src: url('assets/dist/css/fonts/MarkForMC.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Mark Offc For MC';
    src: url('assets/dist/css/fonts/MarkForMC-Lt.eot');
    src: url('assets/dist/css/fonts/MarkForMC-Lt.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'Futura';
  src: url('assets/dist/css/fonts/FuturaPTW10-Bold.eot');
  src:  url('assets/dist/css/fonts/FuturaPTW10-Bold.eot?#iefix') format('embedded-opentype'),
  url('assets/dist/css/fonts/FuturaPTW10-Bold.woff2') format('woff2'),
  url('assets/dist/css/fonts/FuturaPTW10-Bold.woff') format('woff'),
  url('assets/dist/css/fonts/FuturaPTW10-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futura';
  src: url('assets/dist/css/fonts/FuturaPTW10-Book.eot');
  src:  url('assets/dist/css/fonts/FuturaPTW10-Book.eot?#iefix') format('embedded-opentype'),
  url('assets/dist/css/fonts/FuturaPTW10-Book.woff2') format('woff2'),
  url('assets/dist/css/fonts/FuturaPTW10-Book.woff') format('woff'),
  url('assets/dist/css/fonts/FuturaPTW10-Book.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futura';
  src: url('assets/dist/css/fonts/FuturaPTW10-Medium.eot');
  src:  url('assets/dist/css/fonts/FuturaPTW10-Medium.eot?#iefix') format('embedded-opentype'),
  url('assets/dist/css/fonts/FuturaPTW10-Medium.woff2') format('woff2'),
  url('assets/dist/css/fonts/FuturaPTW10-Medium.woff') format('woff'),
  url('assets/dist/css/fonts/FuturaPTW10-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futura';
  src: url('assets/dist/css/fonts/FuturaPTW10-Light.eot');
  src:  url('assets/dist/css/fonts/FuturaPTW10-Light.eot?#iefix') format('embedded-opentype'),
  url('assets/dist/css/fonts/FuturaPTW10-Light.woff2') format('woff2'),
  url('assets/dist/css/fonts/FuturaPTW10-Light.woff') format('woff'),
  url('assets/dist/css/fonts/FuturaPTW10-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

</style>

<script>
import "./assets/dist/js/vendors.min.js";
import "./assets/dist/js/main.js";
import GlobalHeader from '@/views/partials/GlobalHeader';
import GlobalFooter from "@/views/partials/GlobalFooter";
import FloodLightTag from "@/views/analytics/FloodLightTag";
import SateliteAgent from "@/views/analytics/SateliteAgent.vue";


export default {
  name: 'App',
  watch: {
    '$store.state.lang': function() {
      this.$root.$i18n.locale = this.$store.state.lang;



      if(this.$root.$i18n.locale == 'ua') {
        document.body.classList.add('ua');
      } else {
        document.body.classList.remove('ua');
      }

      var langToPass = this.$root.$i18n.locale;
      if(langToPass == 'ua')
        langToPass = 'uk';

      document.documentElement.setAttribute("lang", langToPass);
      window.di.language = langToPass;
      if(window.OneTrust != undefined)
        window.OneTrust.changeLanguage(langToPass);
      else {
        window.otInt = setInterval(function () {
          if(window.OneTrust != undefined) {
            window.OneTrust.changeLanguage(document.documentElement.getAttribute('lang'));

            clearInterval(window.otInt);
          }
        }, 100);
      }



    }
  },
  mounted() {
    this.$root.$i18n.locale = this.$store.state.lang;

    document.documentElement.setAttribute("lang", this.$root.$i18n.locale);
    window.di.language = this.$root.$i18n.locale;
    if(this.$root.$i18n.locale == 'ua') {
      document.body.classList.add('ua');
    } else {
      document.body.classList.remove('ua');
    }


  },
  beforeCreate() {

  },
  created() {

  },
  methods: {
    handleClickEvent(event) {
      this.$refs.satelite.handleClick(event);
    },
    isFooterVisible() {
      return this.$store.state.footerVisibility;
    },
    insertTag(eventName) {

      if(eventName == 'home') {
        this.$refs.floodLightTag.home();
      }
      if(eventName == 'started') {
        this.$refs.floodLightTag.step01();
      }
      if(eventName == 'step02') {
        this.$refs.floodLightTag.step02();
      }
      if(eventName == 'step03') {
        this.$refs.floodLightTag.step03();
      }
      if(eventName == 'step04') {
        this.$refs.floodLightTag.step04();
      }
      if(eventName == 'step05') {
        this.$refs.floodLightTag.step05();
      }
      if(eventName == 'summary') {
        this.$refs.floodLightTag.summary();
      }
      if(eventName == 'save') {
        this.$refs.floodLightTag.save();
      }
      if(eventName == 'check') {
        this.$refs.floodLightTag.check();
      }
      if(eventName == 'banks') {
        this.$refs.floodLightTag.banks();
      }
    }
  },
  components: {
    GlobalHeader, GlobalFooter, FloodLightTag, SateliteAgent
  }
}
</script>
