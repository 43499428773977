var isMobile = navigator.userAgent.match(/Mobile/i) != null;
var ww = window.innerWidth;
var wh = window.innerHeight;
var body = document.querySelector('body');

console.log('is mobile', isMobile);
window.addEventListener('load', function(){
    if ( !body.classList.contains('loaded') ) {
        body.classList.add('loaded');
    }
})

// p = document.querySelector(".animEffect"),
//     offset = 245,
//     newoffset = 0;
// p.removeAttribute("style");
// var offsetMe = function() {
//     newoffset = 245 - 2.45 * document.querySelector(".svg").getAttribute("data-percent"), offset >= newoffset && (p.style.strokeDashoffset = offset, offset -= 5, requestAnimationFrame(offsetMe))
// };

var scroll = window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.msRequestAnimationFrame ||
            window.oRequestAnimationFrame ||
            function(callback){ window.setTimeout(callback, 1000/60) };

var lastPosition = -1;
var resizeWin = false;

function lpAnim() {
    var sF = document.querySelector(".summaryFixed");
    var sC = document.querySelector(".summaryCostSum");
    var main = document.querySelector(".main");

    var fP = document.querySelector('.fixedPanel');

    // var sFBound;
    var sCBound;
    if (sC) {
        sCBound = sC.getBoundingClientRect();
    }

    // console.log( 'lastPosition',lastPosition, 'window.pageYOffset',window.pageYOffset );
    if (lastPosition == window.pageYOffset && !resizeWin) {
        scroll(lpAnim);
        return false;
    } else {
        lastPosition = window.pageYOffset;

        if (sF) {
            if ( (sCBound.top - wh + 56) <= 0 ) {
                // console.log( 'lastPosition', lastPosition, 'window.pageYOffset', window.pageYOffset, sCBound.top );
                if ( !sF.classList.contains('hide') ) {
                    sF.classList.add('hide');
                }
            } else {
                sF.classList.remove('hide');
            }
        }

        if (fP) {
            if (main) {
                if (lastPosition >= (main.parentElement.offsetTop + main.offsetHeight) && lastPosition > 0) {
                    if (!fP.classList.contains('fixed')) {
                        fP.classList.add('fixed');
                    }
                } else {
                    fP.classList.remove('fixed');
                }
            } else {
                if (lastPosition >= (wh/2)) {
                    if (!fP.classList.contains('fixed')) {
                        fP.classList.add('fixed');
                    }
                } else {
                    fP.classList.remove('fixed');
                }
            }
            // fixedPanel.style.transform = "translateY(" + lastPosition + "px)";
        }
    }
}

if (document.querySelector(".earningsBars") || document.querySelector(".fixedPanel") || document.querySelector('.summaryFixed')) {
    window.addEventListener('load', function(){
        setTimeout(function(){
            lpAnim();
            console.log('load');
        },300);
    });
    window.addEventListener('resize', function(){
        setTimeout(function(){
            resizeWin = true;
            lpAnim();
        },300);
    });
    window.addEventListener('scroll', function(){
        lpAnim();
    });
    lpAnim();
}

if (document.querySelector("[data-oki]")) {
    document.querySelectorAll("[data-oki]").forEach(function(elem){
        elem.addEventListener("click", function(event1) {
            event1.stopPropagation();
            if ( !this.tagName == 'input' || !this.tagName == 'INPUT' || this.tagName == 'a' || this.tagName == 'A') {
                event1.preventDefault();
                // console.log(t.tagName);
            }
            var t = this;
            var target = document.querySelector(t.getAttribute('data-oki'));
            var body = document.querySelector("body");

            if (target) {
                if ( !!target.getAttribute('data-parent') && t.getAttribute('aria-expanded') != 'true' ) {
                    var par = document.querySelector( target.getAttribute('data-parent') );

                    par.querySelectorAll('.okiCollapse').forEach(function(parElem){
                        parElem.classList.remove('open');
                        document.querySelector('[data-oki="#'+ parElem.id +'"]').setAttribute('aria-expanded','false');
                    });
                }
            }


            if ( t.getAttribute('aria-expanded') == "false" ) {
                if ( !target.classList.contains('open') ) {
                    if (!!t.closest('header') || !!t.closest('footer')) {
                        body.classList.add('okiCollapseOpen');
                    }
                    target.classList.add('open');
                    t.setAttribute('aria-expanded','true');
                }
            } else {
                if (!!t.closest('header') || !!t.closest('footer')) {
                    body.classList.remove('okiCollapseOpen');
                }
                target.classList.remove('open');
                t.setAttribute('aria-expanded','false');
            }

            return false;
        });
    });

    document.addEventListener("click", function() {
        var head = document.querySelector("header"),
        foot = document.querySelector("footer");
        if (document.querySelector("body").classList.contains('okiCollapseOpen')) {
            document.querySelector("body").classList.remove('okiCollapseOpen');
        }
        head.querySelectorAll("[data-oki]").forEach(function(el1){
            el1.setAttribute('aria-expanded','false');
        });
        foot.querySelectorAll("[data-oki]").forEach(function(el1){
            el1.setAttribute('aria-expanded','false');
        });
        head.querySelectorAll(".okiCollapse").forEach(function(el2){
            el2.addEventListener("click", function(el) {
                el.stopPropagation();
                el.preventDefault();
            });
            el2.classList.remove('open');
        });
        foot.querySelectorAll(".okiCollapse").forEach(function(el2){
            el2.addEventListener("click", function(el) {
                el.stopPropagation();
                el.preventDefault();
            });
            el2.classList.remove('open');
        });
    });
}

function setRegionRibbon() {
    var reg = document.querySelector(".region.active");
    var rib = document.querySelector('.stepsMap .ribbon');
    var map = document.querySelector('.stepsMap svg');

    var mapBound;
    var regBound;
    var regPos;
    if (reg) {
        regBound = reg.getBoundingClientRect();
    }
    if (map) {
        mapBound = map.getBoundingClientRect();
    }
    if ( map && reg) {
        regPos = {
            "top": parseInt(regBound.y - mapBound.y + (regBound.height / 2)),
            "left": parseInt(regBound.x - mapBound.x + (regBound.width / 2))
        }
        // console.log('reg active', reg.getAttribute('data-name'), rib);
        // console.log('reg active', reg.getAttribute('data-name'), regPos, rib);
        // console.log('mapBound',mapBound);
        // console.log('regBound',regBound);

        rib.querySelector('.val').innerHTML = reg.getAttribute('data-name');
        rib.style.top = regPos.top + 'px';
        rib.style.left = regPos.left + 'px';
    }

}

if (document.querySelector(".stepsMap")) {
    document.querySelectorAll(".region").forEach(function(elem){
        elem.addEventListener("click", function() {
            var t = this;

            if (document.querySelector(".region.active")) {
                document.querySelector(".region.active").classList.remove('active');
            }
            t.classList.add('active');

            setRegionRibbon();
        });
    });



    setTimeout(function(){
        setRegionRibbon();
    },300);

    window.addEventListener('resize', function(){
        setTimeout(function(){
            setRegionRibbon();
        },300);
    });
}

if ( document.querySelector(".tooltip") && ww <= 1024 ) {
    // console.log('ww', ww);
    document.querySelectorAll(".tooltip_cont").forEach(function(el){
        el.addEventListener('click', function(ev){
            ev.stopPropagation();
            ev.preventDefault();
        });
    });
    // document.querySelectorAll(".tooltip").forEach(function(el){
    //     el.addEventListener('click', function(ev){
    //         ev.stopPropagation();
    //         ev.preventDefault();
    //         // console.log('tooltip', this.getAttribute('title'));
    //
    //         if (!this.classList.contains('show')) {
    //             this.classList.add('show');
    //             if (!document.querySelector("body").classList.contains('tootlipOpen')) {
    //                 document.querySelector("body").classList.add('tootlipOpen');
    //             }
    //         } else {
    //             this.classList.remove('show');
    //             document.querySelector("body").classList.remove('tootlipOpen');
    //         }
    //
    //         return false;
    //     });
    //
    //     el.querySelector(".close").addEventListener('click', function(){
    //         // console.log('close', this.closest('.tooltip'), this.closest('.tooltip').classList.contains('show'));
    //         if (this.closest('.tooltip').classList.contains('show')) {
    //             this.closest('.tooltip').classList.remove('show');
    //             document.querySelector("body").classList.remove('tootlipOpen');
    //         }
    //
    //         return false;
    //     });
    // });


    document.addEventListener("click", function() {
        var tooltip = document.querySelectorAll(".tooltip.show");

        if (document.querySelector("body").classList.contains('tootlipOpen')) {
            document.querySelector("body").classList.remove('tootlipOpen');
        }
        tooltip.forEach(function(el2){
            el2.classList.remove('show');
        });
    });
}
