<template>
  <section class="infoData">
    <div class="wrap">
      <div class="row jcc">
        <div class="infoDataCont col-12 col-xl-10 px-0">
          <div class="row aic">
            <div class="col-12 col-lg-6">
              <h2>{{$t('page_about_data_title')}}</h2>
            </div>
            <div class="col-12 col-lg-6">
              <p>{{$t('page_about_data_desc')}}</p>
            </div>
          </div>
        </div>
        <div class="infoDataList col-12 col-xl-10 px-0">
          <div class="row">
            <div class="infoDataListSingle col-12 col-lg-4">
              <div class="img"><img src="@/assets/dist/img/infoDataIcon01.svg" alt="infoDataIcon01"></div>
              <div class="cont">
                <h3 class="h4">{{$t('page_about_data_title1')}}</h3>
                <p>{{$t('page_about_data_mastercard')}}</p>
              </div>
            </div>
            <div class="infoDataListSingle col-12 col-lg-4">
              <div class="img"><img src="@/assets/dist/img/infoDataIcon02.svg" alt="infoDataIcon02"></div>
              <div class="cont">
                <h3 class="h4">{{$t('page_about_data_title2')}}</h3>
                <p>{{$t('page_about_data_gus')}}</p>
              </div>
            </div>
            <div class="infoDataListSingle col-12 col-lg-4">
              <div class="img"><img src="@/assets/dist/img/infoDataIcon03.svg" alt="infoDataIcon03"></div>
              <div class="cont">
                <h3 class="h4">{{$t('page_about_data_title3')}}</h3>
                <p>{{$t('page_about_data_morizon')}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {

}
</script>