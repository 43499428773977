<template>
  <div class="summaryCost col-12 col-lg-5 offset-xlg-1" id="scs">
    <form>
      <div class="row">



        <div class="summaryCostSingle col-12">
          <input v-model="this.rent_1" type="checkbox" name="cost01" id="cost01" value="cost01">
          <p class="row aic nowrap">
            <label for="cost01" class="summaryIcon icon icon-house cost01"></label>
            <span class="name row aic nowrap">
                    <label for="cost01" >{{ $t('step_summary_rent_1') }}</label>
                    <a href="#" class="tooltip" id="tooltip_1" @click.prevent="handleTooltip" data-toggle="tooltip" data-placement="top" v-bind:title="$t('step_summary_rent_1_tooltip')"><span class="icon icon-question"></span> <span class="tooltip_cont"><span class="close mobile"><span class="icon icon-x2"></span></span>{{$t('step_summary_rent_1_tooltip')}}</span></a>
                </span>
            <label for="cost01" class="price">{{ numberWithSpaces(this.costs.rent_1)}}&nbsp;{{ $t('currency_' + this.$store.state.currency)}}</label>
            <label for="cost01" class="check"></label>
          </p>
        </div>

        <div class="summaryCostSingle col-12">
          <input v-model="this.rent_2" type="checkbox" name="cost02" id="cost02" value="cost02">
          <p class="row aic nowrap">
            <label for="cost02" class="summaryIcon icon icon-house cost01"></label>
            <span class="name row aic nowrap">
                    <label for="cost02" >{{ $t('step_summary_rent_2') }}</label>
                    <a href="#" class="tooltip" id="tooltip_2" @click.prevent="handleTooltip" data-toggle="tooltip" data-placement="top" v-bind:title="$t('step_summary_rent_2_tooltip')"><span class="icon icon-question"></span> <span class="tooltip_cont"><span class="close mobile"><span class="icon icon-x2"></span></span>{{$t('step_summary_rent_2_tooltip')}}</span></a>
                </span>
            <label for="cost02" class="price">{{ numberWithSpaces(this.costs.rent_2)}}&nbsp;{{ $t('currency_' + this.$store.state.currency)}}</label>
            <label for="cost02" class="check"></label>
          </p>
        </div>

        <div class="summaryCostSingle col-12">
          <input v-model="this.rent_3" type="checkbox" name="cost03" id="cost03" value="cost03">
          <p class="row aic nowrap">
            <label for="cost03" class="summaryIcon icon icon-house cost01"></label>
            <span class="name row aic nowrap">
                    <label for="cost03" >{{ $t('step_summary_rent_3') }}</label>
                    <a href="#" class="tooltip" id="tooltip_3" @click.prevent="handleTooltip" data-toggle="tooltip" data-placement="top" v-bind:title="$t('step_summary_rent_3_tooltip')"><span class="icon icon-question"></span> <span class="tooltip_cont"><span class="close mobile"><span class="icon icon-x2"></span></span>{{$t('step_summary_rent_3_tooltip')}}</span></a>
                </span>
            <label for="cost03" class="price">{{ numberWithSpaces(this.costs.rent_3)}}&nbsp;{{ $t('currency_' + this.$store.state.currency)}}</label>
            <label for="cost03" class="check"></label>
          </p>
        </div>
        <div class="summaryCostSingle col-12">
          <input v-model="this.rent_4" type="checkbox" name="cost09" id="cost09" value="cost09">
          <p class="row aic nowrap">
            <label for="cost09" class="summaryIcon icon icon-house cost01"></label>
            <span class="name row aic nowrap">
                    <label for="cost03" >{{ $t('step_summary_rent_4') }}</label>
                    <a href="#" class="tooltip" id="tooltip_4" @click.prevent="handleTooltip" data-toggle="tooltip" data-placement="top" v-bind:title="$t('step_summary_rent_4_tooltip')"><span class="icon icon-question"></span> <span class="tooltip_cont"><span class="close mobile"><span class="icon icon-x2"></span></span>{{$t('step_summary_rent_4_tooltip')}}</span></a>
                </span>
            <label for="cost09" class="price">{{ numberWithSpaces(this.costs.rent_4)}}&nbsp;{{ $t('currency_' + this.$store.state.currency)}}</label>
            <label for="cost09" class="check"></label>
          </p>
        </div>

        <div class="summaryCostSingle col-12">
          <input v-model="this.supermarket" type="checkbox" name="cost04" id="cost04" value="cost04">
          <p class="row aic nowrap">
            <label for="cost04" class="summaryIcon icon icon-bag cost02"></label>
            <span class="name row aic nowrap">
                    <label for="cost04" >{{$t('step_summary_supermarket')}}</label>
                    <a href="#" class="tooltip" id="tooltip_5" @click.prevent="handleTooltip" data-toggle="tooltip" data-placement="top" v-bind:title="$t('step_summary_supermarket_tooltip')"><span class="icon icon-question"></span> <span class="tooltip_cont"><span class="close mobile"><span class="icon icon-x2"></span></span>{{$t('step_summary_supermarket_tooltip')}}</span></a>
                </span>
            <label for="cost04" class="price">{{ numberWithSpaces(this.costs.supermarket)}}&nbsp;{{ $t('currency_' + this.$store.state.currency)}}</label>
            <label for="cost04" class="check"></label>
          </p>
        </div>

        <div v-if="this.shouldBeShown('fuel')" class="summaryCostSingle col-12">
          <input v-model="this.fuel" type="checkbox" name="cost05" id="cost05" value="cost05">
          <p class="row aic nowrap">
            <label for="cost05" class="summaryIcon icon icon-gas cost03"></label>
            <span class="name row aic nowrap">
                    <label for="cost05" >{{$t('step_summary_fuel')}}</label>
                    <a href="#" class="tooltip" id="tooltip_6" @click.prevent="handleTooltip" data-toggle="tooltip" data-placement="top" v-bind:title="$t('step_summary_fuel_tooltip')"><span class="icon icon-question"></span> <span class="tooltip_cont"><span class="close mobile"><span class="icon icon-x2"></span></span>{{$t('step_summary_fuel_tooltip')}}</span></a>
                </span>
            <label for="cost05" class="price">{{ numberWithSpaces(this.costs.fuel)}}&nbsp;{{ $t('currency_' + this.$store.state.currency)}}</label>
            <label for="cost05" class="check"></label>
          </p>
        </div>

        <div v-if="this.shouldBeShown('publicTransportation')" class="summaryCostSingle col-12">
          <input v-model="this.publicTransportation" type="checkbox" name="cost06" id="cost06" value="cost06">
          <p class="row aic nowrap">
            <label for="cost06" class="summaryIcon icon icon-train cost04"></label>
            <span class="name row aic nowrap">
                    <label for="cost06" >{{$t('step_summary_public_transportation')}}</label>
                    <a href="#" class="tooltip" id="tooltip_7" @click.prevent="handleTooltip" data-toggle="tooltip" data-placement="top" v-bind:title="$t('step_summary_public_transportation_tooltip', {'city': this.getCurrentCity(), 'cost': this.getSingleTicketCost().toFixed(2), 'currency': $t('currency_' + this.$store.state.currency)})"><span class="icon icon-question"></span> <span class="tooltip_cont"><span class="close mobile"><span class="icon icon-x2"></span></span>{{$t('step_summary_public_transportation_tooltip', {'city': this.getCurrentCity(), 'cost': this.getSingleTicketCost().toFixed(2), 'currency': $t('currency_' + this.$store.state.currency)})}}</span></a>
                </span>
            <label for="cost06" class="price">{{ numberWithSpaces(this.costs.publicTransportation)}}&nbsp;{{ $t('currency_' + this.$store.state.currency)}}</label>
            <label for="cost06" class="check"></label>
          </p>
        </div>

        <div class="summaryCostSingle col-12">
          <input v-model="this.restaurants" type="checkbox" name="cost07" id="cost07" value="cost07">
          <p class="row aic nowrap">
            <label for="cost07" class="summaryIcon icon icon-knife cost05"></label>
            <span class="name row aic nowrap">
                    <label for="cost07" >{{$t('step_summary_restaurants')}}</label>
                    <a href="#" class="tooltip" id="tooltip_8" @click.prevent="handleTooltip" data-toggle="tooltip" data-placement="top" v-bind:title="$t('step_summary_restaurants_tooltip')"><span class="icon icon-question"></span> <span class="tooltip_cont"><span class="close mobile"><span class="icon icon-x2"></span></span>{{$t('step_summary_restaurants_tooltip')}}</span></a>
                </span>
            <label for="cost07" class="price">{{ numberWithSpaces(this.costs.restaurants)}}&nbsp;{{ $t('currency_' + this.$store.state.currency)}}</label>
            <label for="cost07" class="check"></label>
          </p>
        </div>

        <div v-if="this.shouldBeShown('pets')" class="summaryCostSingle col-12">
          <input v-model="this.pets" type="checkbox" name="cost08" id="cost08" value="cost08">
          <p class="row aic nowrap">
            <label for="cost08" class="summaryIcon icon icon-dog cost06"></label>
            <span class="name row aic nowrap">
                    <label for="cost08" >{{$t('step_summary_pets')}}</label>
                    <a href="#" class="tooltip" id="tooltip_9" @click.prevent="handleTooltip" data-toggle="tooltip" data-placement="top" v-bind:title="$t('step_summary_pets_tooltip')"><span class="icon icon-question"></span> <span class="tooltip_cont"><span class="close mobile"><span class="icon icon-x2"></span></span>{{$t('step_summary_pets_tooltip')}}</span></a>
                </span>
            <label for="cost08" class="price">{{ numberWithSpaces(this.costs.pets)}}&nbsp;{{ $t('currency_' + this.$store.state.currency)}}</label>
            <label for="cost08" class="check"></label>
          </p>
        </div>



        <div class="summaryCostSum col-12">
          <p class="w-100">{{$t('step_summary_total_annualy')}} <span class="price">{{numberWithSpaces(this.costs.total)}}&nbsp;{{ $t('currency_' + this.$store.state.currency)}}</span></p>
        </div>
        <div class="summaryCostAvr col-12">
          <p class="w-100">{{$t('step_summary_avg_salary')}} <span class="price">{{numberWithSpaces(this.getAvgSalary())}}&nbsp;{{ $t('currency_' + this.$store.state.currency)}}</span></p>
        </div>
      </div>
    </form>

    <div class="row">
      <div class="earningsPower mobile col-12">
          <p>{{$t('page_about_powered')}}</p>
          <div class="row">
            <div class="earningsPowerSingle col-auto">
              <img src="@/assets/dist/img/powerLogo01.svg" alt="powerLogo01">
            </div>
          </div>
        </div>
        <div class="earningsPower mobile col-12">
          <p>{{$t('page_about_partnership')}}</p>
          <div class="row">
            <div class="earningsPowerSingle col-auto">
              <img src="@/assets/dist/img/powerLogo02.svg" alt="powerLogo02">
            </div>
            <div class="earningsPowerSingle col-auto">
              <img src="@/assets/dist/img/powerLogo03.svg" alt="powerLogo03">
            </div>
          </div>
        </div>
    </div>

    <div class="summaryFixed mobile" v-bind:class="{hide: summaryCostSumPos <= 0}">
      <p class="w-100">{{$t('step_summary_total_annualy')}} <span class="price">{{this.costs.total}}&nbsp;{{ $t('currency_' + this.$store.state.currency)}}</span></p>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        summaryCostSumPos: null,
        wh: window.innerHeight,

        rent_1: false,
        rent_2: false,
        rent_3: false,
        rent_4: false,
        supermarket: true,
        fuel: false,
        publicTransportation: false,
        restaurants: true,
        pets: false,
        costs: {
          rent_1: 0,
          rent_2: 0,
          rent_3: 0,
          rent_4: 0,
          supermarket: 0,
          fuel: 0,
          publicTransportation: 0,
          restaurants: 0,
          pets: 0,
          total: 0
        },
        remoteData: null,
        ww: window.innerWidth
      }
    },
    watch: {
      remoteData() {
        this.recalculate();
      },
      '$store.state.currency': function() {
        this.recalculate();
      },
      '$store.state.config.city': function() {
        this.getCosts();
      },
      rent_1() {
        if(this.rent_1) {
          this.rent_2 = false; this.rent_3 = false; this.rent_4 = false;
        }
        this.recalculate();
      },
      rent_2() {
        if(this.rent_2) {
          this.rent_1 = false; this.rent_3 = false; this.rent_4 = false;
        }
        this.recalculate();
      },
      rent_3() {
        if(this.rent_3) {
          this.rent_1 = false; this.rent_2 = false; this.rent_4 = false;
        }
        this.recalculate();
      },
      rent_4() {
        if(this.rent_4) {
          this.rent_1 = false; this.rent_2 = false; this.rent_3 = false;
        }
        this.recalculate();
      },
      supermarket() {
        this.recalculate();
      },
      fuel() {
        this.recalculate();
      },
      publicTransportation() {
        this.recalculate();
      },
      restaurants() {
        this.recalculate();
      },
      pets() {
        this.recalculate();
      },
    },
    mounted() {
      //var people = this.$store.state.config.adults + this.$store.state.config.kids;

      this.rent_1 = false;
      this.rent_2 = false;
      this.rent_3 = false;
      this.rent_4 = false;
      if(this.$store.state.config.estate == 1)
        this.rent_1 = true;
      if(this.$store.state.config.estate == 2)
        this.rent_2 = true;
      if(this.$store.state.config.estate == 3)
        this.rent_3 = true;
      if(this.$store.state.config.estate == 4)
        this.rent_4 = true;

      if(this.$store.state.config.ownVehicle) {
        this.fuel = true;
      }
      if(this.$store.state.config.publicTransportation) {
        this.publicTransportation = true;
      }
      if(this.$store.state.config.pets > 0)
        this.pets = true;

      this.getCosts();

    },
    beforeUnmount () {

      //window.removeEventListener('scroll', this.updateScroll2);
    },
    created() {
      var thisComponent = this;
      window.addEventListener("scroll", function() {
        if (thisComponent.ww < 1024) {
            if (window.document.getElementById('summary-section')) {
                thisComponent.summaryCostSumPos = parseInt(window.document.getElementById('summary-section').getBoundingClientRect().top + window.document.getElementById('summary-section').getBoundingClientRect().height - thisComponent.wh);
            } else {
                thisComponent.summaryCostSumPos = 0
            }
        }
        // console.log('summaryCostSumPos', thisComponent.summaryCostSumPos, thisComponent.ww);
      });
    },
    methods: {
      getAvgSalary() {
        if(this.remoteData != null) {
          return this.remoteData[this.$store.state.currency]['qb_sal'+this.$store.state.config.industry];

        }

        return 0;
        //return this.remoteData[this.$store.state.currency]['qb_sql'+this.$store.state.config.industry];
      },
      getTotalCosts() {
          return this.costs.total;
      },
      shouldBeShown(field) {
        if(field == 'pets') {
          if(this.$store.state.config.pets > 0)
            return true;
        }
        if(field == 'fuel') {
          if(this.$store.state.config.ownVehicle)
            return true;
        }
        if(field == 'publicTransportation') {
          if(this.$store.state.config.publicTransportation)
            return true;
        }
        return false;
      },
      getCosts() {
        this.$store
            .dispatch('fetchCosts')
            .then(costs => {
              this.remoteData = costs
              this.recalculate();

            }).catch(error => {
          console.log(error);
        });
      },
      recalculate() {
        //var people = this.$store.state.config.adults + this.$store.state.config.kids;
        var peopleWeighted = this.$store.state.config.adults + (this.$store.state.config.kids * 1.05);
        var peopleWeightedTransport = this.$store.state.config.adults + (this.$store.state.config.kids * 0.5);


        if(this.remoteData == null )
          return;
        this.costs.rent_1 = Math.round(this.remoteData[this.$store.state.currency].qb_rent32m);
        this.costs.rent_2 = Math.round(this.remoteData[this.$store.state.currency].qb_rent45m);
        this.costs.rent_3 = Math.round(this.remoteData[this.$store.state.currency].qb_rent60m);
        this.costs.rent_4 = Math.round(this.remoteData[this.$store.state.currency].qb_rent110m);

        //supermarket

        this.costs.supermarket = Math.round(this.remoteData[this.$store.state.currency].qb_spdFoodChem * peopleWeighted);

        if(this.fuel) {
          this.costs.fuel = Math.round(this.remoteData[this.$store.state.currency].qb_spdFuelAcv);
        }

        if(this.publicTransportation) {
          this.costs.publicTransportation = Math.round(this.remoteData[this.$store.state.currency].qb_monthlyTicket * peopleWeightedTransport);
        }

        this.costs.restaurants = Math.round(this.remoteData[this.$store.state.currency].qb_spdRestaurantsTicket * peopleWeighted);

        this.costs.pets = Math.round(this.remoteData[this.$store.state.currency].qb_spdAnimalsAcv * this.$store.state.config.pets);

        this.costs.total = (
            (this.rent_1 ? this.costs.rent_1 : 0) +
            (this.rent_2 ? this.costs.rent_2 : 0) +
            (this.rent_3 ? this.costs.rent_3 : 0) +
            (this.rent_4 ? this.costs.rent_4 : 0) +
            (this.fuel ? this.costs.fuel : 0) +
            (this.publicTransportation ? this.costs.publicTransportation : 0) +
            (this.supermarket ? this.costs.supermarket : 0) +
            (this.restaurants ? this.costs.restaurants : 0)+
            (this.pets ? this.costs.pets : 0)
        );


        this.$emit('recalculate');


      },
      numberWithSpaces(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      getSingleTicketCost() {
        if(this.remoteData != null) {
          return this.remoteData[this.$store.state.currency].qb_singleTicket;
        }

        return 0;
      },
      getCurrentCity() {
        if(this.$store.state.lang == 'pl')
          return this.$store.state.config.city.city_pol;
        if(this.$store.state.lang == 'en')
          return this.$store.state.config.city.city_eng;
        if(this.$store.state.lang == 'ua')
          return this.$store.state.config.city.city_uah;
      },

      handleTooltip(e) {
          // console.log('handleTooltip e', e.currentTarget, document.querySelector('body'));
          if (this.ww < 1024) {


              if (!e.currentTarget.classList.contains('show')) {
                  e.currentTarget.classList.add('show');
                  if (!document.querySelector("body").classList.contains('tootlipOpen')) {
                      document.querySelector("body").classList.add('tootlipOpen');
                  }
              } else {
                  e.currentTarget.classList.remove('show');
                  document.querySelector("body").classList.remove('tootlipOpen');
              }

              var tooltipsElems = document.getElementsByClassName('tooltip');

              for(var index in tooltipsElems) {

                if(typeof tooltipsElems[index] == "object") {
                  if(e.currentTarget.getAttribute('id') != tooltipsElems[index].getAttribute('id')) {
                    tooltipsElems[index].classList.remove('show');
                  }
                }
              }
          }
      }
    }

  }

</script>
