<template>
  <section class="banks">
    <div class="wrap">
      <div class="row">
        <div class="col-12 col-lg-4 offset-lg-1">
          <h2>{{$t('banks_title')}}</h2>
          <p>{{$t('banks_caption')}}</p>
          <div class="form">
            <div class="row">
              <div class="col-12 px-0">

                <vue-google-autocomplete id="map" classname="form-control" types="(cities)" :country="['pl']" v-bind:placeholder="$t('banks_type_city')" v-on:placechanged="getAddressData">
                </vue-google-autocomplete>
                <a v-if="this.isGeolocationAvailable()" class="getLoc" @click="this.findMyLocation"><span class="icon icon-loc"></span></a>

              </div>
              <div v-if="this.geolocationError" class="geolocation-error col-12 px-0">{{$t('banks_geolocation_error')}}</div>
            </div>
          </div>
        </div>

        <BanksList :coords="this.coords" ref="banksList"/>

      </div>
    </div>
  </section>
</template>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import BanksList from "@/views/widgets/BanksList";

export default {
  data() {
    return {
      coords: {
        'latitude': 52.21049547359925,//waw
        'longitude': 21.022516527310678
      },
      geolocationError: false
    }
  },
  components: {
    VueGoogleAutocomplete,
    BanksList
  },
  mounted() {
    this.$store
        .dispatch('changeFooterVisibility', {
          'visibility': true
        })
    ;
    this.findMyLocation();
  },
  methods: {
    getAddressData(data) {
      this.coords = {
        'latitude': data.latitude,
        'longitude': data.longitude
      };
    },
    findMyLocation() {
      var thisComponent = this;
      window.navigator.geolocation.getCurrentPosition(function(position) {

        thisComponent.coords = {
          'latitude': position.coords.latitude,
          'longitude': position.coords.longitude
        }

      }, function() {
        thisComponent.geolocationError = true;
      });
    },
    isGeolocationAvailable() {
      return window.navigator.geolocation;
    }

  }
}
</script>