<template>
  <section class="banks">
    <div class="wrap visible">
      <div class="row">
        <div class="col-12 col-lg-4 offset-lg-1">
          <h2>{{$t('schools_title')}}</h2>
          <div v-html="$t('schools_content')"></div>
        </div>

        <div class="banksList col-12 col-lg-5 offset-lg-1">
          <div class="row">


            <div v-for="item in items" v-bind:key="item" class="banksListSingle col-12">
              <div class="row aic">
                <div class="col pr-5">
                  <p><strong>{{item.name}}</strong></p>
                  <p class="fw-l">{{item.address}}<br><a v-bind:href="'http://'+item.www" target="_blank" rel="nofollow">{{item.www}}</a></p>
                </div>
                <div class="onMap col-auto">
                  <a v-bind:href="this.getGmapsLink(item)" target="_blank" rel="nofollow">
                    <span class="icon icon-pin"></span>
                    <span class="val"><br />{{$t('see_on_map')}}</span>
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>



        <div class="banksBg col-12 col-lg-4 offset-lg-1 ta-c">
          <img src="@/assets/dist/img/whoImg01Big.svg" alt="whoImg01Big">
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      items: []
    }
  },
  watch: {
    '$store.state.lang': function() {
      this.generateItems();
    }
  },
  mounted() {

  },
  created() {

    if(this.items.length == 0) {
      this.generateItems();
    }
  },
  methods: {
    getGmapsLink(item) {

      if(item.gmaps != '')
        return item.gmaps;
      else
        return 'http://maps.google.com/maps?q='+item.address;
    },
    extractItemIndex(key) {

      var rx = /school_list_(.*)_/g;
      var arr = rx.exec(key);
      return arr[1];
    },
    getItems() {
      return this.items;
    },
    generateItems() {
      var elems = [];
      for(var index in this.$root.$i18n.messages[this.$root.$i18n.locale]) {

        if(index.indexOf('school_list_') !== -1) {

          var key = this.extractItemIndex(index);
          if(key != null) {

            var found = false;
            for(var i in elems) {
              if(elems[i].index == key) {
                found = true;
                break;
              }
            }
            if(found == false) {
              elems.push({
                'index': key
              });

            }

            if(index.indexOf('_name') !== -1) {
              for(i in elems) {
                if(elems[i].index == key) {
                  elems[i]['name'] = this.$t(index);
                }
              }
            } else if(index.indexOf('_address') !== -1) {
              for(i in elems) {
                if(elems[i].index == key) {
                  elems[i]['address'] = this.$t(index);
                }
              }
            } else if(index.indexOf('_www') !== -1) {
              for(i in elems) {
                if(elems[i].index == key) {
                  elems[i]['www'] = this.$t(index);
                }
              }
            } else if(index.indexOf('_gmaps') !== -1) {
              for(i in elems) {
                if(elems[i].index == key) {
                  elems[i]['gmaps'] = this.$t(index);
                }
              }
            }

          }

        }
      }
      this.items = elems;
    }
  }
}
</script>