import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFoundView from "@/views/NotFoundView";
import PageView from "@/views/PageView";
import BanksView from "@/views/BanksView";
import Step01View from "@/views/steps/Step01View";
import Step02View from "@/views/steps/Step02View";
import Step03View from "@/views/steps/Step03View";
import Step04View from "@/views/steps/Step04View";
import Step05View from "@/views/steps/Step05View";
import SummaryView from "@/views/steps/SummaryView";
import ConfigView from "@/views/ConfigView"
import AboutView from "@/views/AboutView.vue";
import SchoolsView from "@/views/SchoolsView.vue";
import {defaultLocale} from "../i18n/index"
import store from '../store'
import i18n from '../i18n/index'
import WhereToSettleView from "@/views/WhereToSettleView.vue";
import CostOfLivingView from "@/views/CostOfLivingView.vue";
//import i18n from "../i18n/index";

const { t } = i18n.global;

const routes = [
  {
    path: "/",
    redirect: `/${defaultLocale}`
  },
  {
    path: "/:locale(pl|en|ua)?",
    component: HomeView,
    name: 'home',
  },
  {
    path: '/:locale/wybor-branzy',
    name: 'step01',
    component: Step01View
  },
  // {
  //   path: '/:locale/wybor-miejscowosci',
  //   name: 'step02',
  //   component: Step02View
  // },
  {
    path: '/:locale/liczba-mieszkancow',
    name: 'step02',
    component: Step02View
  },
  {
    path: '/:locale/rodzaj-nieruchomosci',
    name: 'step03',
    component: Step03View
  },
  {
    path: '/:locale/rodzaj-transportu',
    name: 'step04',
    component: Step04View
  },
  {
    path: '/:locale/wybor-miejscowosci',
    name: 'step05',
    component: Step05View
  },
  {
    path: '/:locale/podsumowanie',
    name: 'summary',
    component: SummaryView
  },
  {
    path: '/:locale/wynik/:uuid',
    name: 'config',
    component: ConfigView
  },
  {
    path: '/:locale/placowki-bankowe',
    name: 'banks',
    component: BanksView
  },
  {
    path: '/:locale/regulamin',
    name: 'terms',
    component: PageView,
    props: {
      pageTitle: 'page_terms_title',
      pageContent: 'page_terms_content',
      pageStartsFrom: 'page_terms_starts',
    }
  },
  {
    path: '/:locale/polityka-prywatnosci',
    name: 'policy',
    component: PageView,
    props: {
      pageTitle: 'page_policy_title',
      pageContent: 'page_policy_content',
      pageStartsFrom: '',
    }
  },
  {
    path: '/:locale/o-nas',
    name: 'about',
    component: AboutView,
    props: {
      pageTitle: 'page_about_title',
      pageContent: 'page_about_content',
      pageStartsFrom: '',
    }
  },
  {
    path: '/:locale/placowki-edukacyjne',
    name: 'schools',
    component: SchoolsView,
    props: {
      pageTitle: 'page_shools_title',
      pageContent: 'page_shools_content',
      pageStartsFrom: '',
    }
  },
  {
    path: '/:locale/where-to-settle',
    name: 'wheretosettle',
    component: WhereToSettleView,
    props: {
      pageTitle: 'page_wheretosettle_title',
    },

  },
  {
    path: '/:locale/cost-of-living',
    name: 'costofliving',
    component: CostOfLivingView,
    props: {
      pageTitle: 'page_costofliving_title',
    },

  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFoundView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to) => {


  if(['pl','en','ua'].indexOf(to.params.locale) === -1 && to.name != 'NotFound') {
    router.push({name: 'home', params: {'locale': to.params.locale}});

  }

  if(['wheretosettle', 'costofliving'].indexOf(to.name) !== -1 && to.params.locale != 'en') {
    router.push({name: 'home', params: {'locale': to.params.locale}});
  }

  //changing app locale based on url param

  store.dispatch('changeLang', to.params.locale);


  if(['step02', 'step03', 'step04', 'step05', 'summary'].indexOf(to.name) != -1) {
    if(store.state.config.industry == null) {
      router.push({name: 'step01', params: {'locale': to.params.locale}});
    }
  } else if(['step03', 'step04', 'step05','summary'].indexOf(to.name) != -1) {
    if(store.state.config.adults == null) {
      router.push({name: 'step01', params: {'locale': to.params.locale}});
    }
  } else if(['step04', 'step05','summary'].indexOf(to.name) != -1) {
    if(store.state.config.estate == null) {
      router.push({name: 'step01', params: {'locale': to.params.locale}});
    }
  } else if(['summary'].indexOf(to.name) != -1) {
    //no required fields in this step
  }

  window.document.title = t('meta_title');
  if(document.querySelector('meta[name="description"]'))
    document.querySelector('meta[name="description"]').setAttribute("content", t('meta_description'));
  if(document.querySelector('meta[property="og:title"]'))
    document.querySelector('meta[property="og:title"]').setAttribute("content", t('meta_title'));
  if(document.querySelector('meta[property="og:description"]'))
    document.querySelector('meta[property="og:description"]').setAttribute("content", t('meta_description'));

  if(to.name == 'wheretosettle')
    window.document.title = t('meta_title_wheretosettle');
  if(to.name == 'costofliving')
    window.document.title = t('meta_title_costofliving');

  window.di.pageName = to.name;
  return true;
})


// router.beforeEach((to, from, next) => {
//
//   if (to.params.locale === from.params.locale) {
//     next()
//     return
//   }
//
//   //const { locale } = to.params
//
//   // loadLocaleMessagesAsync(locale).then(() => {
//   //   setDocumentLang(locale)
//   //   setDocumentDirectionPerLocale(locale)
//   //   setDocumentTitle(i18n.t("app.title"))
//   // })
//
//   next()
// })

export default router
