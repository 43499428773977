<template>
  <section class="towns">
    <div class="wrap">
      <div class="row jcc">
        <div class="col-12 col-lg-10">
          <h2>{{$t('step_summary_other_cities_title')}}</h2>
          <p class="fw-l">{{$t('step_summary_other_cities_title_caption')}}</p>
        </div>
        <div class="col-12 col-lg-10 px-0">
          <div class="form">
            <div class="row">
              <div class="col col-12 col-lg-4">
                <select @change="this.onRegionChange($event)" name="regionSummary" v-model="this.$store.state.config.region" id="regionSummary" v-bind:placecholder="$t('step_summary_choose_other_region')">
                  <option value="" disabled>{{$t('step_summary_select_other_region')}}</option>
                  <option v-for="(district, key) in this.getDistricts()" :key="'district_'+key" v-bind:value="district.woj_no">{{$t('district_'+district.woj_no)}}</option>
                </select>
              </div>
              <div class="col col-12 col-lg-4">
                <input class="loop" type="text" v-model="search" @keyup="this.handleKeyUp" name="town" id="town" v-bind:placeholder="$t('step_summary_type_city_name')">
                <div v-if="this.items.length > 0" id="autocomplete-results-wrapper">
                  <div v-for="(item, key) in this.getItems()" :key="'autocomplete_' + key" @click="this.handleCitySelection(item)" class="autocomplete-item">
                    {{this.getCityName(item)}}
                  </div>
                </div>
                <div v-if="this.showNotFound" class="autocomplete-results-wrapper">
                  <div class="autocomplete-item not-found-error">
                    {{$t('step_02_not_found')}}
                  </div>
                  <div v-for="(item, k) in this.getItemsExamples()" :key="'autocomplete_example_' + k" @click="this.handleCitySelection(item)" class="autocomplete-item">
                    {{this.getCityName(item)}}
                  </div>
                </div>
              </div>
              <div class="col col-12 col-lg-4">
                <a @click="InlineButtonClickHandler" class="btn w-100" type="">{{ $t('step_summary_check') }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-10">
          <h2>{{$t('step_summary_example_cities', {district: this.getRegionName()})}}</h2>
        </div>



        <div class="townsList col-12 col-lg-10">
          <div class="row nowrap">

            <div v-for="(city, k) in this.cities" :key="'cities_'+k" class="townsListSingle col-6 col-sm-3-1 col-md-4-1 col-lg-6-1">
              <a @click="this.onSelectCity(city)" v-bind:class="{'hover': this.$store.state.config.city.id == city.id}">
                        <span class="row">
                            <span class="region col-12"><span class="h4"><strong class="orange tt-u">{{ $t('district_' + city.woj_no) }}</strong> <br>{{this.getCityName(city)}}</span></span>
                            <span class="offers col-12">
                              <span>
                                <template v-if="this.getJobsOffersCount(city) < 5"><strong>&lt; 5</strong> - {{$t('step_summary_gratka_jobs_count')}}</template>
                                <template v-if="this.getJobsOffersCount(city) >= 5"><strong>{{this.getJobsOffersCount(city)}}</strong> - {{$t('step_summary_gratka_jobs_count')}}</template>
                              </span><br>
                              <span>
                                <template v-if="this.getEstatesOffersCount(city) < 5"><strong>&lt; 5</strong> - {{$t('step_summary_flats_count')}}</template>
                                <template v-if="this.getEstatesOffersCount(city) >= 5"><strong>{{this.getEstatesOffersCount(city)}}</strong> - {{$t('step_summary_flats_count')}}</template>
                              </span>


                            </span>
                            <span class="citizen col-12"><span class="bigger">{{numberWithSpaces(city.pop_in_city)}}</span> <br>{{$t('step_summary_citizens')}}</span>
                        </span>
              </a>
            </div>


          </div>
        </div>

      </div>
    </div>
  </section>
</template>
<script>
var searchTimeout = null;

export default {
  data() {
    return {
      showNotFound: false,
      cities: null,
      search: null,
      items: [],
      itemsExamples: [],
    }
  },
  created() {
    this.$store
        .dispatch('fetchBiggestCities', {
          'district': this.$store.state.config.region,
          'industry': this.$store.state.config.industry
        })
        .then(cities => {
          this.cities = cities;
          this.itemsExamples = cities
        }).catch(error => {
      console.log(error);
    });
  },
  methods: {
    getJobsOffersCount(city) {
      return city.jobs_offers_count;
    },
    getEstatesOffersCount(city) {
      return city.estates_offers_count;
    },
    getItems() {
      return this.items;
    },
    getItemsExamples() {
      return this.itemsExamples;
    },
    handleCitySelection(item) {//releated to autocomplete
      this.selected = item;
      this.search = this.getCityName(item);
      this.items = [];
      this.showNotFound=false;

      this.onSelectCity(item);
      window.scrollTo(0,0);
    },
    getDistricts() {
      var list = this.$store.state.earnings_by_district;
      return list.sort(function(a,b) {
        return ('' + a.woj_name).localeCompare(b.woj_name)
      });

    },
    onRegionChange(event) {
      console.log('fffex', event.target.value);
      this.search = null;
      this.items = [];
      this.$store
          .dispatch('fetchBiggestCities', {
            'district': event.target.value
          })
          .then(cities => {
            this.cities = cities
          }).catch(error => {
        console.log(error);
      });

      this.$store
          .dispatch('setRegion', {
            'region': event.target.value
          })
      ;
    },
    onSelectCity(city) {

      this.$store
          .dispatch('setCity', {
            'city': city
          })
      ;

      this.$store
          .dispatch('setRegion', {
            'region': city.woj_no
          })
      ;
      window.scrollTo(0,0);
      this.$emit('changed');
    },
    handleKeyUp() {
      this.showNotFound=false;
      var thisComponent = this;
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(function() {

        var searchObject = {'phrase': thisComponent.search, 'region': thisComponent.$store.state.config.region};
        thisComponent.$store.dispatch('fetchCityAutocomplete', searchObject)
            .then(items => {
              thisComponent.items = items
              if(items.length == 0) {
                thisComponent.showNotFound=true;
              }
            }).catch(error => {
          console.log(error);
        });
      }, 400);
    },
    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    getRegionName()
    {
      return this.$t('district_ms_' + this.$store.state.config.region);
    },
    getCityName(item) {

      if(this.$store.state.lang == 'pl')
        return item.city_pol;
      if(this.$store.state.lang == 'en')
        return item.city_eng;
      if(this.$store.state.lang == 'ua')
        return item.city_uah;
    },
    InlineButtonClickHandler(event) {
      event.section = 'Summary Cities';
      this.$emit('sateliteClick', event);
      this.$emit('analyticsEvent', 'check');
    }
  }
}
</script>