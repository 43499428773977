<template>
  <section class="banner">
    <div class="bg">
      <img class="mobile" src="@/assets/dist/img/aboutBgMob.svg" v-bind:alt="$t(this.pageTitle)">
      <img class="desktop" src="@/assets/dist/img/aboutBg.svg" v-bind:alt="$t(this.pageTitle)">
    </div>
    <div class="wrap visible">
      <div class="row ">
        <div class="col-12 col-lg-6 col-xl-5 px-0 offset-xl-1 bannerCont">
          <div class="row">
            <div class="col-12">
              <h1 v-html="$t(this.pageTitle)"></h1>
              <p>{{$t(this.pageContent)}}</p>
            </div>
            <div class="bannerPower col-12">
              <p>{{$t('page_about_powered')}}</p>
              <div class="row">
                <div class="bannerPowerSingle col-auto">
                  <img src="@/assets/dist/img/powerLogo01.svg" alt="powerLogo01">
                </div>
              </div>
            </div>
            <div class="bannerPower col-12">
              <p>{{$t('page_about_partnership')}}</p>
              <div class="row">
                <div class="bannerPowerSingle col-auto">
                  <img src="@/assets/dist/img/powerLogo02.svg" alt="powerLogo02">
                </div>
                <div class="bannerPowerSingle col-auto">
                  <img src="@/assets/dist/img/powerLogo03.svg" alt="powerLogo03">
                </div>
                <div class="bannerPowerSingle col-auto">
                  <img src="@/assets/dist/img/powerLogo04.png" alt="powerLogo04">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ['pageTitle', 'pageContent']
}

</script>