<template>
  <section class="faq">
    <div class="wrap">
      <div class="row jcc">
        <div class="col-12 col-lg-10">
          <div class="row aic">
            <span class="icon icon-quest"><span class="path1"></span><span class="path2"></span></span>
            <h2>{{ $t('home_faq_title')}}</h2>
          </div>
        </div>
        <div class="faqList col-12 col-lg-10 px-lg-3">
          <div class="row">
            <div v-for="item in items" v-bind:key="item" class="faqListSingle col-12">
              <a @click="handleQuestionClick(item.index)" v-bind:data-oki="'#faq'+item.index" aria-expanded="false">{{ item.question}} <span class="icon icon-down"></span></a>
              <div class="okiCollapse" v-bind:class="{'open': this.isOpen(item.index)}" v-bind:id="'faq'+item.index" data-parent=".faqList" v-html="item.answer">

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  data() {
    return {
      items: [],
      opened: []
    }
  },
  watch: {
    '$store.state.lang': function() {
      this.generateItems();
    }
  },
  mounted() {

  },
  created() {

    if(this.items.length == 0) {
      this.generateItems();
    }
  },
  methods: {
    extractItemIndex(key) {

      var rx = /home_faq_items_(.*)_/g;
      var arr = rx.exec(key);
      return arr[1];
    },
    getItems() {


      return this.items;
    },
    handleQuestionClick(index) {
      if(this.isOpen(index)) {
        this.opened.splice(this.opened.indexOf(index), 1);
      } else {
        this.opened = [];
        this.opened.push(index);
      }
    },
    isOpen(index) {
      return this.opened.indexOf(index) !== -1;
    },
    generateItems() {
      var elems = [];
      for(var index in this.$root.$i18n.messages[this.$root.$i18n.locale]) {

        if(index.indexOf('home_faq_items_') !== -1) {

          var key = this.extractItemIndex(index);
          if(key != null) {

            var found = false;
            for(var i in elems) {
              if(elems[i].index == key) {
                found = true;
                break;
              }
            }
            if(found == false) {
              elems.push({
                'index': key
              });

            }

            if(index.indexOf('_question') !== -1) {
              for(i in elems) {
                if(elems[i].index == key) {
                  elems[i]['question'] = this.$t(index);
                }
              }
            } else if(index.indexOf('_answer') !== -1) {
              for(i in elems) {
                if(elems[i].index == key) {
                  elems[i]['answer'] = this.$t(index);
                }
              }
            }

          }

        }
      }
      this.items = elems;
    }
  }

}
</script>