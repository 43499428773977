<template>
  <section class="summary">
    <div class="wrap visible">
      <div class="row">
        <div class="summaryDesc col-12 col-lg-5">
          <div class="row">
            <div class="col-12 px-0">
              <span class="icon icon-block2"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>
              <h1>{{$t('step_summary_compare')}}</h1>
              <p class="options">{{$t('step_summary_city')}}: <big class="yellow tt-n">{{this.getCityName()}}</big> {{$t('step_summary_other_cities')}} <br>{{$t('step_summary_industry')}}: <big class="yellow tt-n">{{this.getIndustry()}}</big></p>
              <p class="fw-light in">{{$t('step_summary_caption')}}</p>
            </div>
            <div class="col-12 col-lg-auto summaryFilter px-0 mb-lg-3">
              <form>
                <div class="row">
                  <div class="col col-12 px-0 m-0">
                    <label for="filter">{{$t('step_summary_filter_by')}}</label>
                    <select name="filter" id="filter" v-model="this.city_filter">
                      <option value="2">{{$t('step_summary_filter_2')}}</option>
                      <option value="3">{{$t('step_summary_filter_3')}}</option>
                      <option value="1">{{$t('step_summary_filter_1')}}</option>
                      <option value="4">{{$t('step_summary_filter_4')}}</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
            <div class="desktop col-lg-auto mt-lg-auto mb-lg-3">
              <router-link :to="{ name: 'step01', params: {'locale': this.$store.state.lang}}" @click="InlineButtonClickHandler" class="change" ><span class="icon icon-roll"></span> {{$t('step_summary_change_answers')}}</router-link>
            </div>
            <!-- <div class="summaryPower desktop col-12 px-0">
              <p>{{$t('page_about_powered')}}</p>
              <div class="row">
                <div class="summaryPowerSingle col-auto">
                  <img src="@/assets/dist/img/powerLogo01.svg" alt="powerLogo01">
                </div>
              </div>
            </div>
            <div class="summaryPower desktop col-12 px-0">
              <p>{{$t('page_about_partnership')}}</p>
              <div class="row">
                <div class="summaryPowerSingle col-auto">
                  <img src="@/assets/dist/img/powerLogo02.svg" alt="Gratka">
                </div>
                <div class="summaryPowerSingle col-auto">
                  <img src="@/assets/dist/img/powerLogo03.svg" alt="Morizon">
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="summaryRes col-12 col-lg-6 offset-lg-1--off">
          <div class="row">
            <div v-for="(city, key) in this.getSummaryCities()" v-bind:key="'city_' + key" @click="this.onSelectCity(city, $event)" @mouseover="this.highlightCityOnMap(city)" @mouseout="this.highlightCityOnMap(null)" class="summaryResSingle col-12 col-lg-8 col-xlg-7" v-bind:class="{'active': city.qb_id == this.$store.state.config.city.id}" v-bind:data-name="city.qb_cityPol">
              <p><span class="h2">{{this.getCityNameNearBy(city)}}</span>
                <span v-if="city.qb_id == this.$store.state.config.city.id"> ({{$t('step_summary_you_location')}})</span>
                <span v-else :key="this.distanceComponentKey"> ({{this.getDistance(city)}})</span>
              </p>
              <p><strong>{{this.calcSpendingsForCity(city)}} {{ $t('currency_' + this.$store.state.currency)}}</strong> {{$t('step_summary_spendings')}}</p>
              <p><strong>{{this.getEarningsForCity(city)}} {{ $t('currency_' + this.$store.state.currency)}}</strong> {{$t('step_summary_salary_gros')}}</p>
              <p>
                <template v-if="this.getJobOffersCount(city) < 5"><strong>&lt; 5</strong> - {{$t('step_summary_gratka_jobs_count')}}</template>
                <template v-if="this.getJobOffersCount(city) >= 5"><strong>{{this.getJobOffersCount(city)}}</strong> - {{$t('step_summary_gratka_jobs_count')}}</template>
                <a href="#" class="tooltip" v-bind:id="'tooltip_city_jobs_'+city.qb_id" @click.prevent="handleCitiesTooltip" data-toggle="tooltip" data-placement="top" v-bind:title="$t('step_summary_gratka_jobs_tooltip')"><span class="icon icon-question"></span> <span class="tooltip_cont"><span class="close mobile"><span class="icon icon-x2"></span></span>{{$t('step_summary_gratka_jobs_tooltip')}}</span></a>
              </p>
              <p>
                <template v-if="this.getEstateOffersCount(city) < 5"><strong>&lt; 5</strong> - {{$t('step_summary_flats_count')}}</template>
                <template v-if="this.getEstateOffersCount(city) >= 5"><strong>{{this.getEstateOffersCount(city)}}</strong> - {{$t('step_summary_flats_count')}}</template>
                <a href="#" class="tooltip" v-bind:id="'tooltip_city_flats_'+city.qb_id" @click.prevent="handleCitiesTooltip" data-toggle="tooltip" data-placement="top" v-bind:title="$t('step_summary_gratka_flats_tooltip')"><span class="icon icon-question"></span> <span class="tooltip_cont"><span class="close mobile"><span class="icon icon-x2"></span></span>{{$t('step_summary_gratka_flats_tooltip')}}</span></a>
              </p>
            </div>
            <SummaryMap ref="summaryMap" :citiesToShow="this.getCitiesToShowOnMap()" :mobileVisibility="this.mobileMapExpanded" />
          </div>
        </div>
        <div class="summaryDesc col-12 px-0">
          <div class="row">
            <div class="mobile col-12 mb-3">
              <router-link :to="{ name: 'step01', params: {'locale': this.$store.state.lang}}" @click="InlineButtonClickHandler" class="change" ><span class="icon icon-roll"></span> {{$t('step_summary_change_answers')}}</router-link>
            </div>
            <div class="mobile col-12 mb-3">
              <!-- <a @click="this.toogleMobileMap()" data-oki="#mapRegion" v-bind:aria-expanded="this.mobileMapExpanded" class="btn btn-full"><span class="btn-val">{{$t('banks_see_on_map')}} <span class="icon icon-pin2"></span></span> <span class="hover"></span></a> -->
              <a @click="this.toogleMobileMap()" data-oki="#mapRegion" v-bind:aria-expanded="this.mobileMapExpanded" class="change"><span class="icon icon-pin2"></span> {{$t('banks_see_on_map')}}</a>
            </div>
            <div class="col-12 col-lg-3 px-3 mb-3 ta-c nextSec">
                <!-- <p @click="scrollToEarningsSummary">{{$t('step_summary_go_next')}} <span class="icon icon-down"></span></p> -->
                <a @click="scrollToEarningsSummary" href="#" class="btn btn-full"><span class="btn-val">{{$t('step_summary_go_next')}}</span> <span class="hover"></span></a>
            </div>
            <!-- <div class="summaryPower mobile col-12">
              <p>{{$t('page_about_powered')}}</p>
              <div class="row">
                <div class="summaryPowerSingle col-auto">
                  <img src="@/assets/dist/img/powerLogo01.svg" alt="powerLogo01">
                </div>
              </div>
            </div>
            <div class="summaryPower mobile col-12">
              <p>{{$t('page_about_partnership')}}</p>
              <div class="row">
                <div class="summaryPowerSingle col-auto">
                  <img src="@/assets/dist/img/powerLogo02.svg" alt="powerLogo02">
                </div>
                <div class="summaryPowerSingle col-auto">
                  <img src="@/assets/dist/img/powerLogo03.svg" alt="powerLogo03">
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <SummaryEarnings ref="earningsSummary" />
  <SummaryGratka ref="summaryGratka" />
  <SummarySave ref="summarySave" @analyticsEvent="$emit('analyticsEvent', 'save')" @sateliteClick="this.handleSateliteClick" />
  <SummaryCities @changed="handleChange" @analyticsEvent="$emit('analyticsEvent', 'check')" @sateliteClick="this.handleSateliteClick" />
  <SummarySchools v-if="this.$store.state.config.kids > 0" @sateliteClick="this.handleSateliteClick" />
  <section class="banner bannerFull">
      <div class="wrap">
          <div class="bg">
              <!-- <img class="mobile" src="@/assets/dist/img/puBanner01Mob.jpg" alt="puBanner01Mob"> -->
              <img class="desktop" src="@/assets/dist/img/puBanner01.jpg" alt="puBanner01">
          </div>
          <div class="bannerCont">
              <div class="row aic h-100 jcc">
                  <div class="col-12 col-lg-10 px-0">
                      <div class="row">
                          <div class="col-12 logos">
                              <div class="row">
                                  <img src="@/assets/dist/img/pu_logo_03.png" alt="pu_logo_03">
                                  <img src="@/assets/dist/img/pu_logo_02.svg" alt="pu_logo_02">
                                  <img src="@/assets/dist/img/pu_logo_01.svg" alt="pu_logo_01">
                              </div>
                          </div>
                          <div class="col-12 cont">
                              <h1 class="m-0">#PomagamUkrainie</h1>
                              <h2 v-html="$t('help_ua_get_help')"></h2>
                          </div>
                          <div class="col-12 col-lg-4 cta">
                              <a href="https://pomagamukrainie.gov.pl/" class="btn btn-full" target="_blank" rel="nofollow"><span class="btn-val">{{$t('help_ua_goto')}}</span> <span class="hover"></span></a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>


  <SummaryBanksAndLinks @sateliteClick="this.handleSateliteClick" />
  <SummaryModal @sateliteClick="this.handleSateliteClick" ref="summaryModal" />
<!--  <FixedPanel />-->
</template>

<script>

//import FixedPanel from "@/views/partials/FixedPanel.vue";
import SummaryEarnings from "@/views/partials/SummaryEarnings.vue";
import SummaryBanksAndLinks from "@/views/partials/SummaryBanksAndLinks";
import SummaryGratka from "@/views/partials/SummaryGratka.vue";
import SummarySave from "@/views/partials/SummarySave";
import SummaryCities from "@/views/partials/SummaryCities";
import SummaryMap from "@/views/partials/SummaryMap.vue";
import SummaryModal from "@/views/partials/SummaryModal.vue";
import SummarySchools from "@/views/partials/SummarySchools.vue";

function slugify(str) {
  return str.replaceAll('ą', 'a')
      .replaceAll('ć', 'c')
      .replaceAll('ę', 'e')
      .replaceAll('ł', 'l')
      .replaceAll('ń', 'n')
      .replaceAll('ó', 'o')
      .replaceAll('ś', 's')
      .replaceAll('ż', 'z')
      .replaceAll('ź', 'z')
  ;
}

export default {
  components: {
    //FixedPanel,
    SummaryEarnings,SummaryBanksAndLinks, SummaryGratka, SummarySave, SummaryCities, SummaryMap, SummaryModal, SummarySchools
  },
  watch: {
    remoteData() {

      for(var idx in this.remoteData[this.$store.state.currency]) {
        if(idx == 0)
          continue;

        //this.findDistance(this.remoteData[this.$store.state.currency][idx]);
      }
    },
    city_filter() {
      this.getNearBy();
    }
  },
  mounted() {
    if(this.$store.state.config.estate == 1)
      this.rent_1 = true;
    if(this.$store.state.config.estate == 2)
      this.rent_2 = true;
    if(this.$store.state.config.estate == 3)
      this.rent_3 = true;
    if(this.$store.state.config.estate == 4)
      this.rent_4 = true;

    if(this.$store.state.config.ownVehicle) {
      this.fuel = true;
    }
    if(this.$store.state.config.publicTransportation) {
      this.publicTransportation = true;
    }
    if(this.$store.state.config.pets > 0)
      this.pets = true;

    this.getNearBy();
  },
  created() {
    window.document.body.classList.remove('mainPage');
    window.document.body.classList.add('subPage');
  },
  data() {
    return {
      mobileMapExpanded: false,
      city_filter: 2,
      remoteData: [],
      costs_total: null,
      distances: [],
      distanceComponentKey: 0,

      rent_1: false,
      rent_2: false,
      rent_3: false,
      rent_4: false,
      supermarket: true,
      fuel: false,
      publicTransportation: false,
      restaurants: true,
      pets: false,
      wh: window.innerHeight,
      ww: window.innerWidth
    }
  },
  beforeCreate() {
    this.$store
        .dispatch('changeFooterVisibility', {
          'visibility': true
        })
    ;
  },
  methods: {
    toogleMobileMap() {
      this.mobileMapExpanded = !this.mobileMapExpanded;
    },
    showModal(){
      this.$refs.summaryModal.open();
    },
    handleChange() {
      this.getNearBy();
      this.$refs.summarySave.handleSave();

      this.$refs.summaryGratka.retrieveFlats();
      this.$refs.summaryGratka.retrieveJobs();
    },
    getCitiesToShowOnMap() {
      var list = [];
      var cities = this.getSummaryCities();

      for(var index in cities) {

        list.push(
            slugify(cities[index].qb_cityPol.toLowerCase()).replaceAll(" ", "_").replaceAll("-","_")
        );

      }
      return list.join(' ');
    },
    getEstateOffersCount(city) {
      return city['estatesOffersCount'];
    },
    getJobOffersCount(city) {
      return city['offersCount'];
    },
    getEarningsForCity(city) {
      return city['qb_sal' + this.$store.state.config.industry];
    },
    getDistance(city) {
      return this.$t('step_summary_away_from', {'how_far': city.distance+'km'});
      //return this.$t('step_summary_away_from', {'how_far': this.getHowFar(city.qb_id)});
    },
    getHowFar(city_id) {
      if(this.distances['city_' + city_id] != undefined)
        return this.distances['city_' + city_id]+ 'km';

    },
    onSelectCity(city, event) {

      if(event.target.classList.contains('icon-question'))
        return;
      this.$store
          .dispatch('setCity', {
            'city': city
          })
      ;

      this.$store
          .dispatch('setRegion', {
            'region': city.woj_no
          })
      ;

      this.getNearBy();
      this.$refs.summarySave.handleSave();
      this.$refs.summaryGratka.retrieveFlats();
      this.$refs.summaryGratka.retrieveJobs();
    },
    highlightCityOnMap(city) {
      this.$refs.summaryMap.hightlightCity(city);
    },
    getSummaryCities() {
      if(this.remoteData != null) {
        return this.remoteData[this.$store.state.currency];
      }
    },
    getNearBy() {

      this.$store
          .dispatch('fetchNearBy', {'filter': this.city_filter})
          .then(costs => {

            this.remoteData = costs;

          }).catch(error => {
        console.log(error);
      });
    },
    getCityName() {

        if(this.$store.state.lang == 'pl')
          return this.$store.state.config.city.city_pol;
        if(this.$store.state.lang == 'en')
          return this.$store.state.config.city.city_eng;
        if(this.$store.state.lang == 'ua')
          return this.$store.state.config.city.city_uah;
    },
    getCityNameNearBy(city) {

      if(this.$store.state.lang == 'pl')
        return city.qb_cityPol;
      if(this.$store.state.lang == 'en')
        return city.qb_cityEng;
      if(this.$store.state.lang == 'ua')
        return city.qb_cityUah;
    },
    getIndustry() {
       return this.$t('industry_'+this.$store.state.config.industry);
    },
    findDistance(destination) {
      var component = this;
      var service = new window.google.maps.DistanceMatrixService();
      service.getDistanceMatrix({
            origins: [this.$store.state.config.city.city_pol],
            destinations: [destination.qb_cityPol],
            travelMode: window.google.maps.TravelMode.DRIVING,
            unitSystem: window.google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false
          }, function (response, status) {
            if (status == window.google.maps.DistanceMatrixStatus.OK && response.rows[0].elements[0].status != "ZERO_RESULTS") {
              component.distances['city_' + destination.qb_id] = parseInt(response.rows[0].elements[0].distance.value/1000);
              component.forceRenenderDistances();
            } else {
              console.log('could not find the distance');
            }
      });
    },
    calcSpendingsForCity(city) {
      //var people = this.$store.state.config.adults + this.$store.state.config.kids;
      var peopleWeighted = this.$store.state.config.adults + (this.$store.state.config.kids * 1.05);
      var peopleWeightedTransport = this.$store.state.config.adults + (this.$store.state.config.kids * 0.5);


      if(this.remoteData == null )
        return;

      var costs = {};
      costs.rent_1 = Math.round(city.qb_rent32m);
      costs.rent_2 = Math.round(city.qb_rent45m);
      costs.rent_3 = Math.round(city.qb_rent60m);
      costs.rent_4 = Math.round(city.qb_rent110m);

      //supermarket

      costs.supermarket = Math.round(city.qb_spdFoodChem * peopleWeighted);

      if(this.fuel) {
        costs.fuel = Math.round(city.qb_spdFuelAcv);
      }

      if(this.publicTransportation) {
        costs.publicTransportation = Math.round(city.qb_monthlyTicket * peopleWeightedTransport);
      }

      costs.restaurants = Math.round(city.qb_spdRestaurantsTicket * peopleWeighted);

      costs.pets = Math.round(city.qb_spdAnimalsAcv * this.$store.state.config.pets);

      costs.total = (
          (this.rent_1 ? costs.rent_1 : 0) +
          (this.rent_2 ? costs.rent_2 : 0) +
          (this.rent_3 ? costs.rent_3 : 0) +
          (this.rent_4 ? costs.rent_4 : 0) +
          (this.fuel ? costs.fuel : 0) +
          (this.publicTransportation ? costs.publicTransportation : 0) +
          (this.supermarket ? costs.supermarket : 0) +
          (this.restaurants ? costs.restaurants : 0)+
          (this.pets ? costs.pets : 0)
      );

      return costs.total;
    },
    forceRenenderDistances() {
      this.distanceComponentKey += 1;
    },
    InlineButtonClickHandler() {

      this.$emit('analyticsEvent', 'started');
    },
    handleCitiesTooltip(e) {

      // console.log('handleTooltip e', e.currentTarget, document.querySelector('body'));
      if (this.ww < 1024) {

        if (!e.currentTarget.classList.contains('show')) {
          e.currentTarget.classList.add('show');
          if (!document.querySelector("body").classList.contains('tootlipOpen')) {
            document.querySelector("body").classList.add('tootlipOpen');
          }
        } else {
          e.currentTarget.classList.remove('show');
          document.querySelector("body").classList.remove('tootlipOpen');
        }

        var tooltipsElems = document.getElementsByClassName('tooltip');

        for(var index in tooltipsElems) {
          if(typeof tooltipsElems[index] == "object") {

            if(e.currentTarget.getAttribute('id') != tooltipsElems[index].getAttribute('id')) {
              tooltipsElems[index].classList.remove('show');
            }
          }
        }
      }
    },
    scrollToEarningsSummary(event) {
      event.preventDefault();
      event.stopPropagation();
      // this.$refs['earningsSummary'].$el.scrollIntoView({ behavior: "smooth" });
      var tempPos = this.$refs['earningsSummary'].$el.offsetTop;//this.$refs['earningsSummary'].$el.getBoundingClientRect();
      window.scrollTo(0,tempPos);
      return false;
    }
  }
}
</script>