<template>
  <div class="fixedPanel">
    <div class="wrap">
      <div class="row jcc">
        <div class="fixedPanelWrapper col-12 col-lg-auto ta-c">
          <router-link :to="{ name: 'step01', params: {'locale': this.$store.state.lang}}" @click="InlineButtonClickHandler" class="btn btn-full btn-auto-lg"><span class="btn-val">{{ this.label }}</span> <span class="hover"></span></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['label'],
  data() {
    return {
      lastPosition: -1
    }
  },
  created() {


    window.addEventListener("scroll", this.updateScroll);
    window.addEventListener('load', this.updateScrollWithTimeout);
    window.addEventListener('resize', this.updateScrollWithTimeout);


  },
  methods: {
    updateScrollWithTimeout() {
      var $this = this;
      setTimeout(function(){
        $this.updateScroll()
      },300);
    },
    updateScroll() {

      this.lastPosition = window.pageYOffset;

      var fP = document.querySelector('.fixedPanel');
      var main = document.querySelector(".main");


      //var wh = window.innerHeight;

      if (main) {
        if (this.lastPosition >= (main.parentElement.offsetTop + main.offsetHeight-200) && this.lastPosition > 0) {

          if (!fP.classList.contains('fixed')) {
            fP.classList.add('fixed');
          }
        } else {
          if (this.lastPosition >= (main.parentElement.offsetTop + main.offsetHeight) && this.lastPosition > 0) {
            if (!fP.classList.contains('fixed')) {
              fP.classList.add('fixed');
            }
          } else {
            fP.classList.remove('fixed');
          }
        }
      } else {
        fP.classList.add('fixed');
        // if (this.lastPosition >= (wh/2)) {
        //   if (!fP.classList.contains('fixed')) {
        //     fP.classList.add('fixed');
        //   }
        // } else {
        //   fP.classList.remove('fixed');
        // }
      }

    },
    InlineButtonClickHandler(event) {
      event.section = 'FixedPanel';
      this.$emit('sateliteClick', event);
      this.$emit('analyticsEvent', 'started');
    }
  },
  unmounted() {
    window.removeEventListener("scroll", this.updateScroll);
    window.removeEventListener("load", this.updateScrollWithTimeout);
    window.removeEventListener("resize", this.updateScrollWithTimeout);
  }
}
</script>