<template>
  <section class="steps stepsTransport">
    <div class="wrap visible">
      <div class="row aic jcc">
        <div class="stepsNav col-12 col-lg-10">
          <StepsMenu :active="4"/>
        </div>
        <div class="stepsDesc col-12 ta-c px-0">
          <div class="row jcc">
            <div class="col-12 col-lg-8 mb-lg-5">
              <div class="step"><p class="yellow"><strong><span class="val">4</span>/5</strong></p></div>
              <h1 class="mb-5 h2">{{$t('step_04_title')}}</h1>
              <p class="fw-light">{{$t('step_04_caption')}}</p>
              <p><strong>{{$t('step_04_bold')}}</strong></p>

              <form>
                <div class="row jcc">
                  <div class="box col col-6">
                    <input v-model="this.publicTransportation" type="checkbox" name="transport01" id="transport01" value="public">
                    <label for="transport01">
                      <span class="icon icon-train2"></span>
                      <span class="name" v-html="$t('step_04_public_transportation')"></span>
                    </label>
                  </div>
                  <div class="box col col-6">
                    <input v-model="this.ownVehicle" type="checkbox" name="transport02" id="transport02" value="own">
                    <label for="transport02">
                      <span class="icon icon-car2"></span>
                      <span class="name">{{$t('step_04_own_vehicle')}}</span>
                    </label>
                  </div>
                </div>
              </form>
            </div>

            <div class="stepDescNav col-12 px-0 mt-auto">
              <div class="row jcc">
                <div class="col-6 mobile">
                  <router-link :to="{'name': 'step03', params: {'locale': this.$store.state.lang}}" @click="InlineButtonClickHandler" class="btn btn-w btn-full"><span class="btn-val">{{$t('steps_prev')}}</span> <span class="hover"></span></router-link>
                </div>
                <div class="col-6 col-lg-auto desktop">
                  <router-link :to="{'name': 'step03', params: {'locale': this.$store.state.lang}}" @click="InlineButtonClickHandler" class="btn btn-ghost btn-full"><span class="btn-val">{{$t('steps_prev')}}</span> <span class="hover"></span></router-link>
                </div>
                <div class="col-6 col-lg-auto offset-lg-1">
                  <router-link :to="{name: 'step05', params: {'locale': this.$store.state.lang}}" @click="InlineButtonClickHandler" class="btn btn-full hover"><span class="btn-val">{{$t('steps_next')}}</span> <span class="hover"></span></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import StepsMenu from "@/views/partials/StepsMenu";
export default {
  components: {StepsMenu},
  mounted() {

  },
  data() {
    return {
      publicTransportation: false,
      ownVehicle: false
    }
  },
  created() {
    if(this.$store.state.config.publicTransportation) {
      this.publicTransportation = this.$store.state.config.publicTransportation;
    }
    if(this.$store.state.config.ownVehicle) {
      this.ownVehicle = this.$store.state.config.ownVehicle;
    }
  },
  watch: {
    publicTransportation(newValue) {
      this.$store
          .dispatch('setPublicTransportation', {
            'publicTransportation': newValue
          })
      ;
    },
    ownVehicle(newValue) {
      this.$store
          .dispatch('setOwnVehicle', {
            'ownVehicle': newValue
          })
      ;
    }
  },
  methods: {
    nextStepAvailable() {
      return true;
    },
    InlineButtonClickHandler(event) {

      this.$emit('analyticsEvent', 'step04');
      event.section = 'Step04';
      this.$emit('sateliteClick', event);
    }
  }
}
</script>