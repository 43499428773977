<template>
  <section class="reg regTop">
    <div class="wrap">
      <div class="row aic">
        <div class="regLogo col-12 ta-c order-lg-2 mt-lg-3">
          <img src="@/assets/dist/img/where-to-settle-baner.png" v-bind:alt="$t(this.pageTitle)">
        </div>
        <div class="col-12 ta-c">
          <h1>Where to settle as a Ukrainian refugee?</h1>
        </div>
      </div>
    </div>
  </section>
  <section class="reg">
    <div class="wrap">
      <div class="row">
        <div class="col-12">
          <h2>Moving to Poland: Where to settle as a Ukrainian refugee?</h2>
          <p>Since the beginning of the war in Ukraine, Poland has welcomed over 1.5 million refugees. With no end in sight to the Russian full-scale invasion and cities across Ukraine experiencing air raids and attacks almost daily, many more people are leaving their homes and seeking safety across the Polish border. We would like to support them in choosing the best place to live and thrive in our country. Let us then try and answer some of the most important questions, such as:</p>
          <p>“Where to settle in Poland?”, <br> “What is life like in Poland?” and <br>“What are the pros and cons of living in Poland?”.</p>

          <h3>The best places to live in Poland.</h3>
          <p>Unsurprisingly, Ukrainians seeking shelter in Poland have mostly settled in large cities, such as Warsaw, Gdańsk, Wrocław, or Kraków, while the population of Rzeszów—the largest city in south-eastern Poland—has doubled. Let’s explore what these cities have to offer for Ukrainian refugees and help you decide if it is the right place for you and your family.</p>
          <ol>
            <li><strong>Warsaw.</strong> Poland’s capital city offers a wide range of opportunities for all newcomers, including employment, education, international schools, and support groups. As in any big city, there are all kinds of cultural attractions – many galleries, concerts, and cinemas that often have English-language screenings. There are also great leisure facilities – swimming pools, climbing walls, and sports clubs. Home to approximately 2 million people, Warsaw boasts a cosmopolitan atmosphere with diverse neighborhoods – while in the city center, you will hear various foreign languages – including lots of Ukrainian and English, and all kinds of food from around the world. <br><br>Despite it having some expensive areas, you can still find affordable housing and other amenities in Warsaw. Since Ukrainian refugees are entitled to the same benefits as Poles, including health insurance, free public education, and child allowance, Warsaw could prove to be a safe place for Ukrainian families that provides multiple opportunities. There are also many dedicated help centers for Ukrainians – with free clothing, language lessons, and support in finding work and housing.</li>
            <li><strong>Kraków.</strong> Since February 24, 2022, approximately 150,000 people fleeing the war have arrived in Kraków, a city in the south of Poland known for its historical charm, vibrant arts scene, and a mix of cultural heritage and modernity. The city offers a high standard of living and varied employment opportunities, particularly in IT and, especially, in tourism – the city is a popular destination all year round, with the Christmas markets in the Old Town square just as popular as summer city breaks to sample the many restaurants. There are 82 schools in Kraków with 140 Ukrainian-speaking teachers and almost 300 teachers qualified to teach Polish as a foreign language, which is an important aspect for families with children.</li>
            <li><strong>Wrocław.</strong> Situated in south-western Poland on the Oder (among other rivers!), Wrocław offers a dynamic blend of history, stunning architecture, and a growing economy. It hosts several universities, research centers, start-ups, and technology and business parks, making it an appealing destination for Ukrainian refugees pursuing higher education or professional growth. Wrocław’s architecture and history (it was for many years the German city of Breslau and has a long, fascinating history) make it a popular all-round tourist destination, just like Kraków – with plenty of hotels and restaurants catering to travelers from Europe and beyond. Also, a short train trip takes you into Czechia, Germany, or the stunning Sudeten Mountains.</li>
            <li><strong>Gdańsk.</strong> Located on the Baltic Coast of northern Poland, Gdańsk offers a high quality of life, with modern infrastructure, cultural attractions, and a thriving international community. Despite being one of the most expensive Polish cities, it provides employment opportunities for both unqualified and qualified workers across multiple industries, including shipbuilding, petrochemical, food, and amber processing, electronics, telecommunications, IT engineering, cosmetics, and pharmaceuticals.</li>
            <li><strong>Poznań.</strong> The western city is the capital of the Wielkopolski (Greater Poland) region. A bustling city of half a million people, it’s the most prosperous in Poland after Warsaw. A well-developed business sector with many Western firms basing their headquarters in the city means employment opportunities in finance, banking, and the pharmaceutical industry. Poznań is also home to a thriving tech and start-up scene.</li>
          </ol>
          <img src="@/assets/dist/img/whereToSettleImg02.jpg" alt="whereToSettleImg02">

          <h3>Find the best city for you, online.</h3>
          <p>If you are considering moving to Poland and building a new life in the country, check out our user-friendly <router-link :to="{ name: 'home', params: {'locale': this.$store.state.lang}}">“Where to Settle”</router-link> digital tool. It has been developed to help you visualize what the living costs in various Polish cities are like, as well as provide information on approximate wages in different parts of the country. <router-link :to="{ name: 'step01', params: {'locale': this.$store.state.lang}}" @click="InlineButtonClickHandler">Click here</router-link> to answer a few questions and find the location that best corresponds to your needs.</p>

          <h3>Moving to Poland: What to expect.</h3>
          <p>Moving abroad and settling in a foreign country, even temporarily, can be an extremely difficult experience. To make your move less stressful, here is a list of the top three things to bear in mind when moving to and living in Poland.</p>
          <ol>
            <li><strong>Life in Poland</strong> – the costs. Compared to other European countries, the cost of living in Poland is relatively affordable. Although housing, groceries, transportation, rent, and entertainment expenses compare favorably with Western European countries, be mindful that prices in Poland can vary dramatically depending on where you stay – large cities are also more expensive and it will cost more to support yourself and your family. Many Ukrainian refugees have moved to smaller cities where the daily cost of living is lower.</li>
            <li><strong>Language barrier.</strong> Polish is among the most difficult languages to learn, and although many people speak English, learning Polish might be a necessity if you are planning on staying for a longer time – basic Polish might be required by an employer, and speaking good Polish will certainly help widen the range of jobs you could do. Fortunately, a number of public and private institutions are offering free language courses to support Ukrainian refugees learn Polish.</li>
            <li><strong>Bureaucracy.</strong> The Polish government adopted a special act on assistance for refugees from Ukraine roughly a week after the outbreak of the war. Intended to offer a substitute for a normal life, this special law was created to help Ukrainians legally stay in Poland without unnecessary bureaucracy. It gave the right to legal residence and to a PESEL number, as well as social assistance, cash benefits, and access to the labor market and public health care. Still, bureaucracy in Poland is complicated, and legal advice might sometimes be necessary. Thankfully, many aid centers provide legal information and assistance free of charge to Ukrainian refugees.</li>
          </ol>

          <h3>The pros of living in Poland.</h3>
          <p>As a next-door neighbor and a country that has offered widespread solidarity with Ukrainians, Poland has been a natural destination since the very first day of the Russian invasion. Safety and support have undoubtedly been the most significant factors for Ukrainian citizens when deciding to relocate to Poland. It is relatively culturally similar, and even the language isn’t quite so foreign. Let’s now explore some additional advantages of living in Poland.</p>
          <ol>
            <li><strong>Education.</strong> Poland boasts a good education system, offering various academic programs across the country. Ukrainian refugees can pursue higher education or vocational training to enhance their career prospects. Polish universities are internationally recognized, and many programs are taught in English as well as Ukrainian, making them accessible to non-native Polish speakers. Additionally, as a response to the war in Ukraine, the Ministry of Education and Science has introduced measures to ensure that Ukrainian students have the right to continue their education in Poland. This means that every child between the ages of 7 and 18 can attend a Polish school. Similarly, Ukrainian students can continue their studies at Polish universities.</li>
            <li><strong>Healthcare.</strong> Poland’s healthcare system is well-developed, providing accessible and affordable medical services covered by the public health fund. Compulsory health insurance in Poland also covers family members, including spouses, children, and parents and grandparents residing in the same house. Ukrainian refugees are eligible for public healthcare benefits on the basis of the European Union Council Implementing Decision (EU) 2022/382 of 4 March 2022 and the Act of 12 March 2022 on assistance to Ukrainian citizens in connection with the war of armed forces in their country. This gives Ukrainians access to healthcare services on the same terms as persons covered by compulsory or voluntary health insurance.</li>
            <li><strong>Economic stability and a strong job market.</strong> Poland has experienced significant economic growth in recent years, resulting in a thriving job market and a low unemployment rate. The country offers diverse employment opportunities across various sectors, including IT, finance, manufacturing, tourism, and services. Ukrainian refugees with relevant skills and qualifications can find fulfilling careers in Poland. Although, like in every country, there have been setbacks due to the global coronavirus pandemic as well as recently soaring inflation, Poland still provides solid foundations for business development and international entrepreneurship.</li>
            <li><strong>Low crime rate.</strong> Poland is considered a safe country, with low crime rates and a well- functioning legal system. Ukrainian refugees can feel secure and protected, contributing to a sense of peace and well-being.</li>
            <li><strong>Public infrastructure.</strong> Efficient transportation networks, reliable public services, and advanced telecommunications are affordable and widely available. Major Polish cities are also conveniently connected with other European countries, providing you with the opportunity to travel or relocate further West.</li>
            <li><strong>European Union membership.</strong> Poland’s membership in the European Union can provide Ukrainian refugees with additional benefits and opportunities. It grants them access to the EU job market, education systems, and social welfare benefits, increasing their potential for personal and professional growth.</li>
          </ol>

          <h3>Finding employment in Poland.</h3>
          <p>Since February 24, 2022, Ukrainian citizens have been able to start working in Poland legally without a work permit. Most, however, work at jobs with qualifications below their level of education – it is estimated that as many as 50% of Ukrainian refugees have a university degree. Research shows that the restaurant and hotel business, service sector, production, trade, and logistics are among the most popular industries for Ukrainian refugees to find employment in while living in Poland. The Polish government continually tries to assist integration into the labor market for refugees by passing regulations that are expected to ensure they are guaranteed adequate work standards.</p>

          <h3>Should I move to Poland as a Ukrainian citizen?</h3>
          <p>Poland offers Ukrainian refugees a promising environment with a range of cities to choose from, based on individual preferences and opportunities. The country’s rich culture, diverse job market, affordable cost of living, and well-developed infrastructure make it an attractive option for you and your family. While there are challenges such as language barriers and complicated bureaucracy, Polish society’s huge support, solidarity, and acceptance help create a favorable destination for Ukrainian refugees seeking a fresh start and a brighter future, or a place to call home temporarily until they are safe to return to Ukraine. <sup>1</sup></p>

          <p><small><sup>1</sup>The presented information is based on Mastercard’s anonymized transaction data and publicly available external sources, including long-term archived data regarding average salaries – obtained from Statistics Poland (GUS) – and job and rental listings from Gratka.pl to provide a personalized estimation of the average, basic costs of living and remuneration in the chosen location.</small></p>
        </div>
      </div>
    </div>
  </section>

  <section class="reg">
    <div class="wrap">
      <div class="row">
        <div class="col-12">
          <TermsPl v-if="this.pageTitle == 'page_terms_title' && this.$store.state.lang == 'pl'"/>
          <TermsEn v-if="this.pageTitle == 'page_terms_title' && this.$store.state.lang == 'en'"/>
          <TermsUa v-if="this.pageTitle == 'page_terms_title' && this.$store.state.lang == 'ua'"/>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import TermsPl from "@/views/terms/TermsPl";
import TermsEn from "@/views/terms/TermsEn";
import TermsUa from "@/views/terms/TermsUa";
export default {
  components: {TermsPl, TermsEn, TermsUa},
  props: ['pageTitle', 'pageContent', 'pageStartsFrom'],
  mounted() {
    this.$store
        .dispatch('changeFooterVisibility', {
          'visibility': true
        })
    ;
  },
  methods: {
    getCurrentLang() {
      return this.$store.state.lang;
    },
    InlineButtonClickHandler(event) {

      event.section = 'Home';
      this.$emit('sateliteClick', event);
      this.$emit('analyticsEvent', 'started');
    }
  }
}
</script>