export default {
  "top_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WhereToSettle"])},
  "langswitcher_lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "langswitcher_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
  "langswitcher_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "home_banner_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where to settle?"])},
  "home_banner_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find your place with us."])},
  "home_banner_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our tool will help you get an idea of what the living costs in Polish cities are like. It will also show you potential job and housing offers."])},
  "home_banner_content_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer a few questions and find the location that best corresponds to your needs."])},
  "home_banner_btn_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin"])},
  "home_who_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why was WhereToSettle invented?"])},
  "home_who_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastercard®, together with Grupa Morizon-Gratka and the Ukrainian House, has created this site to help you find the best place to live and work with just a few clicks."])},
  "home_who_strong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We support Ukrainian refugees in finding their place in Poland."])},
  "home_who_btn_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
  "home_earnings_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earnings per industry"])},
  "home_earnings_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an industry"])},
  "home_earnings_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover the average, approximate earnings per voivodeship in one of several industries.*"])},
  "home_earnings_small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Average gross salaries in the business sector and broken down into relevant PKD (Polish Classification of Activities) groups."])},
  "home_earnings_show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all"])},
  "home_info_citizens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["citizens"])},
  "home_info_need_an_bank_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you need a bank <br>account in Poland?"])},
  "home_info_need_an_bank_account_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening an account in a local bank can make it easier to start your life in a new place. See a list of all banking institutions in the area."])},
  "home_info_see_banks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show institutions"])},
  "home_info_check_cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selection of cities available on the website"])},
  "home_faq_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently asked questions"])},
  "home_faq_items_1_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why and for whom did Mastercard create the WhereToSettle website?"])},
  "home_faq_items_1_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>The WhereToSettle website was created to help Ukrainian citizens find refuge from war in Poland. However, the tool will prove helpful for anyone interested in relocating, regardless of reasons or origin, providing information on the average cost of living and approximate wages in different parts of the country. This is our form of supporting you in deciding where to stay for the long term.</p>"])},
  "home_faq_items_2_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How does the WhereToSettle website work?"])},
  "home_faq_items_2_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Filling out the form is the first step to starting your search. Our tool suggests results relevant to the provided information based on anonymized Mastercard data, statistical data from the Statistics Poland (GUS), and offers available on Gratka.pl.</p>"])},
  "home_faq_items_3_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From which source and period does the information presented on the website come?"])},
  "home_faq_items_3_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>The WhereToSettle website uses anonymized Mastercard transaction data and publicly available external sources, such as the Statistics Poland (GUS) in Poland. The information provided is for the period 01.06.2022 –31.12.2022.</p>"])},
  "home_faq_items_4_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What does \"average monthly cost\" mean?"])},
  "home_faq_items_4_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>The average monthly cost of living for a given household is calculated based on answers provided in the form and estimated costs of living of an average adult in a selected area in Poland. Provided results are indicative and may vary based on individual preferences and a dynamic economic situation.</p>"])},
  "home_faq_items_5_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the form, I selected the cost of living for 2 adults and 2 children. Can I expect that amount to be final at my destination?"])},
  "home_faq_items_5_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Provided prices are approximate. On their basis, you can get an idea of the estimated costs of renting an apartment in a given area. The WhereToSettle website is not meant for comparing or negotiating offers or prices. There are several economic factors, including individual conditions of service and goods providers, that can affect the presented rates and influence the final cost.</p>"])},
  "home_faq_items_6_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The drop-down list is missing the data I am interested in. What should I do?"])},
  "home_faq_items_6_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>We do our best to provide you with as much information as possible based on the data that is available to us. For now, please fill out the form to get personalized results.</p>"])},
  "home_faq_items_7_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have made a mistake in one of the questions. Can I correct my answers?"])},
  "home_faq_items_7_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Yes. Return to the form, choose the specific question, and correct your answer to update the result.</p>"])},
  "home_faq_items_8_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are these all the costs I can expect at my destination?"])},
  "home_faq_items_8_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>The website provides approximate and basic living costs in a given location, including rent, grocery shopping, and transportation. Depending on one’s individual preferences and needs, as well as additional factors, the final cost of living may differ.</p>"])},
  "home_faq_items_9_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I compare the average cost of living in different Polish cities?"])},
  "home_faq_items_9_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Yes, after analyzing the data from the form, the WhereToSettle tool presents the result for three cities: your current place of residence and two other cities nearby.</p>"])},
  "home_faq_items_10_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I save my results and see them later?"])},
  "home_faq_items_10_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>You can generate multiple results and save each as a link, which you can visit later or share with your friends and family.</p>"])},
  "home_faq_items_11_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I check the cost of living outside Poland?"])},
  "home_faq_items_11_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>No, the WhereToSettle website only provides information on the average monthly cost of living and salaries in Poland.</p>"])},
  "home_faq_items_12_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At what rate are the EUR/UAH amounts converted?"])},
  "home_faq_items_12_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>The conversion rate on the WhereToSettle website is updated once a day based on the average exchange rate of foreign currencies of the National Bank of Poland (NBP).</p>"])},
  "home_faq_items_13_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to open a bank account. How can I find a banking institution? "])},
  "home_faq_items_13_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>After you enter your location, the website will help you find the closest bank in your area. Click the “Show institutions” button in the “Do you need a bank account in Poland?” section at the bottom of the home page.</p>"])},
  "home_faq_items_14_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many square meters can I expect with the following criteria: 1, 2, 3, 4 and more rooms?"])},
  "home_faq_items_14_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>On average, 1 room is 32 sqm, 2 rooms are 45 sqm, 3 rooms are 60 sqm, 4 rooms and more – 110 sqm.</p>"])},
  "home_faq_items_15_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do rental costs quoted on WhereToSettle match the reality?"])},
  "home_faq_items_15_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Rental costs quoted on WhereToSettle are estimated and may vary depending on extra fees such as utility and renovation expenses.</p>"])},
  "home_faq_items_16_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I expect a salary in the range quoted on WhereToSettle?"])},
  "home_faq_items_16_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Gross salary is estimated based on the latest data from the Statistics Poland (GUS). We only quote average wages per industry. Therefore, they cannot be regarded as a basis for claims against employers.</p>"])},
  "home_faq_items_17_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why does the website ask me to access my device location?"])},
  "home_faq_items_17_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>By providing access to your location, we can show you a list of the nearest banking institutions in your area.</p>"])},
  "home_faq_items_18_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why does the WhereToSettle website ask me to provide information concerning my family size?"])},
  "home_faq_items_18_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>We use this information to estimate the average cost of living in a given location for the number of people indicated in the form.</p>"])},
  "home_faq_items_19_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On which devices does the WhereToSettle website work?"])},
  "home_faq_items_19_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>The WhereToSettle website works on all devices such as computers, tablets, smartphones. The website is also available in mobile (Android and iOS) and desktop versions.</p>"])},
  "home_faq_items_20_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What does “average gross salary” mean?"])},
  "home_faq_items_20_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Average gross salary means the average remuneration that an employee may receive for work performed in a given industry. “Gross” means the amount prior to the deduction of compulsory state benefits and taxes (income tax, social insurance contributions borne by the employee). Following the said deduction, we get the net amount, i.e. remuneration the employee receives on payday (so-called “take-home pay”). The contributions deducted from the amount of gross remuneration vary depending on, among others, the type of contract concluded and the amount of remuneration received.</p>"])},
  "home_links_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useful links"])},
  "home_links_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://pomagamukrainie.gov.pl/#potrzebuje-pomocy"])},
  "home_links_1_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Government assistance"])},
  "home_links_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://ukrainskidom.pl/"])},
  "home_links_2_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-governmental aid"])},
  "home_links_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.nfz.gov.pl/dla-pacjenta-z-ukrainy/"])},
  "home_links_3_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Healthcare"])},
  "terms_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
  "policy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
  "footer_lang_and_currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language and currency"])},
  "page_terms_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules for the use of GdzieZamieszkac.com website"])},
  "page_terms_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "page_terms_starts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Website Rules shall enter into force on 5 July 2022."])},
  "page_policy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
  "page_policy_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.mastercard.com/global/en/vision/corp-responsibility/commitment-to-privacy/privacy.html"])},
  "page_about_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How WhereToSettle <br>was created."])},
  "page_about_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This advanced tool created by Mastercard was filled with information provided by our partner, Grupa Morizon-Gratka. The Ukrainian House provided patronage for the project."])},
  "page_about_powered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POWERED BY"])},
  "page_about_partnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PARTNERSHIPS"])},
  "page_about_partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
  "page_about_mastercard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastercard is a global tech company in the payments industry. Thanks to the secure use of data, Mastercard’s services and tools help customers, institutions, and companies make the most of their potential."])},
  "page_about_morizon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Morizon-Gratka group is the leading classified ads website publisher in Poland. For over 20 years, Gratka.pl has enjoyed continued popularity among users interested in real estate, automotive, and job listings. Morizon.pl is one of the largest real estate marketplaces."])},
  "page_about_uah_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Ukrainian House"])},
  "page_about_uah_home_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The organization is run by the “Our Choice” foundation to facilitate integration and support for the Ukrainian community. The House is open to all foreigners and serves as a space for exchanging experiences and creating a sense of belonging."])},
  "page_about_how_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How does the tool work?"])},
  "page_about_how_works_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The tool analyzes Mastercard's aggregated data along with open-access information obtained from official sources and our Partners to help users find the best place to live in Poland."])},
  "page_about_data_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We rely on data and reliable information"])},
  "page_about_data_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You might be wondering what WhereToSettle results are based on."])},
  "page_about_data_mastercard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on the anonymized basket values in various shopping categories, we can calculate the average costs of living and rental prices."])},
  "page_about_data_gus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The average earnings per industry are based on annual, open-access reports issued by Statistics Poland."])},
  "page_about_data_morizon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The job and housing offers come from the current listings available on our partner’s website."])},
  "page_about_data_title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastercard"])},
  "page_about_data_title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics Poland (GUS)"])},
  "page_about_data_title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morizon-Gratka Group"])},
  "page_about_toolkit_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download a toolkit (1.3 MB)"])},
  "page_about_toolkit_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to promote your city among people looking for peace and new possibilities? Download our marketing package! Containing graphic assets and invaluable tips on recording a simple promotional video, our ready-made bundle will help you reach your goal."])},
  "page_about_story_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hear their stories"])},
  "page_about_story_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Meet Ukrainian women who share their experiences from their first months in Poland.</p><p><strong>Our „WhereToSettle\" tool was created for, and thanks to, people like them.</strong></p>"])},
  "page_about_btn_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill the form"])},
  "banks_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banking institutions"])},
  "banks_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get the locations of banking institutions in your current or desired location."])},
  "banks_type_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in the address"])},
  "banks_see_on_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See on the map"])},
  "banks_geolocation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We couldn’t find this address, please use the search engine."])},
  "steps_menu_step01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry selection"])},
  "steps_menu_step02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Household members"])},
  "steps_menu_step03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flat selection"])},
  "steps_menu_step04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport selection"])},
  "steps_menu_step05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City selection"])},
  "steps_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "steps_prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "step_01_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What kind of work are you interested in?"])},
  "step_01_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose interested industry"])},
  "step_02_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many people live with you?"])},
  "step_02_adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adults (including you):"])},
  "step_02_kids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Children (below the age of 15):"])},
  "step_02_i_have_pets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have pets"])},
  "step_02_pets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pets:"])},
  "step_02_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found"])},
  "step_03_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What kind of apartment are you looking for?"])},
  "step_03_estate_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-room apartment"])},
  "step_03_estate_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-room apartment"])},
  "step_03_estate_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-room apartment"])},
  "step_03_estate_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4-room apartment"])},
  "step_04_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which means of transport do you use?"])},
  "step_04_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you use your own vehicle, we’ll calculate the average monthly cost of fuel in a given city."])},
  "step_04_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can select both means of transport."])},
  "step_04_public_transportation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public <br>transport"])},
  "step_04_own_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal vehicle"])},
  "step_05_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where in Poland do you currently live?"])},
  "step_05_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will get the result for the chosen location and two cities nearby."])},
  "step_05_type_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in the city/town"])},
  "step_05_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This city is not available, please enter a different name or choose from the list below"])},
  "step_summary_compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare the best places to live and work."])},
  "step_summary_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "step_summary_other_cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and two other cities nearby"])},
  "step_summary_industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry"])},
  "step_summary_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* These are gross wages based on the data from Statistics Poland (GUS). Therefore, they cannot be regarded as a basis for claims against employers."])},
  "step_summary_filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter cities by:"])},
  "step_summary_you_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your location"])},
  "step_summary_away_from": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("how_far")), " from your location"])},
  "step_summary_salary_gros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gross earnings*"])},
  "step_summary_spendings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expenses"])},
  "step_summary_job_offers_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["job offers"])},
  "step_summary_flats_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apartments for rent"])},
  "step_summary_filter_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job offers"])},
  "step_summary_filter_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highest gross earnings"])},
  "step_summary_filter_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowest living costs"])},
  "step_summary_filter_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of apartments for rent"])},
  "step_summary_avg_salary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross earnings"])},
  "step_summary_earnings_spendings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expenses"])},
  "step_summary_earnings_earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross earnings"])},
  "step_summary_earnings_spendings_are_percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Expenses represent<br>\n <span class=\"val\">", _interpolate(_named("percent")), "%</span><br>\n of the earnings"])},
  "step_summary_earnings_small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*The amounts below have been estimated for the selected city. Learn more about gross earnings in the FAQ section."])},
  "step_summary_avg_spendings_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Average monthly living expenses in <span class=\"yellow\">", _interpolate(_named("city")), "</span>"])},
  "step_summary_avg_earning_spendings_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Average expenses and earnings for the <span class=\"yellow\">", _interpolate(_named("industry")), "</span> industry, in <span class=\"yellow\">", _interpolate(_named("city")), "</span>"])},
  "step_summary_family_label_adults_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for 1 adult"])},
  "step_summary_family_label_adults_many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["for ", _interpolate(_named("people")), " adults"])},
  "step_summary_family_label_family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for family"])},
  "step_summary_family_label_detailed_adults_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 adult"])},
  "step_summary_family_label_detailed_adults_many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("people")), " adults"])},
  "step_summary_family_label_detailed_kids_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 child"])},
  "step_summary_family_label_detailed_kids_many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("people")), " children"])},
  "step_summary_family_label_detailed_pets_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 pet"])},
  "step_summary_family_label_detailed_pets_many": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("pets")), " pets"])},
  "step_summary_family_label_detailed_public_transportation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["using public transport"])},
  "step_summary_family_label_detailed_own_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["driving a car"])},
  "step_summary_prices_are_aprox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" *Provided costs are approximate per given city. If you wish to learn more about gross earnings, visit the FAQ section."])},
  "step_summary_change_answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to form"])},
  "step_summary_rent_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental price (1 apartment) "])},
  "step_summary_rent_1_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average rental price for a 32m2 apartment in a given city. Rental costs are only an estimate and may vary depending on extra charges."])},
  "step_summary_rent_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental price (2 rooms)"])},
  "step_summary_rent_2_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average rental price for a 45m2 apartment in a given city. Rental costs are only an estimate and may vary depending on extra charges."])},
  "step_summary_rent_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental price (3 rooms)"])},
  "step_summary_rent_3_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average rental price for a 60m2 apartment in a given city. Rental costs are only an estimate and may vary depending on extra charges."])},
  "step_summary_rent_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental price (4 rooms)"])},
  "step_summary_rent_4_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average rental price for a 110 m2 apartment in a given city. Rental costs are only an estimate and may vary depending on extra charges."])},
  "step_summary_supermarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supermarket"])},
  "step_summary_supermarket_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on the average monthly expenses per 1 adult in the food and cleaning supplies categories."])},
  "step_summary_fuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuel costs"])},
  "step_summary_fuel_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly cost per 1 car."])},
  "step_summary_public_transportation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public transportation"])},
  "step_summary_public_transportation_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Based on the price of a monthly ticket in a given city. A single ticket in a given city ", _interpolate(_named("city")), " for an adult costs ", _interpolate(_named("cost")), _interpolate(_named("currency")), " "])},
  "step_summary_restaurants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurants"])},
  "step_summary_restaurants_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on the average monthly expenses per 1 adult."])},
  "step_summary_pets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pets"])},
  "step_summary_pets_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on the average monthly expenses per 1 pet in pet shops."])},
  "step_summary_need_a_bank_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you need <br>a bank account in Poland?"])},
  "step_summary_bank_account_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening an account in a local bank can make it easier to start your life in a new place. See a list of all banking institutions in the area."])},
  "step_summary_total_annualy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total (monthly)"])},
  "step_summary_avg_earnings_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Average gross salaries<br />in the city ", _interpolate(_named("city"))])},
  "step_summary_choose_industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select industries"])},
  "step_summary_avg_earnings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover the average monthly earnings based on selected industries and location.*"])},
  "step_summary_avg_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Average gross salaries in the business sector and broken down into relevant PKD (Polish Classification of Activities) groups. "])},
  "step_summary_select_other_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose another region"])},
  "step_summary_citizens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["citizens"])},
  "step_summary_other_cities_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to check other cities/towns?"])},
  "step_summary_other_cities_title_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a voivodeship and then select a city/town to see the average monthly living expenses in that area."])},
  "step_summary_choose_other_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose another region"])},
  "step_summary_type_city_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in the city/town"])},
  "step_summary_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
  "step_summary_example_cities": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Explore cities in the ", _interpolate(_named("district")), " voivodeship"])},
  "step_summary_save_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save your form results"])},
  "step_summary_save_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save the results as a link. Keep them for later or share with your family and friends right away."])},
  "step_summary_save_btn_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save results"])},
  "step_summary_gratka_show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all offers"])},
  "step_summary_gratka_estates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apartments"])},
  "step_summary_gratka_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jobs"])},
  "step_summary_gratka_jobs_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job offers"])},
  "step_summary_gratka_jobs_count0to5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job offers"])},
  "step_summary_gratka_estates_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apartments for rent"])},
  "step_summary_gratka_estates_count0to5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apartments for rent"])},
  "step_summary_gratka_flats_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The offers come from Gratka.pl and Morizon.pl"])},
  "step_summary_gratka_jobs_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The offers come from Gratka.pl"])},
  "step_summary_find_flat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searching for an apartment?"])},
  "step_summary_find_flat_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These are some of the apartments we’ve chosen for you based on your answers. Click the \"All Offers\" tab to discover more interesting places."])},
  "step_summary_find_flat_desc_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, we have not found any apartments that match your criteria in this location on <strong>Gratka.pl</strong> and <strong>Morizon.pl</strong>. If you find this tool helpful, try it again in a few days to find new offers."])},
  "step_summary_find_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See job offers selected especially for you."])},
  "step_summary_find_job_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go through the list and choose the one you like the most."])},
  "step_summary_find_job_desc_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, we have not found any job offers that match your criteria on <strong>Gratka.pl</strong> in these categories. The job market is changing rapidly, so come back in a few days to check for new offers."])},
  "step_summary_flat_rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartment to rent"])},
  "step_summary_flat_room_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-room"])},
  "step_summary_flat_room_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-rooms"])},
  "step_summary_flat_room_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3-rooms"])},
  "step_summary_flat_room_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4-rooms"])},
  "step_summary_flat_room_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5-rooms"])},
  "step_summary_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate our tool"])},
  "step_summary_modal_question1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is this tool helpful in finding a place to live and work in Poland?"])},
  "step_summary_modal_question2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are the informations useful?"])},
  "step_summary_modal_question3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are the results accurate?"])},
  "step_summary_modal_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "step_summary_modal_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your rate!"])},
  "step_summary_go_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check results"])},
  "browser_not_support_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your browser does not support this feature!"])},
  "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Url copied to clipboard"])},
  "copy_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unexpected error has occurred"])},
  "not_found_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We couldn’t find this page"])},
  "not_found_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just a few steps. That’s all it takes to find your place in Poland."])},
  "not_found_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home page"])},
  "industry_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office administration"])},
  "industry_B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction/Architecture, geodesy"])},
  "industry_C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education, coaching"])},
  "industry_D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Franchise, independent business"])},
  "industry_E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet, e-commerce, new media"])},
  "industry_F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT administration"])},
  "industry_G": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistics, transportation, shipping"])},
  "industry_H": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing, promotion, PR"])},
  "industry_I": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automotive"])},
  "industry_J": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Service, call center"])},
  "industry_K": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Law"])},
  "industry_L": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agriculture"])},
  "industry_M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cleaning, housekeeping, and caregiving"])},
  "industry_N": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance"])},
  "industry_O": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping"])},
  "industry_P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State administration, public sector"])},
  "industry_R": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulting"])},
  "industry_S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finances, economy, banking"])},
  "industry_T": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HR, Occupational Safety and Health"])},
  "industry_U": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engineering, technology"])},
  "industry_W": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT programming"])},
  "industry_X": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality control"])},
  "industry_Y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media, journalism, entertainment, culture"])},
  "industry_Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real estate"])},
  "industry_AA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual labor"])},
  "industry_AB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production"])},
  "industry_AC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertising, design, photography"])},
  "industry_AD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
  "industry_AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauty, health, sports"])},
  "currency_pln": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zł"])},
  "currency_eur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€"])},
  "currency_uah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UAH"])},
  "district_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dolnośląskie"])},
  "district_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kujawsko-pomorskie"])},
  "district_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lubelskie"])},
  "district_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lubuskie"])},
  "district_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["łódzkie"])},
  "district_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["małopolskie"])},
  "district_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mazowieckie"])},
  "district_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opolskie"])},
  "district_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podkarpackie"])},
  "district_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podlaskie"])},
  "district_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pomorskie"])},
  "district_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["śląskie"])},
  "district_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["świętokrzyskie"])},
  "district_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["warmińsko-mazurskie"])},
  "district_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wielkopolskie"])},
  "district_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zachodniopomorskie"])},
  "district_ms_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dolnośląskim"])},
  "district_ms_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kujawsko-pomorskim"])},
  "district_ms_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lubelskim"])},
  "district_ms_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lubuskim"])},
  "district_ms_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["łódzkim"])},
  "district_ms_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["małopolskim"])},
  "district_ms_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mazowieckim"])},
  "district_ms_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opolskim"])},
  "district_ms_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podkarpackim"])},
  "district_ms_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podlaskim"])},
  "district_ms_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pomorskim"])},
  "district_ms_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["śląskim"])},
  "district_ms_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["świętokrzyskim"])},
  "district_ms_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["warmińsko-mazurskim"])},
  "district_ms_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wielkopolskim"])},
  "district_ms_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zachodniopomorskim"])},
  "toolkit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toolkit"])},
  "meta_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where to settle? Mastercard"])},
  "meta_title_wheretosettle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where to settle as a Ukrainian refugee? - Where to settle? Mastercard"])},
  "meta_title_costofliving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Cost of Living in Poland - Where to settle? Mastercard"])},
  "meta_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the cost of living, job and housing offers in Poland."])},
  "help_ua_get_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get legal and administrative help and find support in Poland."])},
  "help_ua_goto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the site"])},
  "patronage_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patronage"])},
  "patronage_cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Association of Polish Cities"])},
  "patronage_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The oldest Polish organisation of local governments. Its mission is to support the social and economic growth of Polish cities and promote the modern and innovative governance of local communities."])},
  "home_school_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational establishments in your area"])},
  "home_school_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for establishments that teach in Ukrainian."])},
  "home_school_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show establishments"])},
  "schools_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational establishments "])},
  "schools_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>When choosing a school that offers the opportunity to study in Ukrainian, it is necessary to examine the curriculum of the chosen establishment personally. The number of places in schools is limited*.</p>\n<p>Often, when no school in the area offers the possibility of studying in Ukrainian, local communities search for alternatives, such as after-school activities for children. Therefore, checking if such an option is available in the chosen area is a good idea.</p>\n<p style=\"font-size: 14px;\">*Details on enrollment and fees are available on the establishments’ websites. To learn about the rules for admitting Ukrainian children to Polish schools, check the website:\n<a href=\"https://www.gov.pl/web/psse-lask/zasady-przyjmowania-dzieci-z-ukrainy-do-polskich-szkol\" target=\"_blank\" rel=\"nofollow\">https://www.gov.pl/web/psse-lask/zasady-przyjmowania-dzieci-z-ukrainy-do-polskich-szkol</a></p>"])},
  "school_list_1_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkoła „Materinka”"])},
  "school_list_1_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zgoda 5/5, Warsaw, Poland"])},
  "school_list_1_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.ukranska-shkola-materinka.mozello.com"])},
  "school_list_1_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/vV4N381ThyWG1m6W9"])},
  "school_list_2_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warszawska Szkoła Ukraińska"])},
  "school_list_2_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grójecka 128A, 02-383 Warszawa"])},
  "school_list_2_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.vshkolu.edu.pl"])},
  "school_list_2_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/gVL7UvF793nhvNqx6"])},
  "school_list_3_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraińska szkoła w Polsce z fundacji „Ukraina Niezniszczalna”"])},
  "school_list_3_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al. Jerozolimskie 179, 02-222, Warszawa"])},
  "school_list_3_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.nezlamna.org"])},
  "school_list_3_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "school_list_4_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkoła Sobotnia (Wrocławski Związek Ukraińców w Polsce)"])},
  "school_list_4_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruska 46/202, 50-079 Wrocław"])},
  "school_list_4_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.facebook.com/CUKRuska/"])},
  "school_list_4_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/KcfeKDv2xMAAX7VG7"])},
  "school_list_5_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraińska szkoła w Polsce z fundacji „Ukraina Niezniszczalna”"])},
  "school_list_5_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aleja Wiśniowa, 1, 53-313, Wrocław"])},
  "school_list_5_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.nezlamna.org"])},
  "school_list_5_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/K4cqHAi6h96CEtGR8"])},
  "school_list_6_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraińska szkoła w Polsce z fundacji „Ukraina Niezniszczalna”"])},
  "school_list_6_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Świętego Filipa 25, 31-150, Kraków"])},
  "school_list_6_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.nezlamna.org"])},
  "school_list_6_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "school_list_7_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zespół szkół średnich imienia Tarasa Szewczenki"])},
  "school_list_7_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dworcowa 25, 78-425 Biały Bór"])},
  "school_list_7_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.liceum-bialybor.pl"])},
  "school_list_7_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/mzGdBQ5V2FobKjQS9"])},
  "school_list_8_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkoła Podstawowa Nr 2 w Białym Borze"])},
  "school_list_8_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Słupska 27, 78-425 BIAŁY BÓR"])},
  "school_list_8_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.sp2bbor.eu"])},
  "school_list_8_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/xp1ZXGCETVjHWEHu5"])},
  "school_list_9_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkoła Podstawowa nr 8  im. Łesi Ukrainki www.lesiabart.pl"])},
  "school_list_9_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leśna 1, 11-200 Bartoszyce"])},
  "school_list_9_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.lesiabart.pl"])},
  "school_list_9_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/dDhqnnw4VRzwWZ7L7"])},
  "school_list_10_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zespół  Szkół z Ukraińskim Językiem Nauczania w Górowie Iławeckim"])},
  "school_list_10_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkolna 6, 11-220 Górowo Iławeckie"])},
  "school_list_10_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.szkolazdumka.pl"])},
  "school_list_10_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/9x2ZE7K73xosxWwi9"])},
  "school_list_11_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zespół Szkół Ogólnokształcących Nr 2 im. Markiana Szaszkewicza w Przemyślu"])},
  "school_list_11_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smolki 10, 37-700 Przemyśl"])},
  "school_list_11_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" www.szaszk.edu.pl"])},
  "school_list_11_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/bDrmq4aD42uN5L1d7"])},
  "school_list_12_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkoła Podstawowa z Oddziałami Mistrzostwa Sportowego nr 9 im. A.Mickiewicza mieszcząca się w budynku byłego Gimnazjum nr 1 w Rybniku Oddziały przygotowawcze"])},
  "school_list_12_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cmentarna 1, 44-200 Rybnik"])},
  "school_list_12_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.sp9.miastorybnik.pl/for-foreigners/"])},
  "school_list_12_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/81wc3ZfLp8Yo8Q7Y9"])},
  "school_list_13_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkoła Podstawowa im. A. Mickiewicza w Bielsku Podlaskim (oddzielne klasy ukraińskie z nauczaniem języka ukraińskiego)"])},
  "school_list_13_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adama Mickiewicza 126"])},
  "school_list_13_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zs-mickiewicz.szkolnastrona.pl"])},
  "school_list_13_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/vqk8j2ztkUqggW8GA"])},
  "school_list_14_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zespół Szkolno-Przedszkolny w Czeremsze"])},
  "school_list_14_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szkolna 2, 17-240 Czeremcha"])},
  "school_list_14_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zspczeremcha.pl/index.php/pl/"])},
  "school_list_14_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/fvonmV4zwwHyMcWh6"])},
  "school_list_15_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niepubliczna Szkoła Podstawowa św. św. Cyryla i Metodego"])},
  "school_list_15_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ludwika Waryńskiego 30, 15-461 Białystok"])},
  "school_list_15_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szkolacim.szkolnastrona.pl"])},
  "school_list_15_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/htAj9RbNkfub4Zdi6"])},
  "school_list_16_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IV Liceum Ogólnokształcące w Legnicy"])},
  "school_list_16_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarasa Szewczenki 10, 59-220;"])},
  "school_list_16_www": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zso4legnica.pl"])},
  "school_list_16_gmaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://goo.gl/maps/Tu7uDEJ5teBQWoN97"])},
  "summary_schools_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational establishments in your area"])},
  "summary_schools_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for establishments that teach in Ukrainian."])},
  "summary_schools_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show establishments"])},
  "see_on_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See on the map"])},
  "page_wheretosettle_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where to settle as a Ukrainian refugee?"])},
  "wheretosettle_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where to settle"])},
  "costofliving_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost of living"])}
}