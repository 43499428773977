import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//import i18n from './i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'

import i18n from './i18n/index'
import LoadScript from "vue-plugin-load-script";
import VueCookies from 'vue3-cookies';



createApp(App).use(i18n).use(store).use(router).use(VueAxios, axios).use(LoadScript)
    .use(VueCookies, {
        expireTimes: "30d",
        path: "/",
        domain: "",
        secure: true,
        sameSite: "None"
    })
    .mount('#app')

