<template>
  <section class="steps stepsFamily">
    <div class="wrap visible">
      <div class="row aic jcc">
        <div class="stepsNav col-12 col-lg-10">
          <StepsMenu :active="2"/>
        </div>

        <div class="stepsDesc col-12 ta-c px-0">
          <div class="row jcc">
            <div class="col-12 col-lg-6 mb-lg-5">
              <div class="step"><p class="yellow"><strong><span class="val">2</span>/5</strong></p></div>
              <h1 class="mb-lg-5 h2">{{$t('step_02_title')}}</h1>
              <form>
                <div class="row jcc">
                  <div class="col col-12 col-lg-10">
                    <label for="adults01" v-bind:class="{'error': this.showErrorAdults}">{{ $t('step_02_adults') }}</label>
                  </div>
                  <div class="radio col col-12 col-lg-10 ta-l">
                    <div class="row w-100 jcb row-rev">
                      <input v-model="this.adults" :value="5" type="radio" name="adults" id="adults05">
                      <label for="adults05">5+</label>
                      <input v-model="this.adults" :value="4" type="radio" name="adults" id="adults04">
                      <label for="adults04">4</label>
                      <input v-model="this.adults" :value="3" type="radio" name="adults" id="adults03">
                      <label for="adults03">3</label>
                      <input v-model="this.adults" :value="2" type="radio" name="adults" id="adults02">
                      <label for="adults02">2</label>
                      <input v-model="this.adults" :value="1" type="radio" name="adults" id="adults01">
                      <label for="adults01">1</label>
                    </div>
                  </div>

                  <div class="col col-12 col-lg-10 tac-lg">
                    <label for="child01" v-bind:class="{'error': this.showErrorKids}">{{$t('step_02_kids')}}</label>
                  </div>
                  <div class="radio col col-12 col-lg-10">
                    <div class="row w-100 jcb row-rev">
                      <input v-model="this.kids" :value="4" type="radio" name="child" id="child05">
                      <label for="child05">4+</label>
                      <input v-model="this.kids" :value="3" type="radio" name="child" id="child04">
                      <label for="child04">3</label>
                      <input v-model="this.kids" :value="2" type="radio" name="child" id="child03">
                      <label for="child03">2</label>
                      <input v-model="this.kids" :value="1" type="radio" name="child" id="child02">
                      <label for="child02">1</label>
                      <input v-model="this.kids" :value="0" type="radio" name="child" id="child01">
                      <label for="child01">0</label>
                    </div>
                  </div>

                  <div class="col col-12 switch">
                    <input type="checkbox" v-model="this.petsChecked" :value="this.petsChecked" name="havePets" id="havePets" data-oki="#footLang" aria-expanded="false">
                    <label for="havePets"><span class="box"></span>{{$t('step_02_i_have_pets')}}</label>
                  </div>
                </div>

                <div v-bind:class="{'open': this.petsChecked}" class="okiCollapse" id="footLang">
                  <div class="row jcc">
                    <div class="col col-12 col-lg-10 tac-lg">
                      <label for="pets01" v-bind:class="{'error': this.showErrorPets}">{{$t('step_02_pets')}}</label>
                    </div>
                    <div class="radio col col-12 col-lg-10">
                      <div class="row w-100 jcb row-rev">
                        <input v-model="this.pets" :value="5" type="radio" name="pets" id="pets05">
                        <label for="pets05">5+</label>
                        <input v-model="this.pets" :value="4" type="radio" name="pets" id="pets04">
                        <label for="pets04">4</label>
                        <input v-model="this.pets" :value="3" type="radio" name="pets" id="pets03">
                        <label for="pets03">3</label>
                        <input v-model="this.pets" :value="2" type="radio" name="pets" id="pets02">
                        <label for="pets02">2</label>
                        <input v-model="this.pets" :value="1" type="radio" name="pets" id="pets01">
                        <label for="pets01">1</label>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="stepDescNav col-12 co-lg-10 px-0 mt-auto">
              <div class="row jcc">
                <div class="col-6 mobile">
                  <router-link :to="{'name': 'step01', params: {'locale': this.$store.state.lang}}" @click="InlineButtonClickHandler" class="btn btn-w btn-full"><span class="btn-val">{{$t('steps_prev')}}</span> <span class="hover"></span></router-link>
                </div>
                <div class="col-6 col-lg-auto desktop">
                  <router-link :to="{'name': 'step01', params: {'locale': this.$store.state.lang}}" @click="InlineButtonClickHandler" class="btn btn-ghost btn-full"><span class="btn-val">{{$t('steps_prev')}}</span> <span class="hover"></span></router-link>
                </div>
                <div class="col-6 col-lg-auto offset-lg-1">
                  <router-link :to="this.nextStepAvailable() == false ? '' : {'name': 'step03', params: {'locale': this.$store.state.lang}}" @click="InlineButtonClickHandler"  class="btn btn-full" v-bind:class="{'btn-g': !this.nextStepAvailable()}"><span class="btn-val">{{$t('steps_next')}}</span> <span class="hover"></span></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import StepsMenu from "@/views/partials/StepsMenu";
export default {
  components: {StepsMenu},
  watch: {
    petsChecked(newValue) {
      if(newValue == false) {
        this.pets = 0;
      }
    },
    adults(newValue) {
      this.$store
          .dispatch('setAdults', {
            'adults': newValue
          })
      ;
      this.showErrorAdults = false;
    },
    kids(newValue) {
      this.$store
          .dispatch('setKids', {
            'kids': newValue
          })
      ;
      this.showErrorKids = false;
    },
    'pets': function() {
      this.$store
          .dispatch('setPets', {
            'pets': this.pets
          })
      ;
      this.showErrorPets = false;
    }

  },
  data() {
    return {
      adults: [],
      kids: null,
      petsChecked: false,
      pets: 0,
      showErrorAdults: false,
      showErrorKids: false,
      showErrorPets: false
    }
  },
  created() {
    if(this.$store.state.config.adults != null) {
      this.adults = this.$store.state.config.adults;
    }
    if(this.$store.state.config.kids != null) {
      this.kids = this.$store.state.config.kids;
    }

    if(this.$store.state.config.pets != null && this.$store.state.config.pets > 0) {
      this.petsChecked = true;
      this.pets = this.$store.state.config.pets;
    }
  },
  mounted() {
  },
  methods: {
    nextStepAvailable() {
      var result = true;
      if(this.adults.length == 0) {
        result = false;
      }
      if(this.kids == null) {
        result = false;
      }
      if(this.petsChecked && this.pets == 0) {
        result = false;
      }

      return result;
    },
    InlineButtonClickHandler(event) {
      this.validateStep();
      this.$emit('analyticsEvent', 'step03');

      event.section = 'Step02';
      this.$emit('sateliteClick', event);
    },
    validateStep() {
      if(this.adults.length == 0) {
        this.showErrorAdults = true;
      }
      if(this.kids == null) {
        this.showErrorKids = true;
      }
      if(this.petsChecked && this.pets == 0) {
        this.showErrorPets = true;
      }
    }
  }
}
</script>