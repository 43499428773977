<template>
  <section class="steps stepsTown">
    <div class="wrap visible">
      <div class="row aic jcc">
        <div class="stepsNav col-12 col-lg-10">
          <StepsMenu :active="5"/>
        </div>
        <div class="stepsDesc col-12 ta-c px-0">
          <div class="row jcc">
            <div class="col-12 col-lg-8  mb-5">
              <div class="step"><p class="yellow"><strong><span class="val">5</span>/5</strong></p></div>
              <h1 v-bind:class="{'error': this.showError}" class="mb-5 h2" v-html="$t('step_05_title', {district: this.getRegionName()})"></h1>
              <p class="fw-light">{{$t('step_05_caption')}}</p>
              <div class="form">
                <div class="row jcc">
                  <div class="col-12 col-lg-10">
                    <input type="text" v-model="search" @keyup="this.handleKeyUp" name="town" id="name" v-bind:placeholder="$t('step_05_type_city')">
                    <button type="submit"><span class="icon icon-magnifier"></span></button>
                    <div v-if="this.items.length > 0" id="autocomplete-results-wrapper">
                      <div v-for="(item, key) in this.getItems()" :key="'autocomplete_' + key" @click="this.handleCitySelection(item)" class="autocomplete-item">
                        {{this.getCityName(item)}}
                      </div>
                    </div>
                    <div v-if="this.showNotFound" class="autocomplete-results-wrapper">
                      <div class="autocomplete-item not-found-error">
                        {{$t('step_05_not_found')}}
                      </div>
                      <div v-for="(item, k) in this.getItemsExamples()" :key="'autocomplete_example_' + k" @click="this.handleCitySelection(item)" class="autocomplete-item">
                        {{this.getCityName(item)}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="stepsDescNav col-12 px-0 mt-auto">
              <div class="row jcc">
                <div class="col-6 mobile">
                  <router-link :to="{'name': 'step04', params: {'locale': this.$store.state.lang}}" @click="InlineButtonClickHandler" class="btn btn-w btn-full"><span class="btn-val">{{$t('steps_prev')}}</span> <span class="hover"></span></router-link>
                </div>
                <div class="col-6 col-lg-auto desktop">
                  <router-link :to="{'name': 'step04', params: {'locale': this.$store.state.lang}}" @click="InlineButtonClickHandler" class="btn btn-ghost btn-full"><span class="btn-val">{{$t('steps_prev')}}</span> <span class="hover"></span></router-link>
                </div>
                <div class="col-6 col-lg-auto offset-lg-1">
                  <router-link :to="this.selected == null ? '' : {'name': 'summary', params: {'locale': this.$store.state.lang}}" @click="InlineButtonClickHandler"  v-bind:class="{'hover': this.selected != null}" class="btn btn-g btn-full"><span class="btn-val">{{$t('steps_next')}}</span> <span class="hover"></span></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import StepsMenu from "@/views/partials/StepsMenu";

var searchTimeout = null;


export default {
  components: {StepsMenu},
  data() {
    return {
      showNotFound: false,
      search: null,
      items: [],
      itemsExamples: [],
      selected: null,
      showError: false
    }
  },
  watch: {
    selected() {
      if(this.selected != null)
        this.showError = false;
    }
  },
  mounted() {
  },
  created() {
    if(this.$store.state.config.city != null) {
      this.selected = this.$store.state.config.city;
      this.search = this.$store.state.config.city.city_pol;
    }

    this.$store
        .dispatch('fetchBiggestCities', {
          'district': this.$store.state.config.region
        })
        .then(cities => {
          this.itemsExamples = cities
        }).catch(error => {
      console.log(error);
    });
  },
  methods: {
    getItems() {
      return this.items;
    },
    getItemsExamples() {
      return this.itemsExamples;
    },
    handleKeyUp() {
      this.showNotFound=false;
      var thisComponent = this;
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(function() {

        thisComponent.$store.dispatch('fetchCityAutocomplete', thisComponent.search)
            .then(items => {
              thisComponent.items = items
              if(items.length == 0) {
                thisComponent.showNotFound=true;
              }
            }).catch(error => {
              console.log(error);
        });
      }, 400);
    },
    handleCitySelection(item) {
      this.selected = item;
      this.search = this.getCityName(item);
      this.items = [];
      this.showNotFound=false;


      this.$store
          .dispatch('setCity', {
            'city': this.selected
          })
      ;
    },
    getRegionName() {
      return this.$t('district_ms_' + this.$store.state.config.region);
    },
    getCityName(item) {
      if(this.$store.state.lang == 'pl')
        return item.city_pol;
      if(this.$store.state.lang == 'en')
        return item.city_eng;
      if(this.$store.state.lang == 'ua')
        return item.city_uah + ' ('+item.city_pol+')';
    },
    InlineButtonClickHandler(event) {
      this.validateStep();
      this.$emit('analyticsEvent', 'summary');

      event.section = 'Step05';
      this.$emit('sateliteClick', event);
    },
    validateStep() {
      if(this.selected == null)
        this.showError = true;
    }
  }
}
</script>