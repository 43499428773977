<template>
  <section class="reg regTop">
    <div class="wrap">
      <div class="row aic">
        <div class="regLogo col-12 col-lg-auto ta-c pr-lg-5">
          <img src="@/assets/dist/img/logo.svg" width="120" v-bind:alt="$t('home_banner_title')">
        </div>
        <div class="col-12 col-lg-9 ta-c ta-l-lg pl-lg-5">
          <h2>{{ $t(this.pageTitle) }}</h2>
          <p><strong>{{ $t(this.pageStartsFrom) }}</strong></p>
          <div v-html="$t(this.pageContent)"></div>

        </div>
      </div>
    </div>
  </section>

  <section class="reg">
    <div class="wrap">
      <div class="row">
        <div class="col-12">
          <TermsPl v-if="this.pageTitle == 'page_terms_title' && this.$store.state.lang == 'pl'"/>
          <TermsEn v-if="this.pageTitle == 'page_terms_title' && this.$store.state.lang == 'en'"/>
          <TermsUa v-if="this.pageTitle == 'page_terms_title' && this.$store.state.lang == 'ua'"/>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import TermsPl from "@/views/terms/TermsPl";
import TermsEn from "@/views/terms/TermsEn";
import TermsUa from "@/views/terms/TermsUa";
export default {
  components: {TermsPl, TermsEn, TermsUa},
  props: ['pageTitle', 'pageContent', 'pageStartsFrom'],
  mounted() {
    this.$store
        .dispatch('changeFooterVisibility', {
          'visibility': true
        })
    ;
  },
  methods: {
    getCurrentLang() {
      return this.$store.state.lang;
    }
  }
}
</script>